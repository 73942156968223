<div class="bar-wrapper" [ngStyle]="timerBarSize()">
    <svg   
        version="1.1" xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 170 3"
        enable-background="new 0 0 170 3"
        xml:space="preserve" preserveAspectRatio=""
        >

 

        <defs>
            <linearGradient id="timer1" x1="0%" x2="100%" y1="0%" y2="0%" gradientUnits="userSpaceOnUse">
                <stop class="" offset="0%" style="stop-color: #1c8800;"></stop>
                <stop class="" offset="100%" style="stop-color: #2aff00"></stop>
            </linearGradient>
            <linearGradient id="timer2" x1="0%" x2="60%" y1="0%" y2="20%" gradientUnits="userSpaceOnUse">
                <stop class="" offset="0%" style="stop-color: #ba0000;"></stop>
                <stop class="" offset="100%" style="stop-color: #ff7f05"></stop>
            </linearGradient>
        </defs>
    
    <!-- <g 
        stroke="url(#timer1)" [attr.stroke]="timer_gradient" stroke-width="3" 
        >

            <path 
                [ngClass]="{empty: segments[0]}"
                stroke-linecap="round" d="M5 1.5 l10 0" />
            <path [ngClass]="{empty: segments[0]}" stroke-linecap="butt" d="M5 1.5 l15 0" />

            <path [ngClass]="{empty: segments[1]}" stroke-linecap="butt" d="M21 1.5 l15 0" />	      
            <path [ngClass]="{empty: segments[2]}" stroke-linecap="butt" d="M37 1.5 l15 0" />
            <path [ngClass]="{empty: segments[3]}" stroke-linecap="butt" d="M53 1.5 l15 0" />
            <path [ngClass]="{empty: segments[4]}" stroke-linecap="butt" d="M69 1.5 l15 0" />
            <path [ngClass]="{empty: segments[5]}" stroke-linecap="butt" d="M85 1.5 l15 0" />
            <path [ngClass]="{empty: segments[6]}" stroke-linecap="butt" d="M101 1.5 l15 0" />
            <path [ngClass]="{empty: segments[7]}" stroke-linecap="butt" d="M117 1.5 l15 0" />
            <path [ngClass]="{empty: segments[8]}" stroke-linecap="butt" d="M133 1.5 l15 0" />
            
            <path [ngClass]="{empty: segments[9]}" stroke-linecap="butt" d="M149 1.5 l15 0" />
            <path [ngClass]="{empty: segments[9]}" stroke-linecap="round" d="M154 1.5 l10 0" />  
    </g>     -->
    <!-- <g stroke="url(#timer1)" [attr.stroke]="timer_gradient" stroke-width="3" >
        <path
            [ngClass]="{                
                full_offset: full_offset,
                count_down_1: count_down_1,
                count_down_2: count_down_2,
                count_down_stop: !is_player_turn
            }"
            stroke-dasharray="158"
            stroke-linecap="round"            
            d="M5 1.5 l158 0">
        </path>
    </g> -->
    <g 
        width="100%" height="100%"
        stroke-width="3" >
        <path
            class="animated_timer"
            [@timer]="handleTurnTimerAnimation()"  
            (@timer.start)="handleTurnTimerStart($event)"
            (@timer.done)="handleTurnTimerDone($event)"
            stroke-dasharray="158"
            stroke-linecap="round"            
            d="M5 1.5 l158 0">
        </path>
    </g>
    <g stroke="#050506" stroke-width="4px">
        <path
            d="
                M20 1.5 l1 0 
                M36 1.5 l1 0 
                M52 1.5 l1 0 
                M68 1.5 l1 0 
                M84 1.5 l1 0 
                M100 1.5 l1 0 
                M116 1.5 l1 0 
                M132 1.5 l1 0 
                M148 1.5 l1 0 
            ">
        </path>
    </g>
    </svg>

</div>
