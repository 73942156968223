<ng-container *ngIf="replay">
    <div class="centered"
        [ngStyle]="deckPosition()"
        [@deck_animations]="handleDeckAnimation()"
        (@deck_animations.done)="deckAnimationDone($event)">
            <app-card
                *ngIf="cutterCard !== null"
                [card]="cutterCard"
                class="card bottom_card">
            </app-card>
        <app-card
            #deck
            [card]="back_card"
            class="card deck_card"> 
        </app-card>
        <div class="right-controls-wrapper">
            <div class="right-controls" 
                [ngStyle]="rightControlsSize()">
                <div class="row">
                </div>
                <div class="row">
                    <div class="speed-info">
                        <p class="small">Speed</p>
                        <p>{{speed}} x</p>
                    </div>
                    <button class="speed-up">
                        <i class="triangle-up"></i>
                        <i class="square up"></i>
                    </button>
                    <button class="speed-down">
                        <i class="square down"></i>
                        <i class="triangle-down"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

</ng-container>

<ng-container *ngIf="!replay">
    <div class="centered"
    [ngStyle]="deckPosition()"
    [@deck_animations]="handleDeckAnimation()"
    (@deck_animations.done)="deckAnimationDone($event)">
    <ng-container *ngIf="canRenderCutterCard()">
        <app-card
            *ngIf="cutterCard !== null"
            [card]="cutterCard"
            class="card bottom_card">
            <!-- bottom_card -->
        </app-card>
    </ng-container>
    
    <app-card        
        #deck
        *ngIf="canRenderDeckCard()"
        (click)="drawCards()"
        [glowing]="canRenderGlowingDeck()"
        [card]="back_card"
        [ngClass]="{'shift': !canRenderCutterCard()}"
        class="card deck_card shift"> 
        <!-- deck_card -->
    </app-card>
    
    
</div>
</ng-container>

