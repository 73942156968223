<ng-container *ngIf="canRenderPlayerCards()">
    <app-player-cards
        [player]="0"
        [ngClass]="{player: true}">
    </app-player-cards>
</ng-container>

    <div class="player-bar-wrapper" [ngStyle]="barWrapperSize()">
        <ng-container *ngIf="canRenderSumOfPlayerCards()">
            <app-number-of-cards
                [player]="0">
            </app-number-of-cards>
        </ng-container>
        <app-player-info-bar *ngIf="canRenderPlayerInfo()" [playerInfoState]="playerInfoState" [player]="0" class="bottom"></app-player-info-bar>
        <app-player-bar
            [player]="0"
            [name]="player_name"
            [ngClass]="{player: true}">
        </app-player-bar>
    </div>


