<div class="selection_wrapper" [ngClass]="{'open': openSelectOptions}" >
    <div class="selected_option" 
        (click)="toggle()">
        {{selectedOptions()}}
        <span class="arrow_down"></span>
    </div>
    <div class="select_options_wrapper" [ngClass]="{'open': openSelectOptions}">
        <div *ngFor="let item of list; index as indexOfelement">
            <label class="select_option">
                {{item.label}}
                <input 
                    value="{{item.value}}" 
                    type="{{item.type}}" 
                    (click)="requiredOne(indexOfelement, $event)"
                    (change)="select(indexOfelement, $event)"
                    [checked]="isItemSelected(indexOfelement) === true"
                    name="{{item.name}}"/>
                <span class="checkmark_{{item.checkmark}}">
                </span>
            </label>
            
        </div>

        <ng-content ></ng-content>

    </div>
</div>
