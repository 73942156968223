<app-active-player-arrow
    *ngIf="canRenderPlayerArrow()"
    [player]="player"
    [replay]="replay">
</app-active-player-arrow>


<div class="player-bar-wrapper">
    <app-timer-bar 
        *ngIf="canRenderPlayerTimerBar()"
        [ngClass]="{player: true}"
        [player]="player"
        [replay]="replay">
    </app-timer-bar>
    
    <div class="player-text-wrapper"
        [ngClass]="{active: (isPlayerTurn && canRenderPlayerArrow()), empty: !canRenderPlayerInfo()}">

        <ng-container *ngIf="canRenderPlayerInfo()">

            <div class="name">
                    {{name}}    
            </div>
            <div class="buy-in">
                {{transformBuyIn()}}

            </div>    
        </ng-container>
        
    </div>

</div>

<app-timer-dial 
    *ngIf="canRenderPlayerTimerDial()"
    [player]="player"
    [replay]="replay">
</app-timer-dial>

