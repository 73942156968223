import { User } from './../models/user.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {  
  }

  getUser(name: string): Observable<User> {
    const options = {
      params: new HttpParams().set('value', name)
    }
    return this.http.get<User>
    ('http://localhost:5000/api/user/name', options)
    .pipe(    
      tap(_ => this.log(_)),
      catchError(this.handleError<any>('getUser',[])),
    );
  }

  handleError<T>(operation = 'operation', result?: T){
    return (error: any): Observable<T> => {
      //console.log(error);      
      return of(result as T);
    }
  }

  private log(user: User) {
    console.log("user log function");
    // console.log(user.balance);
    // console.log(user.name);
  }
  
}
