<div class="opponent-bar-wrapper" [ngStyle]="barWrapperSize()">
    
    <div class="replay" [ngStyle]="replaySize()">
        <button (click)="handleCloseClick()"><i class="close"></i></button>
        <div class="text">
            <div>replay</div>
        </div>
    </div>
    
    <app-opponent-bar [ngStyle]="opponentBarPosition()" [player]="1" [replay]="true" [name]="opponentName">
    </app-opponent-bar>

</div>

<app-opponent-cards [replay]="true">
</app-opponent-cards>

