import { Component, ElementRef, OnInit } from '@angular/core';
import { UserWinLostRate } from 'src/app/models/user.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';

@Component({
  selector: 'app-win-lost-rate',
  templateUrl: './win-lost-rate.component.html',
  styleUrls: ['./win-lost-rate.component.scss']
})
export class WinLostRateComponent implements OnInit {

  unitSize: number = 12;
  maxUnits: number = 12;

  fontUnitWidth: number = 7;
  

  constructor(private display: DisplayModeService) {

  }

  // TODO: use this to map data from server
  wonGames = 99
  lostGames = 222
  

  ngOnInit(): void {
    this.setUnitSize()
  }
  ngAfterViewChecked() {
    this.setUnitSize()
  }

  setUnitSize(){
    let defaultSize = 12
    
    // this.display.expanded
    if (this.display.appWrapperSize.width >= 570 ) {      
      this.unitSize = ((this.display.appWrapperSize.width - 30)* 0.115) / 12
      return 
    }

    this.unitSize = defaultSize
  
  }    


  calculateStyleForWinLost(key: keyof UserWinLostRate) {
    let winLostRate = {
      win: this.wonGames,
      lost: this.lostGames
    }
    
    if (winLostRate.win <= this.maxUnits && winLostRate.lost <= this.maxUnits) {   
      return {
        width: (this.unitSize* winLostRate[key]) + "px"
      }
    }

    if (winLostRate.win > winLostRate.lost) {  
      return {
        width: (key === 'win') ? 
        (this.unitSize*this.maxUnits) + "px" : 
        ((this.unitSize*this.maxUnits/winLostRate.win) * winLostRate.lost) + "px",
      };
    } 

    if (winLostRate.win < winLostRate.lost) {
      return {
        width: (key === 'lost') ? 
        (this.unitSize*this.maxUnits) + "px" : 
        (this.unitSize*this.maxUnits/winLostRate.lost * winLostRate.win) + "px",
      };
    } 

    return {
      width:  (this.maxUnits*this.unitSize) + "px"
    }

  }

  calculateStyleForAmount(key: keyof UserWinLostRate) {

    var max = Math.max(this.wonGames, this.lostGames);
    
    return {
      width: (this.fontUnitWidth * (max.toString().length + 1) + 9) + "px"
    }
  }
}
