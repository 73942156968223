<div class="opponent-bar-wrapper" [ngStyle]="barWrapperSize()">
    <app-opponent-bar
        [ngStyle]="opponentBarPosition()"
        [player]="1"
        [name]="opponentName">
    </app-opponent-bar>
</div>

<ng-container *ngIf="canRenderOpponentCards()">
    <app-opponent-cards
        [player]="1">
    </app-opponent-cards>
</ng-container>

<app-player-info-bar *ngIf="canRenderPlayerInfo()" [playerInfoState]="playerInfoState" [player]="1" class="top"></app-player-info-bar>
