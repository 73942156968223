import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GameType } from 'src/app/models/lobby.model';
import { MyGamesFilterResults } from 'src/app/models/user.model';
import { changeOpponentName, selectGameType } from 'src/app/state/actions/lobby.actions';
import { AppState } from 'src/app/state/app.state';
import { canStartTheGame } from 'src/app/state/selectors/lobby.selector';

@Component({
  selector: 'app-game-in-table',
  templateUrl: './game-in-table.component.html',
  styleUrls: ['./game-in-table.component.scss']
})
export class GameInTableComponent implements OnInit {

  @Input() game!: MyGamesFilterResults
  @Input() elementIndex!: number
  @Input() toggleElementIndex!: number
  @Output() emitToggleElement = new EventEmitter<number>()
  
  user_dark_icon = "assets/images/svg/user-dark.svg";

  user_icon_online = "assets/images/svg/opponent_online.svg"
  user_icon_offline = "assets/images/svg/opponent_offline.svg"
  user_icon = this.user_icon_offline

  gameDetail: boolean = false
  inviteOpponentDetail: boolean = false

  constructor(private router: Router, private store: Store<AppState>) { }

  ngOnInit(): void {
    if (this.game.opponentOnline === true) {
      this.user_icon = this.user_icon_online
    } else {
      this.user_icon = this.user_icon_offline
    }
  }
  
  


  getOpponentName() {
    if (this.game.opponent.length > 15) {
      return this.game.opponent.slice(0,15) + "..."
    }

    return this.game.opponent
  }

  getGameResult() {
    return (this.game.iamWinner === true) ? 'W' : 'L'
  }
  getGameResultClass() {
    return (this.game.iamWinner === true) ? 'won' : 'lost'
  }

  getBuyIn(withUGX: boolean) {
    if (this.game.buyIn === 0) {
      return 'Free'
    }
    
    let unit = (withUGX === true) ? " UGX" : "" 
    return this.renderThousands(this.game.buyIn) + unit
  }

  renderThousands(number: number | null) {
    if (!number || number === 0) {
      return 
    }

    let string = number.toString().split("").reverse().join("")
    string = string.replace(/(\d{3})/g, "$1 ")
    return string.split("").reverse().join("")
  }
  
  getPlayed() {
    return this.game.gameStarted.getDate() + "." + this.game.gameStarted.getMonth() + ". | " + 
    ('00' + this.game.gameStarted.getHours()).slice(-2) + ":" + ('00' + this.game.gameStarted.getMinutes()).slice(-2) + "h" 
  }

  getGameLength() {
  
    let diffrence = (this.game.gameEnded.getTime() - this.game.gameStarted.getTime())  / 1000
    // console.log(diffrence);
    

    let hour = Math.floor(diffrence / 3600);
    // console.log(hour);
    
    let minutes = Math.floor((diffrence - (hour * 3600)) / 60)
    // console.log(minutes);
    
    let seconds = diffrence - (hour * 3600) - (minutes * 60)  
    // console.log(seconds);
    
    let h = (hour !== 0) ?  hour + " hour " : ""
    let min = minutes + " min. " //(diffrence.getMinutes()) ? diffrence.getMinutes() + " min. " : ""
    let sec = seconds + " sec." //(diffrence.getDate()) ? diffrence.getDate() + " sec." : "" 
    return  h + min + sec
  }
  getGameWin() {
    // console.log(this.game.win);
    let unit = "UGX"
    // let win = this.game.win
    let win = this.renderThousands(this.game.win)
    return ((win) ? (win ) : "0" ) + " " + unit
  }

  toggleGameDetail() {
    this.inviteOpponentDetail = false
    this.gameDetail = !this.gameDetail
    this.emitToggleElement.emit(this.elementIndex)
  }
  closeGameDetail() {
    this.gameDetail = false
  }

  replayGameDetail() {
    // console.log("replay game detail");
    //TODO: save states for the return

    this.router.navigate(['/game-replay'])
  }
  toggleInviteOpponentDetail($event: Event) {
    $event.stopPropagation()
    this.gameDetail = false
    this.inviteOpponentDetail = !this.inviteOpponentDetail
    this.emitToggleElement.emit(this.elementIndex)
  }
  inviteOpponent() {
    console.log("invite opponent");
    if (this.game.opponentOnline === false) {
      // TODO: show pop-up - opponent is offline (StartGameErrors.notOpponentAvailable)
      return 
    }
    
    this.store.dispatch(selectGameType({gameType: GameType.FRIEND}))
    this.store.dispatch(changeOpponentName({opponentName: this.game.opponent}))
    this.router.navigate(['/'], { queryParams: { c: 'invite' } })
  }
  closeInviteOpponent() {
    this.inviteOpponentDetail = false
  }


  gameDetailActiveClass() {
    if (this.elementIndex !== this.toggleElementIndex) {
      this.gameDetail = false
    }

    return this.gameDetail
    
  }
  inviteOpponentDetailActiveClass() {
    if (this.elementIndex !== this.toggleElementIndex) {
      this.inviteOpponentDetail = false
    }
    return this.inviteOpponentDetail
  }
}
