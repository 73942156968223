import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GameComponent } from './game.component';
import { GamePlayerComponent } from './game-player/game-player.component';
import { GameOpponentComponent } from './game-opponent/game-opponent.component';
import { PlayerBarComponent } from './game-player/player-bar/player-bar.component';
import { GameControlComponent } from './game-control/game-control.component';
import { TimerBarComponent } from './shared/timer-bar/timer-bar.component';
import { TimerDialComponent } from './shared/timer-dial/timer-dial.component';
import { ActivePlayerArrowComponent } from './shared/active-player-arrow/active-player-arrow.component';
import { PlayerCardsComponent } from './game-player/player-cards/player-cards.component';
import { GameTableModule } from './game-table/game-table.module';
import { CardComponent } from './shared/card/card.component';

import { ModalsModule } from '../modals/modals.module';
import { GiveUpModalComponent } from '../modals/give-up-modal/give-up-modal.component';
import { GameSharedComponentsModule } from './shared/game-shared-components.module';
import { OpponentBarComponent } from './game-opponent/opponent-bar/opponent-bar.component';
import { OpponentCardsComponent } from './game-opponent/opponent-cards/opponent-cards.component';
import { GameReplayComponent } from './game-replay/game-replay.component';
import { GameReplayPlayerComponent } from './game-replay/game-replay-player/game-replay-player.component';
import { GameReplayOpponentComponent } from './game-replay/game-replay-opponent/game-replay-opponent.component';
import { GameReplayBoardComponent } from './game-replay/game-replay-board/game-replay-board.component';
import { PlayerInfoBarComponent } from './game-player/player-info-bar/player-info-bar.component';



@NgModule({
  declarations: [ 
    GameComponent, 
    GamePlayerComponent, 
    GameOpponentComponent, 
    OpponentBarComponent,
    PlayerBarComponent, 
    GameControlComponent, 
    TimerBarComponent, 
    TimerDialComponent, 
    ActivePlayerArrowComponent, 
    PlayerCardsComponent,
    OpponentCardsComponent, 
    GiveUpModalComponent ,
    GameReplayComponent,
    GameReplayPlayerComponent,
    GameReplayOpponentComponent,
    GameReplayBoardComponent,
    PlayerInfoBarComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    GameTableModule,  
    ModalsModule,
    GameSharedComponentsModule,
    
  ],
  exports: [
    GameComponent
  ]
})
export class GameModule { }
