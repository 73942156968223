<app-header></app-header>
<app-subheader></app-subheader>

<div class="inner_wrapper">
  <h1>settings</h1>
  <div class="settings_table">
    <div class="settings_option" *ngFor="let option of settings">
      <label>
        {{option.label}}
        <input type="checkbox"
          [checked]="(userSettings[option.input])"
          (change)="handleChangeSettings(option.input)"
        > 
        <span class="switcher" [ngClass]="{active: (userSettings[option.input])}"></span>
      </label>    
    </div>
  </div>
</div>


<!-- <app-footer *ngIf="!isMobileLandscape"></app-footer> -->
<app-footer></app-footer>