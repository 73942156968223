import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import * as LobbyActions from 'src/app/state/actions/lobby.actions'
import { opponentNameExist, selectOpponent } from 'src/app/state/selectors/lobby.selector';

@Component({
  selector: 'app-invite-opponent-input',
  templateUrl: './invite-opponent-input.component.html',
  styleUrls: ['./invite-opponent-input.component.scss']
})
export class InviteOpponentInputComponent implements OnInit {

  @Output() startTheGameEventEmmiter = new EventEmitter<any>()

  opponentNameForm = new FormGroup({
    opponentName: new FormControl('')  
  })

  // opponentName = new FormControl('') 

  // opponentNameExist!: boolean
  // opponentNameExist$ = this.store.pipe(
  //   select(state => opponentNameExist(state.lobby))
  // )

  opponent!: any
  opponent$ = this.store.pipe(
    select(state => selectOpponent(state.lobby))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.onChange()
    // this.subscribeOpponentNameExist()
    this.subscribeOpponent()
    if (this.opponent.name !== "") {
      this.opponentNameForm.controls['opponentName'].setValue(this.opponent.name)
    }    
    

  }

  onChange(): void {
    this.opponentNameForm.controls.opponentName.valueChanges.subscribe(value => {
      // console.log(value);
      this.store.dispatch(LobbyActions.changeOpponentName({ opponentName: this.opponentNameForm.controls.opponentName.value }));
    })
  }
  // subscribeOpponentNameExist() {
  //   this.opponentNameExist$.subscribe(value => {
  //     this.opponentNameExist = value;
  //   })
  // }

  subscribeOpponent() {
    this.opponent$.subscribe(value => {
      this.opponent = value
    })
  }

  startTheGameEvent() {
    this.startTheGameEventEmmiter.emit(this.opponentNameForm.value)
  }


}
