<div class="cards_player"
    #player_cards_ref
    [@player_animations]="handlePlayerAnimation()"
    (@player_animations.start)="playerAnimationStart($event)"
    (@player_animations.done)="playerAnimationDone($event)">
        <app-card 
            *ngFor="let card of playerCards; last as isLast; let indexOfelement=index;"
            [card]="card"
            [glowing]="canRenderGlowingCards()"
            [ngClass]="{last: isLast, played: isPlayed(indexOfelement)}"
            [ngStyle]="calculateStyle(isLast)"
            (click)="playerPlaysCard(card, indexOfelement)">        
        </app-card>
   

</div>
