import { animate, query, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { select, State, Store } from '@ngrx/store';
import { timeStamp } from 'node:console';
import { fromEvent, Subscription, timer, VirtualTimeScheduler } from 'rxjs';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { GameTimersService } from 'src/app/services/game-timers.service';
import { startGame, timeBankTimerStart, turnTimerDone, turnTimerStart } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { isPlayerTurn, playerTurn, selectPlayerOnTurn, selectPlayerTimers } from 'src/app/state/selectors/game.selector';
import { runTimeBankTimer, runTurnTimer } from './animations/run-timer.animation';

@Component({
  selector: 'app-timer-bar',
  templateUrl: './timer-bar.component.html',
  styleUrls: ['./timer-bar.component.scss'],
  animations: [
    trigger('timer',[
      transition('* => run_turn_timer', [
        useAnimation(runTurnTimer)
      ], {params: {stroke: 'url(#timer1)'}}),
      transition('* => run_time_bank_timer', [
        useAnimation(runTimeBankTimer)
      ], {params: {stroke: 'url(#timer2)'}}),
    ])
  ]
})
export class TimerBarComponent implements OnInit {

  // @Input() opponent!: boolean
  @Input() replay: boolean = false
  @Input() player!: number
  
  timerAnimationState!: any

  isPlayerTurn!: boolean;
  isPlayerTurn$ = this.store.pipe(
    select(state => isPlayerTurn(state.game, this.player))
  ) 

  timeBankTimer!: boolean
  turnTimer!: boolean
  playerTimers$ = this.store.pipe(
    select(state => selectPlayerTimers(state.game, this.player))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  
  constructor(private store: Store<AppState>, private timers: GameTimersService,
    private displayModeServices: DisplayModeService) { }
  
  ngOnInit(): void {    
    this.subscribeAppWrapperSize()

    this.subscribeIsPlayerTurn()
    this.subscribePlayerTimers()
    
  } 

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }
  // subscription$!: Subscription

  // subscribeTurnTimer() {
    
  //   console.log(this.timers);
  //   let event = fromEvent(document, 'click');
  //   // console.log(this.timers.turnTimerReset(0, 15000, event));
  //   this.timers.turnTimerReset(0, 15000, event)
  //   this.subscription$ = this.timers.turnTimer.subscribe(
  //     (n) => { 
  //       console.log(n); 
  //     },
  //     (error) => {},
  //     () => { 
  //       // this.store.dispatch(turnTimerDone())
  //       this.store.dispatch(turnTimerDone({ player: this.player }))      
  //       this.subscription$.unsubscribe();  
  //       // this.store.dispatch(timeBankTimerStart({player: this.player}))
  //       // if (this.isPlayerTurn) {
  //       //   this.timerAnimationState = 'run_time_bank_timer'  
  //       // }
        
  //     } 
  //   )    
    
  // }

  // unsubscribeTurnTimer() {
  //   this.subscription$.unsubscribe()
  // }

  handleTurnTimerStart($event: any) {
    // console.log("start turn time animation")    
    // console.log(this.player);
    // console.log($event);    

    
  }
  handleTurnTimerDone($event: any) {
    // console.log("done turn time animation");
    // console.log(this.player);
    // console.log($event);    
    
    if ($event.toState == 'run_turn_timer') {
      this.store.dispatch(turnTimerDone({ player: this.player }))
      // this.store.dispatch(timeBankTimerStart({ player: this.player }))
      
      return   
    }
    
  } 
  handleTurnTimerAnimation() {
    
    // let stroke = "url(#timer1)"
    // console.log(this.isTimeBankRunning);
    return {
      value: this.timerAnimationState,
      params: {
        // stroke: stroke, 
      }
    };
  }

  subscribePlayerTimers() {
    this.playerTimers$.subscribe(value => {
      // console.log(this.player);      
      // console.log(value);
      
      this.turnTimer = value.turnTimer
      this.timeBankTimer = value.timeBankTimer

      // console.log(this.turnTimer + ":" + this.player);
      // console.log(this.timeBankTimer + ":" + this.player);

      if (this.turnTimer === true) {
        this.timerAnimationState = 'run_turn_timer'
       
      } 
      if (this.timeBankTimer === true) {
        this.timerAnimationState = 'run_time_bank_timer'
      }

      if (this.turnTimer === false && this.timeBankTimer === false) {
        this.timerAnimationState = '*'
        
      }
    })
  }
  
  subscribeIsPlayerTurn() {
    this.isPlayerTurn$.subscribe(value => {
      
      this.isPlayerTurn = value
      
      
      if (this.isPlayerTurn === true) {
        this.timerAnimationState = 'run_turn_timer'
        this.store.dispatch(turnTimerStart({player: this.player}));
      }

      if (this.isPlayerTurn === false) {
        this.timerAnimationState = '*'
      }
    })
  }

  timerBarSize() {

    let defaultWidth = 168
    let defaultHeight = 13

    let width = (defaultWidth * this.displayModeServices.expanded)
    let height = (defaultHeight * this.displayModeServices.expanded)

    return {
      width: width + 'px',
      height: height + 'px'
    }
  }
}
