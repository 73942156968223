import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PlayerInfoStates } from 'src/app/models/game.model';
import { BuyInValue } from 'src/app/models/lobby.model';
import { AppState } from 'src/app/state/app.state';
import { selectGameBuyIn, selectWinAfterGame } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-player-info-bar',
  templateUrl: './player-info-bar.component.html',
  styleUrls: ['./player-info-bar.component.scss']
})
export class PlayerInfoBarComponent implements OnInit {

  @Input() playerInfoState: PlayerInfoStates = PlayerInfoStates.none
  @Input() player!: number 

  playerWon!: number
  playerWon$ = this.store.pipe(
    select(state => selectWinAfterGame(state.game))
  )

  constructor(private store:Store<AppState>) { }

  ngOnInit(): void {
    this.subscribePlayerWon()
  }

  subscribePlayerWon() {
    this.playerWon$.subscribe(value => {
      this.playerWon = value.playerWon
    })
  }
  getMessage() {
    if (this.playerInfoState === PlayerInfoStates.opponent_is_ready) {
      return "Opponent is ready"
    }
    if (this.playerInfoState === PlayerInfoStates.pick_or_retaliate) {
      return "You have to pick or retaliate with a missile"
    }
    if (this.playerInfoState === PlayerInfoStates.credit_added) {
      return "Your account was credited with the amount of "+ this.playerWon +" UGX."
    }
    if (this.playerInfoState === PlayerInfoStates.you_lost) {
      return "Good luck next time"
    }
  
    return ""
  }

  getClass() {
    if (this.playerInfoState === PlayerInfoStates.opponent_is_ready) {
      return "green"
    }  
    return ""
  }
}
