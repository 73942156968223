import { Action, createReducer, on } from "@ngrx/store";
import { GameType, GameTypeKeys, GameVariant, Lobby } from "src/app/models/lobby.model";
import * as LobbyActions from "../actions/lobby.actions";

export const initialLobbyState: Lobby = {
    buyInType: null,
    gameType: GameType.H2H,
    opponent: {
        name: 'Kapitan123',
        exist: false,
        available: false
    },
    invitation: {
        name: '',
        buyIn: null
    }
}




export const createdLobbyReducer = createReducer(
    initialLobbyState,
    on(
        LobbyActions.selectGameType,
        (state, { gameType }) => {
            
            //do not allow free game for friend game 
            let buyInType = state.buyInType
            if (buyInType === 0 && gameType === GameType.FRIEND) {
                buyInType = null
            }

            return {
                ...state,
                 gameType,
                 buyInType
            }
        }
    ),
    on(
        LobbyActions.selectBuyInType,
        (state, { buyInType }) => {
            
            if (state.gameType === GameType.FRIEND && buyInType === 0) {
                return state
            }

            return {
                ...state, 
                buyInType
            }
        }
    ),
    on(
        LobbyActions.acceptInvitation,
        (state) => {
            return {
                ...state,
                invitation: {
                    name: '',
                    buyIn: null
                }
            }
        }
    ),
    on(
        LobbyActions.rejectInvitation,
        (state) => {
            return {
                ...state,
                invitation: {
                    name: '',
                    buyIn: null
                }
            }
        }
    ),
    on(
        LobbyActions.changeOpponentName,
        (state, { opponentName }) => {
            return {
                ...state,
                opponent: {
                    name: opponentName,
                    exist: state.opponent.exist,
                    available: state.opponent.available
                }

            }
        }
    )
);
export function lobbyReducer(state: Lobby | undefined,  action: Action): Lobby {
    return createdLobbyReducer(state, action);
}





// export const initialState: LobbyState = {
//     lobby: {
//         buyIn: null,
//         gameType: GameTypeVariant.H2H
//     }
// }

// const createLobbyReducer = createReducer(
//     initialState,
//     on(
//         LobbyActions.selectBuyIn,
//         (state,  { buyInType } ) => {   
//             let lobby = {
//                 ...state.lobby,
//                 buyIn: buyInType
//             }
//             return {...state, lobby: lobby};
//         }
        
//     ),
//     on(
//         LobbyActions.selectGameType,
//         (state, { gameType }) => {
//             let lobby = {
//                 ...state.lobby,
//                 gameType: gameType
//             }

//             return {...state, lobby: lobby}
//         }
//     )
// );
