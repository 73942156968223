<div class="game-variant" *ngIf="buyInType !== 0">
    <img src="{{gameVariant.icon}}" alt="">
    <span class="">{{gameVariant.name}}</span>
</div>

<div class="game-variant free" *ngIf="buyInType === 0">
    <div>
        <p>FREE</p>
        <p>GAME</p>
    </div>
</div>
