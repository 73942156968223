<app-active-player-arrow
    *ngIf="canRenderPlayerArrow()"
    [player]="player"
    [replay]="replay">
</app-active-player-arrow>

<div class="bar-wrapper">
    <div 
        class="text-wrapper"
        [ngClass]="{active: (isPlayerTurn && canRenderPlayerArrow()), empty: !canRenderOpponentDetail()}">            
        <ng-container *ngIf="canRenderOpponentDetail()">
            <div class="name">
                {{name}}    
            </div>
            <div class="buy-in">
                {{transformBuyInText()}}
            </div>    
        </ng-container>
    </div>
    <app-timer-bar 
        *ngIf="canRenderTimerBar()"
        [ngClass]="{opponent: true}"
        [player]="player"
        [replay]="replay">
    </app-timer-bar>
</div>

<app-timer-dial 
    *ngIf="canRenderPlayerTimerDial()"
    [player]="player"
    [replay]="replay">
</app-timer-dial>


