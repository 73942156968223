<div 
    #ace_switch
    [@ace_switch]="handleAnimation()"
    class="button-wrapper"
    [ngClass]="{'selected': selected, 'glow': glowingStates}">
    <div class="row">
        <button
            (click)="selectSuit(CardSuit.heart)"
            [ngStyle]="buttonSize()"
            class="button top-left">
            <div class="heart icon"></div>
        </button>
        <button
            (click)="selectSuit(CardSuit.clover)"
            [ngStyle]="buttonSize()"
            class="button m-l-1 top-right">
            <div class="clover icon"></div>
        </button>
    </div>
    <div class="row">
        <button
            (click)="selectSuit(CardSuit.pike)"
            [ngStyle]="buttonSize()"
            class="button bottom-left">
            <div class="pike icon"></div>
        </button>
        <button 
            (click)="selectSuit(CardSuit.tile)"
            [ngStyle]="buttonSize()"
            class="button bottom-right">
            <div class="tile icon"></div>
        </button>
    </div>

</div>