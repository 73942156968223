import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { isMobileLandscapeMode, isPortraitMode } from 'src/app/state/selectors/display-mode.selector';
import {  UserSettings } from 'src/app/models/user.model';
import { selectUserSettings } from 'src/app/state/selectors/user.selector';
import { changeSettings } from 'src/app/state/actions/user.actions';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  

  // isMobileLandscape!: boolean
  // isMobileLandscape$ = this.store.pipe(
  //   select(state => isMobileLandscapeMode(state.displayMode))
  // ) 

  settings = [
    {label: 'Sound on', input: 'soundOn'},
    {label: 'Play card by doubleclick', input: 'playCardByDoubleClick'},
    {label: 'Switch turn automatically', input: 'autoSwitchTurn'},
    {label: 'Unplayable cards sit down', input: 'displayUnplayableCards'},
    {label: 'Display Card sum', input: 'displayCardsSum'},
    {label: 'Display My today balance', input: 'displayTodaysBalance'},
  ]

  userSettings!: UserSettings
  userSettings$ = this.store.pipe(
    select(state => selectUserSettings(state.user))
  )

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscribeUserSettings()
  }

  subscribeUserSettings() {
    this.userSettings$.subscribe(value => {
      this.userSettings = value
      // console.log(this.userSettings);
      
    })
  }

  handleChangeSettings(key: keyof UserSettings) {
    this.store.dispatch(changeSettings({key: key}))
  }

  
  
}

