import { animate, query, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { GameTimersService } from 'src/app/services/game-timers.service';
import { timeBankTimerDone } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { isPlayerTurn, selectPlayerTimers } from 'src/app/state/selectors/game.selector';
import { runTimer } from './animations/run-timer.animation';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { DisplayModeService } from 'src/app/services/display-mode.service';


@Component({
  selector: 'app-timer-dial',
  templateUrl: './timer-dial.component.html',
  styleUrls: ['./timer-dial.component.scss'],
  animations: [
    trigger('timer', [
      transition('* => run_timer', [
        useAnimation(runTimer)
      ], {params: {strokeDashoffset_start: 0, strokeDashoffset_end: 0}})
    ])
  ]
})
export class TimerDialComponent implements OnInit {

  // @Input() opponent!: boolean
  @Input() player!: number
  @Input() replay: boolean = false

  // @ViewChild('timer_ref', {static:false, read: ElementRef}) timer_ref!: ElementRef;

  dial_icon = "assets/images/game/dial.svg"
  remainingTime: number = 60
  is_player_turn!: boolean;
  is_player_turn$!: Subscription

  timeBankTimer!: boolean
  timeBankTimerPaused: boolean = false
  playerTimers$ = this.store.pipe(
    select(state => selectPlayerTimers(state.game, this.player))
  )
  
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )


  constructor(private store: Store<AppState>, private timers: GameTimersService,
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeIsPlayerTurn()
    this.subscribePlayerTimers()
    this.subscribeAppWrapperSize()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }
  subscribePlayerTimers() {
    this.playerTimers$.subscribe(value => {
      this.timeBankTimer = value.timeBankTimer
      // console.log(this.timeBankTimer + " : " +  this.player);
      
      if (this.timeBankTimer === true) {
        // console.log('subscribe');
        this.timerAnimationState = 'run_timer'
        this.subscribeTimeBankTimer()
        this.timeBankTimerPaused = false
        return 
      }

      if (this.timeBankTimer === false) {
        this.timerAnimationState = 'timer_paused'
        this.timeBankTimerPaused = true
        if (this.timeBankSubscription$) {
          this.timeBankTimer_event$.next(true)
          this.timeBankSubscription$.unsubscribe()
        }
        return 
        
      }

    })
  }

  timeBankSubscription$!: Subscription
  timeBankTimer_event$ = new Subject<boolean>();
  
  subscribeTimeBankTimer() {
    
    let fromTime = this.remainingTime
    this.timers.timeBankTimerReset(0, fromTime * 1000, this.timeBankTimer_event$)
    this.timeBankSubscription$ = this.timers.timeBankTimer.subscribe(
      n => {
        this.remainingTime = fromTime - n 
        // console.log(n);        
        // console.log(fromTime - n);
      }, 
      err => {}, 
      () => { 
        if (this.remainingTime === 1) {
          this.remainingTime = 0
        }
        this.calculateRemainingTimer()
        // console.log("complete time bank");
        // this.remainingTime = 0
      } 
     )
  }

  subscribeIsPlayerTurn() {
    this.is_player_turn$ = this.store.pipe(
      select(state => isPlayerTurn(state.game, this.player))
    ).subscribe(value => {
      this.is_player_turn = value
    })
  }

  calculateRemainingTimer() {
    
    if (this.timeBankTimerPaused) {      
      return {
        'stroke-dashoffset': -87  + ((87/60) * this.remainingTime )
      }
    }
    if (this.remainingTime === 0) {
      return {
        'stroke-dashoffset': -87
      }
    }
    return {}
    
  }

  timerAnimationState!: string
  handleTimerAnimation() {
    let strokeDashoffset_start =  (-87 + ((87/60) * this.remainingTime ));
    return {
      value: this.timerAnimationState,
      params: {
        strokeDashoffset_start: strokeDashoffset_start,
        strokeDashoffset_end: -87
      }
    }
  }
  timerAnimationStart($event: any) {
    
  }
  timerAnimationDone($event: any) {
  }

  countdownAnimationState = '*' 
  handleCountdownAnimation() {
    return this.countdownAnimationState;
  }

  timerSize(){
    // let width = this.appWrapperSize.width / 17.4
    // let height = this.appWrapperSize.height / 9.3

    // let size = Math.min(width, height, 60)
    let defaultSize = 30
    let size = (this.displayModeServices.expanded * defaultSize)
    // let height = (this.displayModeServices.expanded)
    return {
      width: size + 'px',
      height: size + 'px'
    }
  }

}
