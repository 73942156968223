import { LobbyComponent } from './lobby.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameTypeComponent } from './game-type/game-type.component';
import { BuyInComponent } from './buy-in/buy-in.component';
import { LandscapeComponent } from './landscape/landscape/landscape.component';
import { StartTheGameComponent } from './start-the-game/start-the-game.component';
import { ModalsModule } from '../../modals/modals.module';
import { InviteOpponentInputComponent } from '../shared/invite-opponent-input/invite-opponent-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    LobbyComponent, 
    GameTypeComponent, 
    BuyInComponent, 
    LandscapeComponent, 
    StartTheGameComponent,
    InviteOpponentInputComponent,
  ],
  imports: [
    CommonModule,
    ModalsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LobbyComponent,
  ]
})

export class LobbyModule { }
