import { Component, Input, OnInit } from '@angular/core';
import { select, State, Store } from '@ngrx/store';
import { Game, GlowingStates } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { AppState } from 'src/app/state/app.state';
import { isPlayerTurn, selectGlowingState, selectPlayerOnTurn } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-active-player-arrow',
  templateUrl: './active-player-arrow.component.html',
  styleUrls: ['./active-player-arrow.component.scss']
})
export class ActivePlayerArrowComponent implements OnInit {
  
  // @Input() opponent_bar!: boolean;
  @Input() player!: number
  @Input() replay: boolean = false

  active_player_arrow_icon = "assets/images/game/active_user_arrow.png"
  inactive_player_arrow_icon = "assets/images/game/inactive_user_arrow.png"
  
  
  is_player_turn!: boolean 
  is_player_turn$ = this.store.pipe(
    select(state => isPlayerTurn(state.game, this.player))
  ).subscribe(value => {
    // console.log("active layer arrow: " + value);
    this.is_player_turn = value
  })

  glowingState: boolean = false
  glowingState$ = this.store.pipe(
    select(state => selectGlowingState(state.game))
  )
  constructor(private store: Store<AppState>, 
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeGlowingState();
  }

  subscribeGlowingState() {
    this.glowingState$.subscribe(value => {
      
      if (value === GlowingStates.glow_arrow
        || value === GlowingStates.glow_all) 
      {
        this.glowingState = true
        return 
      }

      this.glowingState = false

    })
  }
  arrowSize() {
    let heightDefault = 19
    let widthDefault = 23
    
    let height = (this.displayModeServices.expanded * heightDefault)
    let width = (this.displayModeServices.expanded * widthDefault)
    
    return {
      width: width + 'px',
      height: height + 'px',

    }
  }

}
