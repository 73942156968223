import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import * as UserActions from 'src/app/state/actions/user.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logo_icon = "assets/images/svg/logo.svg"
  user$ = this.store.pipe(select('user'));
  
  /** 
   * users only for testing purpose
  */
  availableUsersName = [
    "player001", "player002", "player003"
  ]
  displayUsers = "none";
  selectedUser = "player001";

  constructor(
    private store: Store<AppState>,
  ) { }

  

  ngOnInit(): void {

  }

  loadUser(item: string) {
    this.store.dispatch(UserActions.getUserRequest({userToken: item}));
    this.displayUsers = "none"; 
  }

  /**
   * only for testing purposes
   */
  showAvailableTestingUsers() {
    console.log("show");
    
    if (this.displayUsers === "none") {
      this.displayUsers = "block";
      return;
    }
    
    this.displayUsers = "none"; 
  }

  





}
