import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FilerDateType, FilterBuyInValue, FilterGameResult, MyGamesFilterResults, UserMyGamesFilter } from 'src/app/models/user.model';
import { changeMyGamesFilterBuyIn, changeMyGamesFilterDate, changeMyGamesFilterResults } from 'src/app/state/actions/user.actions';
import { AppState } from 'src/app/state/app.state';
import { isMobileLandscapeMode, isPortraitMode } from 'src/app/state/selectors/display-mode.selector';
import { selectMyGamesFilter } from 'src/app/state/selectors/user.selector';


@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.scss']
})
export class MyGamesComponent implements OnInit {

  
  
  toggleElementIndex: number = -1

  isMobileLandscape!: boolean
  isMobileLandscape$ = this.store.pipe(
    select(state => isMobileLandscapeMode(state.displayMode))
  )

  selectedGameResults!: number
  gameResults: any[] = [
    { label: "All", value: FilterGameResult.All },
    { label: "Won", value: FilterGameResult.Won },
    { label: "Lost", value: FilterGameResult.Lost },
  ]

  selectedBuyIns: any[]  = [ 0 ]
  buyIns: any[] = [
    { label: "All", name: "buyin_filter", short: 'All', value: 'All', type: 'checkbox', checkmark: 'square'}, 
    { label: "Free", name: "buyin_filter", short: 'Free', value: '0', type: 'checkbox', checkmark: 'square'},
    { label: "1 000 UGX", name: "buyin_filter", short: '1', value: '1000', type: 'checkbox', checkmark: 'square'},
    { label: "2 000 UGX", name: "buyin_filter", short: '2', value: '2000', type: 'checkbox', checkmark: 'square'},
    { label: "5 000 UGX", name: "buyin_filter", short: '5', value: '5000', type: 'checkbox', checkmark: 'square'},
    { label: "10 000 UGX", name: "buyin_filter", short: '10', value: '10000', type: 'checkbox', checkmark: 'square'},
    { label: "20 000 UGX", name: "buyin_filter", short: '20', value: '20000', type: 'checkbox', checkmark: 'square'},
    { label: "50 000 UGX", name: "buyin_filter", short: '50', value: '50000', type: 'checkbox', checkmark: 'square'},
  ]

  selectedDateIndex: any[] = [ 0 ]
  dates: any[] = [
    { label: "All", name: "date_filter", short: 'All', value: FilerDateType.ALl, type: 'radio', checkmark: 'circle'}, 
    { label: "Today", name: "date_filter", short: 'Today', value: FilerDateType.Today, type: 'radio', checkmark: 'circle'}, 
    { label: "Yesterday", name: "date_filter", short: 'Yesterday', value: FilerDateType.Yeasterday, type: 'radio', checkmark: 'circle'}, 
    { label: "Last 7 days", name: "date_filter", short: 'Last 7 days', value: FilerDateType.ThisWeek, type: 'radio', checkmark: 'circle'}, 
    { label: "Last 30 days", name: "date_filter", short: 'Last 30 days', value: FilerDateType.ThisMonth, type: 'radio', checkmark: 'circle'}, 
  ]
  
  selectedMyGamesFilter!: UserMyGamesFilter
  selectedMyGamesFilter$ = this.store.pipe(
    select(state => selectMyGamesFilter(state.user))
  )
  
  
  myGames: MyGamesFilterResults[] = [
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (1*60*60*1000)),
      gameEnded: new Date(),
      buyIn: 0,
      win: 0,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: true
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (6*60*60*1000)),
      gameEnded: new Date(Date.now() - (5.8*60*60*1000)),
      buyIn: 10000,
      win: 19800,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: false,
      iamWinner: false
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (1*60*60*1000)),
      gameEnded: new Date(),
      buyIn: 0,
      win: 0,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: true
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (6*60*60*1000)),
      gameEnded: new Date(Date.now() - (5.8*60*60*1000)),
      buyIn: 10000,
      win: 19800,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: false
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (25.3*60*60*1000)),
      gameEnded: new Date(Date.now() - (25*60*60*1000)),
      buyIn: 0,
      win: 0,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: true
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (26.1*60*60*1000)),
      gameEnded: new Date(Date.now() - (26*60*60*1000)),
      buyIn: 10000,
      win: 19800,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: false
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (36.3*60*60*1000)),
      gameEnded: new Date(Date.now() - (36*60*60*1000)),
      buyIn: 0,
      win: 0,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: true
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (39.1*60*60*1000)),
      gameEnded: new Date(Date.now() - (39*60*60*1000)),
      buyIn: 10000,
      win: 19800,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: false
    },
    {
      gameId: '25874521587',
      gameStarted: new Date(Date.now() - (50*60*60*1000)),
      gameEnded: new Date(Date.now() - (49.7*60*60*1000)),
      buyIn: 10000,
      win: 19800,
      opponent: 'KapitanKapitanKapitan', 
      opponentOnline: true,
      iamWinner: false
    },
  ] 

  
  // myGames: MyGamesFilterResults[] = []

  constructor(private store: Store<AppState>) {}
  ngOnInit(): void {
    this.subscribeMyGamesFilter()
  }

  emitToggleElement(index: number) {
    // console.log(index);
    this.toggleElementIndex = index
  }
  subscribeMyGamesFilter() {
    this.selectedMyGamesFilter$.subscribe(value => {

      this.selectedGameResults = value.gamesResult
      
      
      this.selectedBuyIns = []
      //for each value in value.buyIns get indexes in this.buyIns
      value.buyIns.forEach(v => {
        // console.log(v);
        let index = -1
        this.buyIns.forEach((buyIn, key) => {
          if (buyIn.value === v) {
            index = key
          }        
        })
        // console.log(index);
        if (index !== -1 && this.selectedBuyIns.indexOf(index) === -1) {
          this.selectedBuyIns.push(index)
        } 
      }) 
      if (value.buyIns.length === 0) {
        this.selectedBuyIns = [0]
      }
      
      let dateIndex =  value.date - 1;
      this.selectedDateIndex = [ dateIndex ]

    })
  }

  selectGameResult(index: number) {
    this.selectedGameResults = index;
    this.store.dispatch(changeMyGamesFilterResults({values: this.gameResults[index].value}))
  }

  dispatchBuyIns() {

    if (this.selectedBuyIns.includes(0)) {
      this.store.dispatch(changeMyGamesFilterBuyIn({values: []}))
    }

    let values: FilterBuyInValue[] = this.selectedBuyIns.map((value) => {
      // console.log(this.buyIns[value].value);
       
      return this.buyIns[value].value
    })
    this.store.dispatch(changeMyGamesFilterBuyIn({values: values}))
  }

  selectedBuyIn(buyInIndex: number) {
    
    if (buyInIndex === 0) {
      this.selectedBuyIns = [ 0 ]
      this.dispatchBuyIns()  
      return 
    }
    

    if (this.selectedBuyIns.indexOf(0) !== -1) {
      this.selectedBuyIns.splice(0, 1)  
    }

    let index = this.selectedBuyIns.indexOf(buyInIndex)
    if (index === -1) {
      this.selectedBuyIns.push(buyInIndex)
      this.dispatchBuyIns()  
      return 
    }
    
    this.selectedBuyIns.splice(index, 1)

    if (this.selectedBuyIns.length === 0) {
      this.selectedBuyIns = [ 0]
    }
    this.dispatchBuyIns()  
  }
  selectedDate(index: number) {
    console.log(index);
    this.selectedDateIndex = [ index ]
    // console.log(this.selectedDateIndex);
    
    this.store.dispatch(changeMyGamesFilterDate({values: this.dates[index].value}))
  }

  selectedBuyInsShorts() {
    let result: any[] = []
    result = this.selectedBuyIns.map((value) => {
      return this.buyIns[value].short
    })
    result.sort(( a: string, b: string ) => {    
      if (a === 'Free') {
        return -1
      }  

      if (b === 'Free') {
        return 1
      }  

      if (a.length > b.length) {
        return 1
      }
      if (a.length < b.length) {
        return -1
      }
      if (a > b) {
        return 1
      }
      if (a < b) {
        return -1
      }

      return 0
    })
    return result
  }
  selectedDateShort() {
    let result: any[] = []
    result = this.selectedDateIndex.map(value => {
      return this.dates[value].short
    }) 

    // console.log(result);
    
    return result
  }

  getDayBar(itemDate: Date, previousItemDate: Date) {
    if (itemDate.getFullYear() != previousItemDate.getFullYear()) {
      return true
    }

    if (itemDate.getMonth() != previousItemDate.getMonth()) {
      return true
    }
    
    if (itemDate.getDate() != previousItemDate.getDate()) {
      return true
    }

    return false
  }
  

  DAY_TIME = 24 * 60 * 60 * 1000;
  getDayBarDate(itemDate: Date) {
    let today = new Date()
    let yesterday = new Date(today.getTime() - this.DAY_TIME)

    if (itemDate.getFullYear() === today.getFullYear()
    && itemDate.getMonth() === today.getMonth() 
    && itemDate.getDate() === today.getDate()) {
      return "Today"
    }
    if (itemDate.getFullYear() === yesterday.getFullYear()
    && itemDate.getMonth() === yesterday.getMonth() 
    && itemDate.getDate() === yesterday.getDate()) {
      return "Yesterday"
    }
    
    return itemDate.getDate() + "." + itemDate.getMonth() + "." 

  } 


}

