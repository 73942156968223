import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-todays-balance',
  templateUrl: './todays-balance.component.html',
  styleUrls: ['./todays-balance.component.scss']
})
export class TodaysBalanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
