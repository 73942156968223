<div class="countdown_wrapper" [ngStyle]="timerSize()">
    <svg
        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 31 31">
        <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="60%" y2="20%" gradientTransform="rotate(-15)">
              <stop offset="0%" style="stop-color:#ba0000" />
              <stop offset="100%" style="stop-color:#ff7f05" />
            </linearGradient>
        </defs>
        <defs>
          <filter id="hln70wyhva" width="204.1%" height="204.4%" x="-52.1%" y="-52.2%" filterUnits="objectBoundingBox">
              <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5"/>
              <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
              <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"/>
                  <feMergeNode in="SourceGraphic"/>
              </feMerge>
          </filter>
          <filter id="7um6jrjdpe" width="104.3%" height="104.3%" x="-2.2%" y="-2.2%" filterUnits="objectBoundingBox">
              <feOffset dx="-1" dy="-1" in="SourceAlpha" result="shadowOffsetInner1"/>
              <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
              <feColorMatrix in="shadowInnerInner1" result="shadowMatrixInner1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetInner2"/>
              <feComposite in="shadowOffsetInner2" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner2"/>
              <feColorMatrix in="shadowInnerInner2" result="shadowMatrixInner2" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
              <feMerge>
                  <feMergeNode in="shadowMatrixInner1"/>
                  <feMergeNode in="shadowMatrixInner2"/>
              </feMerge>
          </filter>
          <radialGradient id="ngzyb1i53d" cx="50%" cy="50%" r="85.742%" fx="50%" fy="50%">
              <stop offset="0%" stop-color="#404145" stop-opacity=".851"/>
              <stop offset="100%" stop-color="#919191" stop-opacity="0"/>
          </radialGradient>
          <linearGradient id="qqkrc82zxb" x1="30.746%" x2="53.972%" y1="-47.902%" y2="100%">
              <stop offset="0%" stop-color="#8F9199"/>
              <stop offset="100%" stop-color="#2F3031"/>
          </linearGradient>
          <circle id="n8x614z2kc" cx="11.5" cy="11.5" r="11.5"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <g>
              <g filter="url(#hln70wyhva)" transform="translate(-382 -22) translate(389.54 29.624)">
                  <g>
                      <g fill-rule="nonzero" transform="translate(-3.5,-3.5)">
                          <use fill="url(#qqkrc82zxb)" xlink:href="#n8x614z2kc"/>
                          <use fill-opacity=".5" style="mix-blend-mode:overlay" xlink:href="#n8x614z2kc"/>
                          <use fill="url(#ngzyb1i53d)" xlink:href="#n8x614z2kc"/>
                          <use fill="#000" filter="url(#7um6jrjdpe)" xlink:href="#n8x614z2kc"/>
                      </g>
                      <path 
                        stroke="#979797" stroke-linecap="square" d="M11.5.5L11.5 1.5M11.5 21.5L11.5 22.5M19.278 3.722L18.571 4.429M4.429 18.571L3.722 19.278M3.722 3.722L4.5 4.5M18.5 18.5L19.278 19.278M22.5 11.5L21.5 11.5M1.5 11.5L.5 11.5" 
                        transform="translate(-3.5, -3.5)" />
                  </g>
              </g>
          </g>
      </g>
      
        <path             
            *ngIf="(replay === false)"
            #timer_ref
            [@timer]="handleTimerAnimation()"
            (@timer.start)= "timerAnimationStart($event)"
            (@timer.done) = "timerAnimationDone($event)"
            [ngStyle]="calculateRemainingTimer()"
            id="dial_timer"
            stroke="url(#grad1)"
            d="M15.5,1.5c7.7,0,14,6.3,14,14s-6.3,14-14,14s-14-6.3-14-14c0-7.5,6-13.7,13.4-14">
        </path>
      
    </svg>
    <div class="countdown" [ngClass]="{'red': (replay === true)}" >
        {{remainingTime}}
    </div>
</div>
