import { createSelector } from "@ngrx/store"
import { timer } from "rxjs";
import { Game, GameTimers } from "src/app/models/game.model"

export const gameStatus = (state: Game) => state.gameStatus;
export const glowingState = (state: Game) => state.glowingState;    
export const playerInfoState = (state: Game) => state.playerInfoState

export const opponentName = (state: Game) => state.opponentName;
export const opponentCards = (state: Game) => state.opponentCards

const opponentRematchStatus = (state: Game) => state.opponentRematchStatus
const playerRematchstatus = (state: Game) => state.playerRematchstatus

const startDealCardForPlayer = (state: Game) => state.startDealCardForPlayer

export const playerMoves = (state: Game) => state.playerMoves
export const cutterCard = (state: Game) => state.cutterCard

export const winner = (state: Game) => state.winner

export const selectPlayerOnTurn = (state: Game) => state.playerTurn;
export const playerCards = (state: Game) => state.playerCards;
export const numberOfOpponentCards = (state: Game) => state.numberOfOpponentCards;
export const valueOfOpponentCards = (state: Game) => state.valueOfOpponentCards;
// export const drawCards = (state: Game) => state.drawCards;
export const playerPlaysCard = (state: Game) => state.playerPlaysCard;
export const opponentPlaysCard = (state: Game) => state.opponentPlaysCard;
export const tableCards = (state: Game) => state.tableCards;
export const timers = (state: Game) => state.timers
export const playerAction = (state: Game) => state.playerAction
export const selectedSuit = (state: Game) => state.selectedSuit
export const gameBuyIn = (state: Game) => state.gameBuyIn
export const gameType = (state: Game) => state.gametype
export const numberOfPlayers = 2


export const selectGlowingState = createSelector(
    glowingState,
    (glowingState) => {
        return glowingState
    }
)
export const selectPlayerInfoState = createSelector(
    playerInfoState,
    (playerInfoState) => {
        return playerInfoState
    }
)
export const selectGameStatus = createSelector(
    gameStatus,
    (gameStatus) => {
        return gameStatus
    }
)
export const selectGameBuyIn = createSelector(
    gameBuyIn,
    (buyIn) => {
        return buyIn
    }
)

export const selectOpponentName = createSelector(
    opponentName,
    (opponentName) => {
        return opponentName
    }
)

export const selectOpponentCards = createSelector(
    opponentCards,
    (opponentCards) => {
        return opponentCards
    }
)

export const selectPlayerMoves = createSelector(
    playerMoves,
    (playerMoves) => {
        return playerMoves
    }
)

export const selectCutterCard = createSelector(
    cutterCard,
    (cutterCard) => {
        return cutterCard
    }
)

export const selectSelectedSuit = createSelector(
    selectedSuit,
    (suit) => {
        return suit
    }
)

export const selectWinner = createSelector(
    winner,
    (winner) => {
        return winner
    }
)

export const selectTimers = createSelector(
    timer,
    (timers) => {
        return timers
    }
)
export const selectPlayerTimers = createSelector(
    timers,
    (timers: GameTimers[], player: number) => {
        return timers[player]
    }
)
export const selectPlayerAction = createSelector(
    playerAction,
    (playerAction: boolean[], player: number) => {
        return playerAction[player]
    }
)

export const selecttableCards = createSelector(
    tableCards,
    (cards) => {
        return cards;
    }
)

export const selectOpponentPlaysCard = createSelector(
    opponentPlaysCard,
    (card) => {
        return card;
    }
)
export const selectPlayerPlaysCard = createSelector(
    playerPlaysCard,
    (card) => {
        return card;
    }
)

export const selectNumberOfOpponentCards = createSelector(
    numberOfOpponentCards,
    (numberOfCards) => {
        return numberOfCards
    }
)

export const selectValueOfOpponentCards = createSelector(
    valueOfOpponentCards,
    (valueOfOpponentCards) => {
        return valueOfOpponentCards     
    }
)

export const selectPlayerCards = createSelector(
    playerCards,
    (playerCards) => {
        return playerCards;
    }
)

export const selectNextPlayerOnTurn = createSelector(
    selectPlayerOnTurn,
    (playerTurn) => {
        return (playerTurn + 1) % numberOfPlayers
    }
)

export const playerTurn = createSelector(
    selectPlayerOnTurn,
    (playerTurn) => {
        return playerTurn
    }
)

export const isPlayerTurn = createSelector(
    selectPlayerOnTurn,
    (playerTurn: number, player: number) => {
        // console.log(playerTurn);
        // console.log(player);
                
        if (playerTurn == player) {
            return true
        }
        
        // if (playerTurn == 0 && !props.opponent || playerTurn != 0 && props.opponent) {
        //     return true
        // }
        
        return false
    }
)

export const selectRematchStatus = createSelector(
    opponentRematchStatus,
    playerRematchstatus,
    (opponentStatus, playerStatus) => {
        return {
            opponentStatus,
            playerStatus
        }
    }
)
export const selectWinAfterGame = createSelector(
    gameBuyIn,
    (gameBuyIn) => {
        let feeForUser = null;
        if (gameBuyIn != null && gameBuyIn > 0) {
            let fee = gameBuyIn / 100;
            feeForUser = (fee > 50) ? fee : 50;
        }
        
        let playerWon = 0
        if (gameBuyIn > 0 && feeForUser) {
            playerWon = gameBuyIn * 2 - (feeForUser * 2)
        }
        
        return {
            playerWon: playerWon
        }
    }
)
export const selectGameOptionsForRematch = createSelector(
    opponentName,
    gameBuyIn,
    gameType,
    (opponentName, gameBuyIn, gameType) => {
        let feeForUser = null;
        let possibleUserWin = 0;

        if (gameBuyIn != null && gameBuyIn > 0) {
            let fee = gameBuyIn / 100;
            feeForUser = (fee > 50) ? fee : 50;
        }
        
        if (gameBuyIn > 0 && feeForUser) {
            possibleUserWin = gameBuyIn * 2 - (feeForUser * 2)
        }

        
        return {
            opponentName: opponentName,
            gameBuyIn: gameBuyIn,
            gameType: gameType,
            feeForUser: feeForUser,
            possibleUserWin: possibleUserWin
        }
    }
)

export const selectStartDealCardForPlayer = createSelector(
    startDealCardForPlayer,
    (player) => {
        return player
    }
)