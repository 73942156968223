import { BuyInValue } from "./lobby.model";

export interface User {
    playerId: string,
    name: string,
    balance: number,   
    settings: UserSettings,
    winLostRate: UserWinLostRate,
    myGamesFilter: UserMyGamesFilter,
    statisticsFilter: UserStatisticsFilter,
    statisticsFilterError: UserStatisticsFilterError
}

export interface UserWinLostRate {
    win: number,
    lost: number
}

export interface UserSettings {
    [key: string]: boolean
    
    soundOn: boolean,
    playCardByDoubleClick: boolean,//PlayCardMethodType,
    autoSwitchTurn: boolean,
    displayUnplayableCards: boolean,
    displayCardsSum: boolean,
    displayTodaysBalance: boolean,
}

export interface UserStatisticsFilter {
    opponent: string,
    date: FilerDateType    
}

export enum UserStatisticsFilterError {
    none = 'none',
    incorrectOpponentName = 'incorrectOpponentName'
}

export interface UserMyGamesFilter {
    gamesResult: FilterGameResult,
    buyIns: FilterBuyInValue[],
    date: FilerDateType
}

export type FilterBuyInValue =  BuyInValue

export enum FilterGameResult {
    All = 1,
    Lost,
    Won
}
export enum FilerDateType {
    ALl = 1,
    Today,
    Yeasterday,
    ThisWeek,
    ThisMonth
}


//StatisticEntryResult
export interface MyGamesFilterResults {
    gameId: string,
    gameStarted: Date,
    gameEnded: Date,
    buyIn: BuyInValue,
    win: number
    opponent: string, 
    opponentOnline: boolean,
    iamWinner: boolean
}

//StatisticSumResult
export interface StatisticsFilterResults {
        buyIn: BuyInValue,
        win: number,
        lost: number   
}

// export enum PlayCardMethodType {
//     SINGLE = "SINGLE", 
//     DOUBLE = "DOUBLE"
// }