<app-table-control-left [replay]="true">
</app-table-control-left>

<app-selected-suit [replay]="true">
</app-selected-suit>

<div 
    class="table-wrapper">
    <div class="table-wrapper-inner" [ngStyle]="tableWrapperSize()">
        <img src="{{table_image}}" alt=""/>
        <div 
            class="table_cards"
            #table_cards_ref>
            <app-card
                *ngFor="let card of tableCards"
                [card]="card"
                [ngStyle]="calculateRandomStyle(card)"
                class=""> 
            </app-card>
        </div>
    </div>
</div>

<app-table-control-right [replay]="true">
</app-table-control-right>


