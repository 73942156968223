import { GameType, GameVariant } from "../models/lobby.model";

export const gameVariants = new Map<GameType, GameVariant>([
    [ 
        GameType.H2H, 
        { 
            name: "Head2Head", 
            icon: "assets/images/svg/globe-africa-gray.svg", 
            type: GameType.H2H,
            classes: [] 
        }
    ],
    [
        GameType.FRIEND,
        {
            name: "Invite a friend", 
            icon: "assets/images/svg/user-friends-whitte.svg", 
            type: GameType.FRIEND,
            classes: []
        }
    ],
]);