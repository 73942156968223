import { Component, OnInit } from '@angular/core';
import { DisplayModeService } from 'src/app/services/display-mode.service';

@Component({
  selector: 'app-game-replay-player',
  templateUrl: './game-replay-player.component.html',
  styleUrls: ['./game-replay-player.component.scss']
})
export class GameReplayPlayerComponent implements OnInit {

  constructor(private displayModeServices: DisplayModeService) { }
  
  player_name = "playerName"

  ngOnInit(): void {
  }
  barWrapperSize() {
    let defaultHeight = 40
    let height = (this.displayModeServices.expanded * defaultHeight)

    return {
      height: height + 'px'
    }
  }

}
