import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { select, State, Store } from '@ngrx/store';
import { StartGameErrors } from 'src/app/models/lobby.model';
import { SignalrService } from 'src/app/services/signalr.service';
import { AppState } from 'src/app/state/app.state';
import { canStartTheGame, isSelectedBuyIn, opponent } from 'src/app/state/selectors/lobby.selector';

@Component({
  selector: 'app-start-the-game',
  templateUrl: './start-the-game.component.html',
  styleUrls: ['./start-the-game.component.scss']
})
export class StartTheGameComponent implements OnInit {

  @Input() startTheGameSignal: boolean = false

  //isNoBuyModalVisible: boolean = false;
  errorModadlVisible!: StartGameErrors; 
  isConfirmModalVisible: boolean = false;


  isSelectedBuyIn!: boolean
  isSelectedBuyIn$ = this.store.pipe(
    select(state => isSelectedBuyIn(state.lobby))
  )
  
  opponent!: any
  opponent$ = this.store.pipe(
    select(state => opponent(state.lobby))
  )

  startTheGameError!: StartGameErrors 
  canStartTheGame$ = this.store.pipe(
    select(state => canStartTheGame(state.lobby))
  )

    
  constructor(private store: Store<AppState>, private signalR: SignalrService) { }

  ngOnInit(): void {    
    this.subscribeSelectedbuyIn()
    this.subscribeCanStartTheGame()
    this.subscribeOpponent()
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes.startTheGameSignal);
    
    if (changes.startTheGameSignal.previousValue === false && changes.startTheGameSignal.currentValue === true) {
      this.startTheGame()      
    }
    
  }

  subscribeOpponent() {
    this.opponent$.subscribe(value => {
      this.opponent = value.name
    })
  }
  subscribeCanStartTheGame() {
    this.canStartTheGame$.subscribe(value => {
      //console.log(value);
      this.startTheGameError = value
    });
  }

  subscribeSelectedbuyIn() {
    this.isSelectedBuyIn$.subscribe(value => {
      this.isSelectedBuyIn = value
      //console.log(value);
      
    })
  }
  closeErrorModal() {
    this.errorModadlVisible = StartGameErrors.none
  }

  startTheGame() {
    //console.log(this.startTheGameError);
    
    if (this.startTheGameError === StartGameErrors.none) {
      this.isConfirmModalVisible = true
      return
    }

    //console.log(this.opponent);
    
    this.errorModadlVisible = this.startTheGameError;

    // if (this.canStartTheGame) {

    // }
    // if (!this.isSelectedBuyIn) {
    //   this.isNoBuyModalVisible = true;
    //   return;
    // }

    // this.isConfirmModalVisible = true;
    //this.signalR.startGame();
  }

  calculateClass(): string[] {
    var result: string[] = [];
    if (!this.isSelectedBuyIn) {
      result.push("disabled");
    }
    return result;
  }

}
