import { LobbyModule } from './lobby/lobby.module';
import { HeaderModule } from './shared/header/header.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SubheaderComponent } from './shared/subheader/subheader.component';
import { OnlinePlayersComponent } from './shared/subheader/online-players/online-players.component';
import { TodaysBalanceComponent } from './shared/subheader/todays-balance/todays-balance.component';
import { WinLostRateComponent } from './shared/subheader/win-lost-rate/win-lost-rate.component';
import { SettingsComponent } from './settings/settings.component';
import { MyGamesComponent } from './my-games/my-games.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { SelectComponent } from './shared/select/select.component';
import { GameInTableComponent } from './my-games/game-in-table/game-in-table.component';
import { ModalsModule } from '../modals/modals.module';




@NgModule({
  declarations: [
    DashboardComponent,
    FooterComponent,
    SubheaderComponent,
    OnlinePlayersComponent,
    TodaysBalanceComponent,
    WinLostRateComponent,
    StatisticsComponent,
    SettingsComponent,
    MyGamesComponent,
    SelectComponent,
    GameInTableComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    HeaderModule,
    LobbyModule,    
    FormsModule,
    ReactiveFormsModule,
    ModalsModule
  ],
  exports: [


  ]
})
export class DashboardModule { }
