<!-- <div *ngIf="!isMobileLandscape" >  -->
<div
    class="portrait">
    <div class="content-wrapper">
        <div class="title">Choose your game:</div>
        <div class="gameTypes" >
            <app-game-type 
                *ngFor="let gameType of gameTypes; first as isFirst;"  
                [gameVariant]="gameType" 
                [ngClass]="{ 'first': isFirst}">
            </app-game-type>
        </div>
        
        <app-invite-opponent-input (startTheGameEventEmmiter)="inputEvent($event)" class="m-t-15" *ngIf="activeGameType === 'FRIEND'"></app-invite-opponent-input>

        <div class="title">Select your buy-in:</div>    
        <div class="buyInTypes" >
            <ng-container *ngFor="let buyInType of buyInTypes; first as isFirst;" >
                <app-buy-in 
                    *ngIf="!(activeGameType === 'FRIEND' && buyInType.type === 0) "
                    [buyInType]="buyInType"
                    [ngClass]="{ 'first': isFirst, 'friend': (activeGameType === 'FRIEND')}"> 
                </app-buy-in>
            </ng-container>
        </div>
        
    </div>
    
    <app-start-the-game [startTheGameSignal]="startTheGameSignal"></app-start-the-game>
  
</div>

<!-- <app-landscape 
    *ngIf="isMobileLandscape" 
    class="landscape"
    [gameTypes]="gameTypes"
    [buyInTypes]="buyInTypes">
</app-landscape> -->
<app-landscape 
    class="landscape"
    [gameTypes]="gameTypes"
    [buyInTypes]="buyInTypes">
</app-landscape>

<app-general-modal class="wide-landscape footer-right" *ngIf="invitation.name !== ''">
    <header>
        Invitation
    </header>
    <content>
        <p>Your friend</p>
        <p class="opponent input m-t-5 m-b-5">{{invitation.name}}</p>
        <p>Invites you for matatu game with</p>
        <p class="opponent">BUY-IN {{invitation.buyIn}} UGX</p>
    </content>
    <footer>
        <button class="half gray" (click)="rejectInvitation()">Reject</button>
        <button class="half" (click)="acceptInvitation()">Accept</button>
    </footer>
</app-general-modal>