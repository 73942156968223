import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { pipe, timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';
import { GameStatus } from 'src/app/models/game.model';
import { AppState } from 'src/app/state/app.state';
import { selectGameStatus, selectOpponentName } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-table-info-box',
  templateUrl: './table-info-box.component.html',
  styleUrls: ['./table-info-box.component.scss']
})
export class TableInfoBoxComponent implements OnInit {

  @Input() info_message_variant!: string
  @Input() tableWrapperSize!: any

  exclamation_mark_img = "assets/images/game/exclamation_mark.png"
  match_countdown: number = 15
  rematch_countdown: number = 15

  //TODO: subscribe the time bank of player and opponent from the server
  time_bank_countdown: number = 300
  opponent_time_bank_countdown: number = 300

  server_reconnection_countdown: number = 120
  
  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )

  opponentName: string = ''
  opponentName$ = this.store.pipe(
    select(state => selectOpponentName(state.game))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeGameStatus()
    
  }

  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value

      if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
        this.waitingForInvitedFriendCountdown()
        this.subscribeOpponentName()
        return 
      }
      
      if (this.gameStatus === GameStatus.waiting_for_match_start) {
        this.waitingForMatchStartCountdown()
        return 
      }

      if (this.gameStatus === GameStatus.match_started_table_info) {
        //TODO: wait 2s then show timebanks
      }
      if (this.gameStatus === GameStatus.lost_game) {
        this.waitingForAvailableRematch()
        return
      }

      if (this.gameStatus === GameStatus.player_offline_player_turn) {
        this.timeBankTimer()
        return
      }

      if (this.gameStatus === GameStatus.lost_connection_with_server) {
        this.serverReconnectionTimer()
        return
      }
      
      if (this.gameStatus === GameStatus.opponent_offline) {
        this.opponentTimeBankTimer()
        return
      }
    })
  }
  
  subscribeOpponentName() {
    this.opponentName$.subscribe(value => {
      
      this.opponentName = value

      if (this.opponentName.length > 13) {
        this.opponentName = this.opponentName.slice(0, 12) + "..."
      }
      
    })
  }

  waitingForInvitedFriendTimer$!: any
  waitingForInvitedFriendCountdown() {
    this.waitingForInvitedFriendTimer$ = timer(0, 1000).pipe(
      scan(acc => --acc, 21),
      takeWhile(x => x >= 0)
    )    
  }

  waitingForMatchStartTimer$!: any
  waitingForMatchStartCountdown() {
    this.waitingForMatchStartTimer$ = timer(0, 1000).pipe(
      scan(acc => --acc, this.match_countdown + 1),
      takeWhile(time => time >= 0)
    )
  }

  waitingForAvailableRematch$!: any
  waitingForAvailableRematch() {
    this.waitingForAvailableRematch$ = timer(0, 1000).pipe(
      scan(acc => --acc, this.rematch_countdown + 1),
      takeWhile(time => time >= 0)
    )
  }

  timeBankTimer$!: any
  timeBankTimer() {
    this.timeBankTimer$ = timer(0, 1000).pipe(
      scan(acc => --acc, this.time_bank_countdown + 1),
      takeWhile(time => time >= 0)
    )
  }

  serverReconnectionTimer$!: any
  serverReconnectionTimer() {
    this.serverReconnectionTimer$ = timer(0, 1000).pipe(
      scan(acc => --acc, this.server_reconnection_countdown + 1),
      takeWhile(time => time >= 0)
    )
  }
  opponentTimeBankTimer$!: any
  opponentTimeBankTimer() {
    this.opponentTimeBankTimer$ = timer(0, 1000).pipe(
      scan(acc =>  --acc, this.opponent_time_bank_countdown ),
      takeWhile(time => time >= 0)
    )
  }

  size() {
    return {
      width: this.tableWrapperSize.width,
      height: this.tableWrapperSize.height,
    }
  }
}
