import { Component, OnInit } from '@angular/core';
import { DisplayModeService } from 'src/app/services/display-mode.service';

@Component({
  selector: 'app-game-replay',
  templateUrl: './game-replay.component.html',
  styleUrls: ['./game-replay.component.scss']
})
export class GameReplayComponent implements OnInit {

  constructor(private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
  }
  fontSize() {
    let defaultFont = 16
    let fontSize = defaultFont * this.displayModeServices.expanded
    // console.log(this.displayModeServices.expanded, fontSize);
    
    return {
      "fontSize": fontSize + 'px'
    }
  }
  
}
