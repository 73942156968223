import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GameStatus, PlayerInfoStates } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { AppState } from 'src/app/state/app.state';
import { selectGameStatus, selectPlayerInfoState } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-game-player',
  templateUrl: './game-player.component.html',
  styleUrls: ['./game-player.component.scss']
})
export class GamePlayerComponent implements OnInit {
  
  @Input() player!: number

  player_name: string = "Habiba Abdul-Jabbar321"

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  playerInfoState: PlayerInfoStates = PlayerInfoStates.none
  playerInfoState$ = this.store.pipe(
    select(state => selectPlayerInfoState(state.game))
  )

  constructor(private store: Store<AppState>,
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeGameStatus()
    this.subscribePlayerInfoState()
  }

  subscribePlayerInfoState() {
    this.playerInfoState$.subscribe(value => {
      this.playerInfoState = value
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }

  canRenderPlayerCards() {
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent){
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }

    
    return true
  }
  canRenderSumOfPlayerCards() {
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent){
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }


    return true
  }

  canRenderPlayerInfo() {
    if (this.playerInfoState !== PlayerInfoStates.none && this.playerInfoState !== PlayerInfoStates.opponent_is_ready) {
      return true
    }
    
    return false
  }

  barWrapperSize() {
    let defaultHeight = 40
    let height = (this.displayModeServices.expanded * defaultHeight)

    return {
      height: height + 'px'
    }
  }
}
