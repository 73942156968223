import { animate, keyframes, query, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { timeStamp } from 'node:console';
import { Card, GameStatus, PlayerMove } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectGameStatus, selectPlayerCards, selectPlayerMoves, selectValueOfOpponentCards, selectWinner } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-number-of-cards',
  templateUrl: './number-of-cards.component.html',
  styleUrls: ['./number-of-cards.component.scss'],

})
export class NumberOfCardsComponent implements OnInit {
  
  @Input() player!: number 
  @Input() replay: boolean = false

  cards_icon = "assets/images/game/number_of_cards.svg"
  //cards_icon = "assets/images/game/Timer1.svg"
  
  // number_of_cards: number = 23
  valueOfCards: number | null = null

  valueOfOpponentCards: number | null = null
  valueOfOpponentCards$ = this.store.pipe(
    select(state => selectValueOfOpponentCards(state.game))
  )

  valueOfPlayerCards: number | null = null
  playerCards$ = this.store.pipe(
    select(state => selectPlayerCards(state.game))
  )

  playerMoves: PlayerMove[] | null = null
  playerMoves$ = this.store.pipe(
    select(state => selectPlayerMoves(state.game))
  )
  
  winner: number | null = null
  winner$ = this.store.pipe(
    select(state => selectWinner(state.game))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )
  
  constructor(private store: Store<AppState>,
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribePlayerMoves()
    this.subscribePlayerCards()
    this.subscribeValueOfOpponentCards()
    this.subscribeWinner()
    this.subscribeGameStatus()

    this.subscribeAppWrapperSize()

  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }
  subscribeWinner() {
    this.winner$.subscribe(value => {
      this.winner = value
    })
  }

  subscribePlayerMoves() {
    this.playerMoves$.subscribe(value => {
      this.playerMoves = value
    })
  }

  subscribePlayerCards() {
    this.playerCards$.subscribe(cards => {
      
      let sum = cards.reduce((sum: number, card: Card) => {
        return sum + card.number
      }, 0)

      this.valueOfPlayerCards = sum
      
      if (this.player === 0) {
        this.valueOfCards = sum
      }

    })
  }

  subscribeValueOfOpponentCards() {
    this.valueOfOpponentCards$.subscribe(value => {
      this.valueOfOpponentCards = value
      if (this.player === 1) {
        this.valueOfCards = value
      }
    }) 
  }

  getIconColor() {

    // if (this.playerMoves === null) {
    //   return "url(#icon-a)"
    // }

    // if (this.playerMoves[this.playerMoves?.length - 1] === PlayerMove.play_card_seven_of_cutter ) {
    //   if (this.player === 0) {
    //     return (this.valueOfPlayerCards! > this.valueOfOpponentCards!) ? "url(#icon-red)" : "url(#icon-green)"
    //   }
    //   if (this.player === 1) {
    //     return (this.valueOfPlayerCards! > this.valueOfOpponentCards!) ? "url(#icon-green)" : "url(#icon-red)"
    //   }
    // }
    
    if (this.gameStatus !== GameStatus.seven_of_cutter_colors 
      && this.gameStatus !== GameStatus.seven_of_cutter_info_box) {
        return 'gray'
        return "url(#icon-a)"
      }

    if (this.winner === null) {
      return 'gray'
      return "url(#icon-a)"
    }

    if (this.winner === this.player ) {
      return 'green'
      return "url(#icon-green)"
    }

    if (this.winner !== this.player ) {
      return 'red'
      return "url(#icon-red)"

    }

    return 'gray'
    return "url(#icon-a)"
    
  }

  numberSizeAndPosition() {

 
  

    let defaultSize = 34
    let size = (this.displayModeServices.expanded * defaultSize)



    let left = 0
    if (this.player == 0) {
      let defaultWidth = 110
      let width = defaultWidth * this.displayModeServices.expanded
      left = this.appWrapperSize.width * 0.26 - width/2 - size

      if (this.displayModeServices.rotation == 1) {
        let defaultWidth = 110
        let width = defaultWidth * this.displayModeServices.expanded
        left = this.appWrapperSize.height * 0.26 - width/2 - size
      }

      return {
        width: size + 'px',
        left: left + 'px'
      }

    } 

    left = 0

    return {
      width: size + 'px',
      left: left + 'px'
    }
  }

}
