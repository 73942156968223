import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { FilerDateType, StatisticsFilterResults, UserStatisticsFilterError } from 'src/app/models/user.model';
import { changeStatisticsFilterDate, changeStatisticsFilterOpponent } from 'src/app/state/actions/user.actions';
import { AppState } from 'src/app/state/app.state';
import { selectStatisticsFilterError } from 'src/app/state/selectors/user.selector';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  selectedDateIndex: any[] = [ 0 ] 
  dates: any[] = [
    { label: "All", name: "date_filter", short: 'All', value: FilerDateType.ALl, type: 'radio', checkmark: 'circle'}, 
    { label: "Today", name: "date_filter", short: 'Today', value: FilerDateType.Today, type: 'radio', checkmark: 'circle'}, 
    { label: "Yesterday", name: "date_filter", short: 'Yesterday', value: FilerDateType.Yeasterday, type: 'radio', checkmark: 'circle'}, 
    { label: "Last 7 days", name: "date_filter", short: 'Last 7 days', value: FilerDateType.ThisWeek, type: 'radio', checkmark: 'circle'}, 
    { label: "Last 30 days", name: "date_filter", short: 'Last 30 days', value: FilerDateType.ThisMonth, type: 'radio', checkmark: 'circle'}, 
  ]


  selectedOpponentName: any[] = [ 0 ]
  opponents: any[] = [
    {label: "All", name: "opponent_filter", short: "All", value: "All", type: 'radio', checkmark: 'circle'},
    {label: "Specific opp", name: "opponent_filter", short: "Opponent", value: "Opponent", type: 'radio', checkmark: 'circle'}
  ]

  specificOpponentName = new FormControl('')
  
  selectedMostCommonOpponent!: any
  mostCommonOpponents: any[] = [
    {label: "KapitanKapitanKapitan", name: "common_opponent_filter", value: "opponent_id" },
    {label: "KapitanKapitanKapitan", name: "common_opponent_filter", value: "opponent_id" },
    {label: "KapitanKapitanKapitan", name: "common_opponent_filter", value: "opponent_id" },
    {label: "KapitanKapitanKapitan", name: "common_opponent_filter", value: "opponent_id" },
    {label: "KapitanKapitanKapitan", name: "common_opponent_filter", value: "opponent_id" },
    {label: "KapitanKapitanKapitan", name: "common_opponent_filter", value: "opponent_id" },
  ]

  userStatistics: StatisticsFilterResults[] = [
    {buyIn: 1000, win: 12, lost: 2 },
    {buyIn: 2000, win: 5, lost: 18 },
    {buyIn: 5000, win: 0, lost: 3 },
    {buyIn: 10000, win: 1000, lost: 8 },
    {buyIn: 20000, win: 0, lost: 0 },
    {buyIn: 50000, win: 3, lost: 8 }
  ]

  statisticsFilterError!: UserStatisticsFilterError
  statisticsFilterError$ = this.store.pipe(
    select(state => selectStatisticsFilterError(state.user))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeStatisticFilterError()
  }

  subscribeStatisticFilterError() {
    this.statisticsFilterError$.subscribe(value => {
      this.statisticsFilterError = value
    })
  }

  MAX_UNIT: number = 10
  UNIT_SIZE: number = 7.5

  winBarStyle(win: number, lost: number) {
    
    let unit = this.UNIT_SIZE;

    if (win === 0) {
      return {
        height: "0px",
        paddingTop: "1px"
      }
    }

    if (win > 10 || lost > 10) {
      let bigger = Math.max(win,lost);
      unit = 75/ bigger;
    }

    return {
      height: (unit * win) + "px",
    }
  }
  winBarNumberStyle(win: number, lost: number) {
    let unit = this.UNIT_SIZE;
    if (win > 10 || lost > 10) {
      let bigger = Math.max(win,lost);
      unit = 75/ bigger;
    }
    let height = unit * win;
    // console.log(height);
    
    if (win < 4 || height < 20) {
      return {
        bottom: "22px",
        color: '#c1c1c1'
      }
    }

    if (win > 999) {
      return {
        display: "block",
        paddingLeft: "6px",
        transform: "rotate(90deg)"
      }
    }
    return {}
  }

  lostBarStyle(lost: number, win: number) {
    let unit = this.UNIT_SIZE;

    if (lost === 0) {
      return {
        height: "0px",
        paddingTop: "1px"
      }
    }

    if (win > 10 || lost > 10) {
      let bigger = Math.max(win,lost);
      unit = 75/ bigger;
    }
    
    return {
      height: (unit * lost) + "px",
    }
  }

  lostBarNumberStyle(lost: number, win: number) {
    
    let unit = this.UNIT_SIZE;
    if (win > 10 || lost > 10) {
      let bigger = Math.max(win,lost);
      unit = 75/ bigger;
    }
    let height = unit * lost;
    // console.log(height);   
    

    
    if (lost < 4 || height < 20) {
      return {
        bottom: "22px",
        color: '#c1c1c1'
      }
    }
    
    if (win > 999) {
      return {
        display: "block",
        paddingLeft: "6px",
        transform: "rotate(90deg)"
      }
    }

    return {}
  }


  getLabel(label: string) {
    if (label.length > 8) {
      return label.slice(0,8) + "..."
    }
    return label
  }

  clickMostCommonOpponent($event: any) {
    // console.log("get statistics for specific opponent");
    let opponent = $event.value
    this.store.dispatch(changeStatisticsFilterOpponent({opponent: opponent}))
  }

  specificOpponentButtonInactive() {
    // console.log(this.specificOpponentName.value);
    if (this.specificOpponentName.value) {
      return false
    }
    return true
  }
  clickSpecificOpponent() {
    
    if (this.specificOpponentName.value) {
      console.log("get statistics for specific opponent");
      this.store.dispatch(changeStatisticsFilterOpponent({opponent: this.specificOpponentName.value}))
    }
    
  }

  selectedDateShort(): any[] {
    let result: any[] = []
    result = this.selectedDateIndex.map(value => {
      return this.dates[value].short
    }) 
    return result
  }

  selectedOpponentShort(): any[] {
    let result: any[] = []
    if (this.selectedOpponentName[0] === 0) {
      return [this.opponents[0].short]
    }

    if (this.specificOpponentName.value.length > 10) {
      return [ this.specificOpponentName.value.slice(0,10) + "..."]
    }
    return [ this.specificOpponentName.value ] 
     
  }
  selectedDate(index: number) {    
    this.selectedDateIndex = [ index ]
    this.store.dispatch(changeStatisticsFilterDate({date: this.dates[index].value}))
  }
  selectOpponent(index: number) {
    this.selectedOpponentName = [ index ]
  }

  
  closeErrorModal() {
    this.statisticsFilterError = UserStatisticsFilterError.none
  }
  

}
