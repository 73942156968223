import { animate, animation, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const playerPlayCard = animation([
    query("app-card.played", [
        animate(
            '2s linear',
            keyframes([
                // style({ top: '{{from_y}}', left: '{{from_x}}', transform: 'rotate({{from_rotation}}deg)', offset: 0 }),
                style({ left: '{{from_x}}', top: '{{from_y}}', transform: 'rotate({{from_rotation}}deg)', offset: 0}),
                style({ left: '{{from_x}}', top: '{{from_y_2}}', transform: 'rotate({{from_rotation}}deg)', offset: 0.25}),
                style({ left: '{{to_x}}', top: '{{to_y}}', transform: 'rotate({{to_rotation}}deg)', offset: 1})
                
            ])
        )
    ], {optional: true}),
])