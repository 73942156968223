import { animate, keyframes, query, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Card, CardSuit, CardValue } from 'src/app/models/game.model';
import { CardPositionService } from 'src/app/services/card-position.service';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
  ]
})
export class CardComponent implements OnInit {

  @Input() glowing!: boolean ;
  @Input() card!: Card
  @Input() flipAnimation!: boolean
  
  card_back = "assets/images/game/card_back_3.svg"
  rotation: boolean = false
  
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  
  constructor(private store:Store<AppState>, private cardPositionService: CardPositionService,
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeAppWrapperSize()
    if (this.card.suit !== CardSuit.back) {
      this.rotation = true
    }    


  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }


  calculateCardClass() {    
    if (!this.card) {
      return ""
    }
    return this.card.suit.toString() + "-" + this.card.number.toString();
  }

  cardValue(): string {
    switch(this.card.number) {
      case CardValue.ace: {
        return "A"
      }
      case CardValue.king: {
        return "K"
      }
      case CardValue.queen: {
        return "Q"
      }
      case CardValue.jack: {
        return "J"
      }
      case CardValue.two: {
        return "2"
      }
      default: {
        return this.card.number.toString()
      }
    }
  }

  generateClass() {

    let result:string = this.card.suit

    if (this.glowing === true) {
      result = result + " glow";
    }

    if (this.rotation === true) {
      result = result + " rotated"
    }

    return result

  }

  cardWidth!: number
  calculateCardSize() {    
    let size = this.cardPositionService.calculateCardSize()
    this.cardWidth = size.width

    // let defaultWidth = 0
    // let defaultHeight = 0

    // let width = (this.displayModeServices.expanded * defaultWidth)
    // let height = (this.displayModeServices.expanded * defaultWidth)
    return {
      width: size.width + 'px',
      height: size.height + 'px'
    }
  }

  cornerSuitSize() {
    
    // let widthCard = Math.min(this.appWrapperSize.width / 8.7, this.appWrapperSize.height / 4.6)
    let defaultWidth = 9
    let defaultHeight = 9.5
    let width = (this.displayModeServices.expanded * defaultWidth)
    let height = (this.displayModeServices.expanded * defaultHeight )

  
    return {
      width: width + 'px',
      height: height + 'px'
    }
  }

  centerSuitSize() {

    // let widthCard = Math.min(this.appWrapperSize.width / 8.7, this.appWrapperSize.height / 4.6)
    let defaultWidth = 30.5
    let defaultHeight = 32.25

    let width = (defaultWidth * this.displayModeServices.expanded)
    let height = (defaultHeight * this.displayModeServices.expanded)

    return {
      width: width + 'px',
      height: height + 'px'
    }
  }

}
