<ng-container *ngIf="canRenderGameControl()">

    <div class="game-id">
        Game ID: {{game_id}}
    </div>
    <button class="give-up" (click)="handleGiveUpModal()" *ngIf="canRenderGameGiveUpButton()">
        <i class="arrow-down left"></i>
        Give Up the Game
        <i class="arrow-down right"></i>
    </button>

    <app-give-up-modal *ngIf="give_up_modal">
        <content>
            <p class="bold">Do you want do give up this game?</p>
            <p>If you decide to give up you lost your buy-in.</p>
        </content>
        <footer>
            <button  
                (click)="giveUpGame()"
                class="giveup">Yes, I give up</button>
            <button 
                (click)="continueInGame()"
                class="continue m-l-16">No, resume the game</button>
        </footer>    
    </app-give-up-modal>

</ng-container>