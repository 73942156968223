<div class="card" [ngStyle]="calculateCardSize()">
    <div class="flip-front card-inner" [ngClass]="generateClass()" *ngIf="card.suit !== 'back'">
        <div class="card-values">
            <div class="top corner" >
                <div class="number">{{cardValue()}}</div>
                <div class="suit" [ngStyle]="cornerSuitSize()"></div>
            </div>
            <div class="center" [ngStyle]="centerSuitSize()">
                <div class="suit"></div>
            </div>
            <div class="bottom corner">
                <div class="number">{{cardValue()}}</div>
                <div class="suit" [ngStyle]="cornerSuitSize()"></div>
            </div>
        </div>
    </div>

    <div class="flip-back card-inner" [ngClass]="generateClass()" >
        <img src="{{card_back}}" alt="">
    </div>
</div>

