import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import * as LobbyActions from 'src/app/state/actions/lobby.actions'
import * as LobbySelectors from 'src/app/state/selectors/lobby.selector'
import { BuyIn, BuyInValue, GameType, GameVariant, Lobby } from 'src/app/models/lobby.model';
import { SocketMessage, SocketMessageType } from 'src/app/models/socket-message.model';
import { Observable } from 'rxjs';
import { isMobileLandscapeMode, isPortraitMode } from 'src/app/state/selectors/display-mode.selector';

export enum LobbyContent {

}

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit {

  gameTypes: GameVariant[] = [
    { 
      name: "Head2Head", 
      icon: "assets/images/svg/globe-africa-gray.svg", 
      type: GameType.H2H,
      classes: ["m-r-5"]
    },
    { 
      name: "Invite a friend", 
      icon: "assets/images/svg/user-friends-whitte.svg", 
      type: GameType.FRIEND,
      classes: ["m-l-5"]
    }
  ];

  buyInTypes: BuyIn[] = [
    { name: "FREE GAME", type: 0, classes: ["m-b-8"]},
    { name: "1,000 UGX", type: 1000, classes: ["m-r-4", "m-b-8"]},
    { name: "2,000 UGX", type: 2000, classes: ["m-l-4","m-b-8"] },
    { name: "5,000 UGX", type: 5000, classes: ["m-r-4","m-b-8"] },
    { name: "10,000 UGX", type: 10000, classes: ["m-l-4","m-b-8"] },
    { name: "20,000 UGX", type: 20000, classes: ["m-r-4","m-b-8"] },
    { name: "50,000 UGX", type: 50000, classes: ["m-l-4","m-b-8"] },
  ];

  invitation!: any
  invitation$ = this.store.pipe(
    select(state => LobbySelectors.invitation(state.lobby))
  )
  
  //lobby!: Lobby
  //lobby$ = this.store.pipe(select('lobby'));
  activeGameType!: GameType
  activeGameType$ = this.store.pipe(
    select(state => LobbySelectors.activeGameType(state.lobby))
  )
  
  // isMobileLandscape: boolean = false
  // isMobileLandscape$ = this.store.pipe(
  //   select(state => isMobileLandscapeMode(state.displayMode))
  // ) 
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    // this.subscribeIsMobileLandscape()
    this.subscribeInvitation()
    this.subscribeActiveGameType()
  }

  startTheGameSignal: boolean = false
  inputEvent(event$: any) {
    // console.log(event$);
    this.startTheGameSignal = true    

    //reset signal 
    setTimeout(() => {
      this.startTheGameSignal = false
    }, 100)
  }

  subscribeActiveGameType() {
    this.activeGameType$.subscribe(value => {
      this.activeGameType = value
    })
  }
  subscribeInvitation() {
    this.invitation$.subscribe(value => {
      // console.log(value);
      this.invitation = value
    })
  }

  calculateClass(lobby: Lobby): string {
    
    if (!lobby.buyInType && lobby.buyInType !== 0)
      return "disabled";

    return "";
  }

  acceptInvitation() {
    this.store.dispatch(LobbyActions.acceptInvitation());
  }

  rejectInvitation() {
    this.store.dispatch(LobbyActions.rejectInvitation());
  }

}

