import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { Observable, of, timer } from "rxjs";
import { concatMapTo, map, mapTo, mergeMap, switchMap, tap } from "rxjs/operators";
import { PlayedCard } from "src/app/models/game.model";
import { CardPositionService } from "src/app/services/card-position.service";
import * as GameActions from '../actions/game.actions'
import { AppState } from "../app.state";
import { playerTurn, selectPlayerAction, selectPlayerOnTurn, selecttableCards } from "../selectors/game.selector";

@Injectable()
export class GameEffects {

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private cardPositionService: CardPositionService
    ){}
    
    shuffleCards$ = createEffect(() => this.actions$.pipe(
        ofType(GameActions.shuffleCards),
        // tap(action => console.log(action)),
        map(() => {
            let deckCards!: PlayedCard[];
            
            this.store.pipe(
                select(state => selecttableCards(state.game))
            ).subscribe(value => {
                deckCards = value
            })

            // console.log(deckCards);
            let shuffledcards: PlayedCard[] = []

            for (var index = 0; index < deckCards.length; index++) {
                // console.log(index);
                // console.log(deckCards.length);

                let newPosition = this.cardPositionService.generateDeckPosition()
                var card = deckCards[index];
                
                // console.log(card);
                // console.log("new position");
                // console.log(newPosition);
                
                let newCard: PlayedCard = {
                    ...card,
                    deckPosition: newPosition
                }
                // console.log("card position set");
                
               
                shuffledcards.push(newCard)
                // shuffledcards.push(card)                
                // console.log(shuffledcards);                
            }
            
            // console.log("index: " + index);
            
            return GameActions.shuffledCards({shuffledcards: shuffledcards})
        }),
        
    ), 
    // { dispatch: false }
    );

    
    // turnTimerDone$ = createEffect(() => this.actions$.pipe(
    //     ofType(GameActions.turnTimerDone),
    //     map((player) => {
    //         return GameActions.timeBankTimerStart()
    //     }),
    // ))
    
    switchTurn$ = createEffect(
        () => this.actions$.pipe(
            ofType(GameActions.switchTurn),
            concatLatestFrom((playerTurn) => this.store.select(state => selectPlayerOnTurn(state.game))),
            tap(([playerTurn, fromPlayerTurn]) => {
                // console.log("effect switch turn");
                // //TODO: turn down all timers except the active player                
                // console.log(playerTurn);
                // console.log(fromPlayerTurn);
                // this.store.dispatch(GameActions.turnOffAllTimers({player: fromPlayerTurn}))
                // // this.store.dispatch(GameActions.turnTimerDone({player: fromPlayerTurn}))
                // this.store.dispatch(GameActions.switchedTurn({playerTurn: playerTurn.playerTurn}))
                
            }),
            map(([toPlayer,fromPlayer]) => {
                if (toPlayer.playerTurn === fromPlayer) {
                    // console.log("second call");
                    return
                }
                this.store.dispatch(GameActions.playerAction({player: fromPlayer}))
                this.store.dispatch(GameActions.turnOffAllTimers({player: fromPlayer}))
                this.store.dispatch(GameActions.switchedTurn({playerTurn: toPlayer.playerTurn}))
            })
        ),
        { dispatch: false}
    );
    
    turnTimerDone$ = createEffect(() => this.actions$.pipe(
        ofType(GameActions.turnTimerDone),
        concatLatestFrom((player) => this.store.select(state => selectPlayerAction(state.game, player.player))),        
        tap(([player, playerActions]) => {
            // console.log('player stop time turn timer: ' + player.player);
            // console.log(playerActions);
            
            if (playerActions === true) {
                return
            }
            // console.log("start time bank ");
            
            this.store.dispatch(GameActions.timeBankTimerStart({player: player.player}))
        })
    ), { dispatch: false})


    // startTimeBank$ = createEffect(() => this.actions$.pipe(
    //     ofType(GameActions.timeBankTimerStart),
    //     tap((player) => {
    //         console.log("player start time bank timer: " + player.player);
    //         //TODO: stop service turn timer and start time bank timer
    //     })
    // ), {dispatch: false})

    // addBookToCollectionSuccess$ = createEffect(
    //     () =>
    //       this.actions$.pipe(
    //         ofType(CollectionApiActions.addBookSuccess),
    //         concatLatestFrom(action => this.store.select(fromBooks.getCollectionBookIds)),
    //         tap(([action, bookCollection]) => {
    //           if (bookCollection.length === 1) {
    //             window.alert('Congrats on adding your first book!');
    //           } else {
    //             window.alert('You have added book number ' + bookCollection.length);
    //           }
    //         })
    //       ),
    //     { dispatch: false }
    //   );
     
    

}
