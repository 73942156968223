import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { timeStamp } from 'console';
import { Card, CardSuit, GameStatus, GlowingStates, PlayerMove } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { setDeckPosition, setDeckSize } from 'src/app/state/actions/display-mode.actions';
import { drawCards, drawOpponentCards } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectCutterCard, selectGameStatus, selectGlowingState, selectPlayerMoves } from 'src/app/state/selectors/game.selector';
import { CardComponent } from '../../shared/card/card.component';
import { slideUnderDeck } from './animations/slide_under_deck.animation';

@Component({
  selector: 'app-table-control-right',
  templateUrl: './table-control-right.component.html',
  styleUrls: ['./table-control-right.component.scss'],
  animations: [
    trigger(
      'deck_animations', [
        transition('* => slide_under_deck', [
          useAnimation(slideUnderDeck)
        ], {params: {}})
      ]
    )
  ]
})
export class TableControlRightComponent implements OnInit {

  @ViewChild('deck',{static:false, read: ElementRef}) deck!: ElementRef;
  // @ViewChild('deck') deck!: ElementRef;
  @Input() replay: boolean = false


  speed = 1.5
  // bottom_card: Card = { number: 1, suit: CardSuit.clover }
  back_card: Card = { number: 0, suit: CardSuit.back }
  
  animationState!: string

  cutterCard: Card | null = null
  cutterCard$ = this.store.pipe(
    select(state => selectCutterCard(state.game))
  )

  playerMoves: PlayerMove[] = []
  playerMoves$ = this.store.pipe(
    select(state => selectPlayerMoves(state.game))
  )


  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )

  glowingStates: GlowingStates = GlowingStates.none
  glowingStates$ = this.store.pipe(
    select(state => selectGlowingState(state.game))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )
  constructor(
    private store:Store<AppState>,
    private displayModeServices: DisplayModeService) { 
  }

  ngOnInit(): void {    
    this.subscribeAppWrapperSize()

    this.subscribePayerMoves()
    this.subscribeCutterCard()
    this.subscribeGameStatus()
    this.subscribeGlowingState()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }

  subscribeGlowingState() {
    this.glowingStates$.subscribe(value => {
      this.glowingStates = value
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value

      if (this.gameStatus === GameStatus.match_started_cutter) {
        this.animationState = 'slide_under_deck'
      }
    })
  }

  subscribePayerMoves() {
    this.playerMoves$.subscribe(value => {
      this.playerMoves = value
    })
  }

  subscribeCutterCard() {
    this.cutterCard$.subscribe(value => {
      this.cutterCard = value
    })
  }
  
  deckRefPosition!: {top: number, left: number}
  ngAfterViewInit() {
    // console.log('DECK AFTER VIEW INIT');
    let deckRect = this.deck?.nativeElement?.getBoundingClientRect()
    this.deckRefPosition = {top: deckRect?.top, left: deckRect?.left}
    this.setDeckPosition()
  }
  ngAfterViewChecked() {
    // console.log('DECK AFTER VIEW CHECKED');
    let deckRect = this.deck?.nativeElement?.getBoundingClientRect()
    this.deckRefPosition = {top: deckRect?.top, left: deckRect?.left}
    this.setDeckPosition()

  }

  setDeckPosition() {
    this.store.dispatch(setDeckPosition(this.deckRefPosition)) 
  }


  drawCards() {

    //can't draw if the game not started 

    // if (this.gameStatus !== GameStatus.running_game) {
    //   return 
    // }
    
    let card1: Card = {
      number: 1,
      suit: CardSuit.clover
    }
    
    //sent signal only if drawing is available move 
    // if (this.playerMoves.length !== 0) {
    //   return 
    // }
    // setInterval(() => {
      // this.store.dispatch(drawCards({ cards: [ card1 ]}))  
    // }, 3000);
    this.store.dispatch(drawCards({ cards: [ card1 ]}))
    // this.store.dispatch(drawCards({ cards: [ card1,  card1 ]}))
    this.store.dispatch(drawOpponentCards({numberOfCards: 1}))
    // this.store.dispatch(drawOpponentCards({numberOfCards: 2}))
  
  }

  canRenderCutterCard() {
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    return true
  }
  canRenderDeckCard() {
    // if (this.gameStatus === GameStatus.game_closed_by_give_up) {
    //   return false
    // }
    return true
  }

  handleDeckAnimation() {

    

    return {
      value: this.animationState,
      params: {

      }
    }
  }
  deckAnimationDone($event: any) {
    //TODO: if cutter is under deck - switch game status to next step (match started table info)
  }

  canRenderGlowingDeck() {
    if (this.glowingStates === GlowingStates.glow_deck
      || this.glowingStates === GlowingStates.glow_all) 
    {
      return true
    }
    return false
  }

  rightControlsSize() {

    // let width = Math.max(this.appWrapperSize.width / 5.6, 93)
    // let height = Math.max(width * 0.8, 74)

    let width = 93 * this.displayModeServices.expanded
    let height = 74 * this.displayModeServices.expanded

    let defaultWidth = 249
    let widthTable = (this.displayModeServices.expanded * defaultWidth)
    let empty = (this.appWrapperSize.width * -0.48 + widthTable) / 2

    let left = empty + (this.displayModeServices.expanded * 20)
    let top = (this.displayModeServices.expanded * 84)
    return {
      width: width + 'px',
      height: height + 'px',
      // left: left + 'px',
      // top: top + 'px'
    }
  }

  deckPosition() {

    let defaultWidth = 249
    let width = (this.displayModeServices.expanded * defaultWidth)
    
    if (this.displayModeServices.rotation == 1) {
      let empty = (this.appWrapperSize.height * -0.48 + width) / 2
      let left = empty
      return {
        left: left + 'px'
      }
    }
    
    
    let empty = ((this.appWrapperSize.width * -0.48 + width) / 2)
    let left = empty
    return {
      left: left + 'px'
    }
  }

}
