import { Action, createReducer, on } from "@ngrx/store";
import { FilerDateType, FilterGameResult, User, UserStatisticsFilterError } from "src/app/models/user.model";
import * as UserActions from "src/app/state/actions/user.actions"

export const initialUserState: User = {
    playerId: '',
    name: 'user name 123',
    balance: 150000,
    settings: {
      autoSwitchTurn: true,
      displayCardsSum: false,
      displayTodaysBalance: true,
      displayUnplayableCards: false,
      playCardByDoubleClick: false,
      soundOn: true
    },
    winLostRate: {
      win: 0,
      lost: 0
    },
    myGamesFilter: {
        gamesResult: FilterGameResult.All,
        buyIns: [],
        date: FilerDateType.ALl
    },
    statisticsFilter: {
        opponent: '',
        date: FilerDateType.ALl  
    },
    statisticsFilterError: UserStatisticsFilterError.incorrectOpponentName
}

export const createdUserReducer = createReducer(
    initialUserState,
    on ( UserActions.changeStatisticsFilterDate,
        (state, { date }) => {
            let statisticsFilter = {...state.statisticsFilter}
            statisticsFilter.date = date
            return {
                ...state,
                statisticsFilter: statisticsFilter
            }
        }
    ),
    on ( UserActions.changeStatisticsFilterOpponent,
        (state, { opponent }) => {
            let statisticsFilter = {...state.statisticsFilter}
            statisticsFilter.opponent = opponent
            return {
                ...state,
                statisticsFilter: statisticsFilter
            }
        }
    ),
    on( UserActions.changeMyGamesFilterResults, 
        (state, { values }) => {    
            let myGamesFilter = {...state.myGamesFilter}
            myGamesFilter.gamesResult = values
            return {
                ...state,
                myGamesFilter: myGamesFilter
            }
        }
    ),
    on( UserActions.changeMyGamesFilterBuyIn, 
        (state, { values }) => {    
            let myGamesFilter = {...state.myGamesFilter}
            myGamesFilter.buyIns = values
            return {
                ...state,
                myGamesFilter: myGamesFilter
            }
        }
    ),
    on( UserActions.changeMyGamesFilterDate, 
        (state, { values }) => {    
            let myGamesFilter = {...state.myGamesFilter}
            myGamesFilter.date = values
            return {
                ...state,
                myGamesFilter: myGamesFilter
            }
        }
    ),
    on(
        UserActions.getUser,
        (state, { user }) => {
            return user;
        }
    ),
    // on(
    //     UserActions.getUserDone,
    //     (_, user) => {
    //         return {
    //             ..._,
    //             user
    //         }
    //     }
    // ),
    on( UserActions.changeSettings,
        (state, { key }) => {
            
            const userSettings = {...state.settings}
            // console.log(userSettings);
            
            userSettings[key] = !state.settings[key]
            // console.log(userSettings);
            return {
                ...state,
                settings: userSettings
            }
            return state

        }
    ),
);

export function userReducer(state: User | undefined, action: Action) : User {
    return createdUserReducer(state, action);
}