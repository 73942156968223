import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { timeStamp } from 'node:console';
import { threadId } from 'node:worker_threads';
import { timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';
import { Card, CardSuit, GameStatus, GlowingStates, PlayedCard, PlayerMove, Position } from 'src/app/models/game.model';
import { CardPositionService } from 'src/app/services/card-position.service';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { opponentPlayedCard, playerPlayedCard, playerPlayesCard } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize, selectDeckPosition, selectTablePosition } from 'src/app/state/selectors/display-mode.selector';
import { selectGameStatus, selectGlowingState, selectNumberOfOpponentCards, selectOpponentPlaysCard, selectPlayerCards, selectPlayerMoves, selectStartDealCardForPlayer } from 'src/app/state/selectors/game.selector';
import { dealCards } from './animations/deal_cards.animation';
import { dealCards2 } from './animations/deal_cards_2.animation';
import { dealDrawCard } from './animations/deal_draw_card.animation';
import { drawCard } from './animations/draw_card.animation';
import { draw2Cards } from './animations/draw_cards.animation';
import { playerPlayCard } from './animations/player_play_card.animation';

@Component({
  selector: 'app-player-cards',
  templateUrl: './player-cards.component.html',
  styleUrls: ['./player-cards.component.scss'],
  animations: [
    trigger(
      'player_animations',[
        transition('* => player_plays_card', [
          useAnimation(playerPlayCard)
        ], {params: { from_x: '0', to_x: '0', from_rotation: '0', to_rotation: '0', from_y: '0', from_y_2: '0', to_y: '0'}}),    
        transition('* => draw_card_2', [
          useAnimation(draw2Cards),        
        ], {params: { from_x_pos: 0, from_x_pos_2: 0, from_y_pos: 0, from_y_pos_2: 0, factor_x: 0}}),
        transition('* => draw_card', [
          useAnimation(drawCard)
        ], {params: { from_x_pos: 0, from_x_pos_2: 0, from_y_pos: 0, from_y_pos_2: 0, factor_x: 0}}),      
        transition('* => deal_draw_card', [
          useAnimation(dealDrawCard)
        ], {params: {delay: '0ms', time: '1000ms', from_x_pos: 0, from_x_pos_2: 0, from_y_pos: 0, from_y_pos_2: 0, factor_x: 0}}),      
      
        // transition('* => deal_cards', [
        //   useAnimation(dealCards),
        // ], {params: {from_top: 0,from_top_2: 0, from_left: 0, from_left_2: 0, from_left_3: 0, from_left_4: 0, from_left_5: 0, from_left_6: 0, from_left_7: 0}}),
        // transition('* => deal_cards_2', [
        //   useAnimation(dealCards2)
        // ], {params: {from_top: 0,from_top_2: 0, from_left: 0, from_left_2: 0, from_left_3: 0, from_left_4: 0, from_left_5: 0, from_left_6: 0, from_left_7: 0}}),
      ]
    ),
    
  ]
})
export class PlayerCardsComponent implements OnInit {

  // @Input() opponent!: boolean
  @Input() player!: number
  @Input() replay: boolean = false
  @ViewChild('player_cards_ref', {static:false, read: ElementRef}) player_cards_ref!: ElementRef;

  card_back_img = "assets/images/game/card_back.svg"
  playerAnimationState!: any 

  playerCards: Card[] = []
  playerCards$ = this.store.pipe(
    select(state => selectPlayerCards(state.game)
  )
  )

  deckPosition!: any
  deckposition$ = this.store.pipe(
    select(state => selectDeckPosition(state.displayMode))
  )
  tablePosition!: any
  tablePosition$ = this.store.pipe(
    select(state => selectTablePosition(state.displayMode))
  )
  
  playerMoves: PlayerMove[] | null = null
  playerMoves$ = this.store.pipe(
    select(state => selectPlayerMoves(state.game))
  )

  stackFactorAddition = 4
  stackFactor = this.cardPositionService.WIDTH + this.stackFactorAddition
  widthFactor = this.cardPositionService.WIDTH
  heightFactor = this.cardPositionService.HEIGHT
  
  back_card: Card = {
    number: 0, suit: CardSuit.back
  }

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  glowingStates: GlowingStates = GlowingStates.none
  glowingStates$ = this.store.pipe(
    select(state => selectGlowingState(state.game))
  )

  selectStartDealCardForPlayer!: number
  selectStartDealCardForPlayer$ = this.store.pipe(
    select(state => selectStartDealCardForPlayer(state.game))
  )

  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  constructor(
    private store:Store<AppState>, 
    private cardPositionService: CardPositionService,
    private displayModeServices: DisplayModeService) { 
  }

  //0 for landscape, 1 for portrait
  screenRotation: number = 0
  renderPlayerCards: boolean = false //this.canRenderPlayerCards()
  ngOnInit(): void {
    this.subscribeAppWrapperSize()

    //this.generateCards();
    this.setScreenRotation()
    this.subscribePlayerMoves()
    this.subscribeGameStatus()
    this.subscribeSelectStartDealCardForPlayer()
    this.renderPlayerCards = this.canRenderPlayerCards()
    this.subscribePlayerCards()
    // this.subscribeOpponentCards()
    this.subscribeDeckPosition()
    this.subscribeTablePosition()
    this.subscribeGlowingState()
    
  }


  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }

  playerCardsRect!: {top: number, left: number}
  playerCardsLastRect!: {top: number, left: number}

  ngAfterViewInit() { 
    // console.log('AFTER VIEW INIT');
    
    this.setScreenRotation()
    this.calculateStackFactor() 
    
    let cardsRect = this.player_cards_ref.nativeElement.getBoundingClientRect();    
    this.playerCardsRect = {top: cardsRect.top, left: cardsRect.left}

    let cardsLastRect = this.player_cards_ref.nativeElement.lastElementChild?.getBoundingClientRect()
    this.playerCardsLastRect = {top: cardsLastRect?.top, left: cardsLastRect?.left}
    
    if (this.player_cards_ref.nativeElement.lastElementChild === null) {      
      this.playerCardsLastRect = {top: this.playerCardsRect.top , left: this.playerCardsRect.left - this.widthFactor}    
    }    
  }

  ngAfterViewChecked() {
    
    // console.log('AFTER VIEW CHCKED');
    
    this.setScreenRotation()
    this.calculateStackFactor()
    
    let cardsRect = this.player_cards_ref.nativeElement.getBoundingClientRect();
    this.playerCardsRect = {top: cardsRect.top, left: cardsRect.left}
 
    let cardsLastRect = this.player_cards_ref.nativeElement.lastElementChild?.getBoundingClientRect()
    this.playerCardsLastRect = {top: cardsLastRect?.top, left: cardsLastRect?.left}
  
    // console.log(this.playerCardsRect);
    
    if (this.player_cards_ref.nativeElement.lastElementChild === null) {
      this.playerCardsLastRect = {top: this.playerCardsRect.top , left: this.playerCardsRect.left - this.widthFactor}
    }

  }

  subscribeSelectStartDealCardForPlayer() {
    this.selectStartDealCardForPlayer$.subscribe(value => {
      this.selectStartDealCardForPlayer = value
    })
  }

  setScreenRotation() {
    if (this.appWrapperSize.width > this.appWrapperSize.height || this.appWrapperSize.width > 720) {
      this.screenRotation = 0
      return 
    } 
    this.screenRotation = 1
  }

  subscribeGlowingState() {
    this.glowingStates$.subscribe(value => {
      this.glowingStates = value
    })
  }
  subscribeDeckPosition() {
    this.deckposition$.subscribe(value => {
      this.deckPosition = value
      // console.log('DESK POSITION');
      // console.log(value);
    })
  }
  subscribeTablePosition() {
    this.tablePosition$.subscribe(value => {
      this.tablePosition = value
      console.log('TABLE POSITION');
      console.log(value);
    })
  }

  
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
      this.chooseDealCardsAnimation()
    })
  }
  subscribePlayerMoves() {
    this.playerMoves$.subscribe(value => {
      this.playerMoves = value  
    })
  }
  
  drawCardAnimationLandscape(time: string, delay: string) {
    
    
    let from_y_pos = this.playerCardsLastRect.top - this.deckPosition.top
    let from_y_pos_2 = from_y_pos - this.heightFactor;    
    let from_x_pos = this.deckPosition.left - this.playerCardsLastRect.left - (this.stackFactor)
    
    
    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.playerCards.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.playerCards.length - 1) 
      let fr = (fr1 - fr2)/2
      from_x_pos += fr

    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('FACTOR SHIFTED');
      from_x_pos += this.stackFactor / 2
    }

    return {
      value: this.playerAnimationState,
      params: {
        time: time,
        delay: delay,
        factor_x: this.stackFactor/2 + "px",
        from_y_pos: from_y_pos + "px",
        from_y_pos_2: from_y_pos_2 + "px",
        from_x_pos: from_x_pos + "px"
      }
    }
  }
  drawCardAnimationPortrait(time: string, delay: string) {
    
    let from_y_pos = this.playerCardsLastRect.left - this.deckPosition.left 
    let from_y_pos_2 = from_y_pos - this.heightFactor;    
    let from_x_pos =  this.playerCardsLastRect.top - this.deckPosition.top - this.stackFactor
    
    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.playerCards.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.playerCards.length - 1) 
      let fr = (fr1 - fr2)/2
      from_x_pos += fr

    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('FACTOR SHIFTED');
      from_x_pos += this.stackFactor / 2
    }

    return {
      value: this.playerAnimationState,
      params: {
        time: time,
        delay: delay,
        factor_x: this.stackFactor/2 + "px",
        from_y_pos: from_y_pos + "px",
        from_y_pos_2: from_y_pos_2 + "px",
        from_x_pos: from_x_pos + "px"
      }
    }
  }
  playerDrawCardAnimation(time: string, delay: string) {
    // console.log('DRAW CARD ANIMATION');

    if (this.screenRotation === 1) {
      return this.drawCardAnimationPortrait(time, delay);
    }
    return this.drawCardAnimationLandscape(time, delay);

  }

  draw2CardsAnimationLandscape() {  
    let from_x_pos = this.deckPosition.left - this.playerCardsLastRect.left - this.stackFactor
    let from_y_pos = this.playerCardsLastRect.top - this.deckPosition.top 
    let from_y_pos_2 = from_y_pos - this.heightFactor

    // let factor_width = (0.1217 * window.innerWidth)

    if (this.stackFactorStartBreaking ) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.playerCards.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.playerCards.length - 1) 
      let fr = (fr1 - fr2)/2
      if (this.playerCards.length % 2 == 0) {
        from_x_pos += fr
      } else {        
        from_x_pos += fr - (this.oldStackFactor - this.stackFactor - 1)
      }

    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('FACTOR SHIFTED');
      from_x_pos += this.stackFactor 
    }

    let from_x_pos_2 = from_x_pos - this.stackFactor
    return {
      value: 'draw_card_2', 
      params: {
        from_x_pos: from_x_pos + "px",
        from_x_pos_2: from_x_pos_2 + "px",
        factor_x: this.stackFactor + "px",
        from_y_pos: from_y_pos + "px",
        from_y_pos_2: from_y_pos_2 + "px"
      }
    }
  }
  draw2CardsAnimationPortrait() {
    
    let from_x_pos = this.playerCardsLastRect.top - this.deckPosition.top - (this.stackFactor)
    let from_y_pos = this.playerCardsLastRect.left - this.deckPosition.left
    let from_y_pos_2 = from_y_pos - this.heightFactor

    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.playerCards.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.playerCards.length - 1) 
      let fr = (fr1 - fr2)/2
      if (this.playerCards.length % 2 == 0) {
        from_x_pos += fr
      } else {
        from_x_pos += fr - (this.oldStackFactor - this.stackFactor - 1)
      }
    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('FACTOR SHIFTED');
      from_x_pos += this.stackFactor 
    }

    let from_x_pos_2 = from_x_pos - this.stackFactor
    
    return {
      value: 'draw_card_2', 
      params: {
        from_x_pos: from_x_pos + "px",
        from_x_pos_2: from_x_pos_2 + "px",
        factor_x: this.stackFactor + "px",
        from_y_pos: from_y_pos + "px",
        from_y_pos_2: from_y_pos_2 + "px"
      }
    }
  }
  playerDraw2CardsAnimation() {
    
    if (this.screenRotation === 1) {
      return this.draw2CardsAnimationPortrait()
    }
    return this.draw2CardsAnimationLandscape()
  
  }


  playCardAnimationLandscape() {
    
    let from_x = 0;
    let to_x = this.tablePosition.left - this.playerPlayedCard.handPosition.left + this.playerPlayedCard.deckPosition.left
    let from_y = 0;
    let from_y_2 = -this.heightFactor / 2;
    let to_y = this.tablePosition.top - this.playerPlayedCard.handPosition.top + this.playerPlayedCard.deckPosition.top
    let from_rotation = 0
    let to_rotation = this.playerPlayedCard.deckPosition.rotation


    return {
      value: 'player_plays_card',
      params: {
        from_x: from_x + "px",
        to_x: to_x + "px",
        from_y: from_y + "px",
        from_y_2: from_y_2 + "px",
        to_y: to_y + "px",
        from_rotation: from_rotation,
        to_rotation: to_rotation
      }
    }
  }
  playsCardAnimationPortrait() {
    
    let from_x = 0;
    let from_y = 0;
    let from_y_2 = -this.heightFactor / 2;
    let from_rotation = 0
    
    let to_x = this.playerPlayedCard.handPosition.top - this.tablePosition.top - this.tablePosition.height + this.playerPlayedCard.deckPosition.left + this.cardPositionService.WIDTH
    let to_y = this.tablePosition.left - this.playerPlayedCard.handPosition.left + this.playerPlayedCard.deckPosition.top   
    
    let to_rotation = this.playerPlayedCard.deckPosition.rotation
    
    
    return {
      value: 'player_plays_card',
      params: {
        from_x: from_x + "px",
        to_x: to_x + "px",
        from_y: from_y + "px",
        from_y_2: from_y_2 + "px",
        to_y: to_y + "px",
        from_rotation: from_rotation,
        to_rotation: to_rotation
      }
    }
  }
  playerPlaysCardAnimation() {
    // from_x: '0', to_x: '0', from_rotation: '0', to_rotation: '0', from_y: '0', from_y_2: '0', to_y: '0'

    if (this.screenRotation === 1) {
      return this.playsCardAnimationPortrait();
    }

    return this.playCardAnimationLandscape();

  }

  playerDealCardsAnimationLandscape(){
   // console.log(this.playerCardsRect);
   let from_top = this.deckPosition.top - this.playerCardsRect.top
   let from_top_2 = -this.heightFactor/2
   let from_left = this.deckPosition.left - this.playerCardsRect.left

   // console.log(value);
   
   return {
     value: this.playerAnimationState,
     params: {
       from_top: from_top,
       from_top_2: from_top_2,
       from_left: from_left,
       from_left_2: from_left - (this.stackFactor),
       from_left_3: from_left - (this.stackFactor * 2),
       from_left_4: from_left - (this.stackFactor * 3),
       from_left_5: from_left - (this.stackFactor * 4),
       from_left_6: from_left - (this.stackFactor * 5),
       from_left_7: from_left - (this.stackFactor * 6)
     }
   }
  }
  playerDealCardsAnimationPortrait(){

    let from_top = this.deckPosition.left - this.playerCardsRect.left
    let from_top_2 = -this.heightFactor/2
    let width = this.playerCards.length * this.stackFactor 
    let from_left = width + this.stackFactor - 2*this.widthFactor    
    
    // console.log(this.playerCards.lengt * this.stackFactor );
     
    return {
      value: this.playerAnimationState,
      params: {
        from_top: from_top,
        from_top_2: from_top_2,
        from_left: from_left,
        from_left_2: from_left - (this.stackFactor),
        from_left_3: from_left - (this.stackFactor * 2),
        from_left_4: from_left - (this.stackFactor * 3),
        from_left_5: from_left - (this.stackFactor * 4),
        from_left_6: from_left - (this.stackFactor * 5),
        from_left_7: from_left - (this.stackFactor * 6)
      }
    }
  }
  playerDealCardsAnimation() {
    // console.log('DEAL PLAYER CARDS ANIMATION');
    // console.log(this.playerAnimationState);
    if (this.screenRotation === 1) {
      return this.playerDealCardsAnimationPortrait()
    }
    return this.playerDealCardsAnimationLandscape()
 
  }

  playerAnimationStart($event: any) {
    // console.log("PLAYER Animation Start:" , $event.fromState, $event.toState);
  }
  playerAnimationDone($event:any ) {
    // console.log("PLAYER Animation Done:" , $event.fromState, $event.toState);
 
    if ($event.toState === 'player_plays_card') {
      this.store.dispatch(playerPlayesCard({playedCard: this.playerPlayedCard}))
    }


    // this.store.dispatch(playerPlayesCard({ playedCard: this.playerPlayedCard }))   

    // if (this.playerAnimationState != $event.toState) {
    //   return 
    // }
    
    this.runningPlayerAnimation = false
    this.playerAnimationState = '*'


    if ($event.toState === '*' && this.dealAnimationQueue.length > 0) {
      this.chooseDealCardsAnimation()
    }
  }

  dealAnimationQueue: number[] = [0,1,2,3,4,5,6]
  dealAnimationDelay: string = '0ms'
  chooseDealCardsAnimation() {
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      
      if (this.selectStartDealCardForPlayer === 1) {
        this.dealAnimationDelay = '100ms'
      }

      if (!this.playerCardsCopy) {
        return
      }
      var index = this.dealAnimationQueue?.shift()
      // console.log(index);
      
      if (index === undefined) {
        return 
      }

      this.playerCards.push(this.playerCardsCopy[index])
      this.playerAnimationState = 'deal_draw_card'    

    }
  }
  runningPlayerAnimation:boolean = false
  handlePlayerAnimation() {
    // console.log('HANDLE PLAYER ANIMATION');
    
    if (this.playerCardsRect === undefined) {
      return {value: '*'}
    }

    if (this.runningPlayerAnimation) {
      return {value: this.playerAnimationState}
    }
    
    switch (this.playerAnimationState) {
      case 'draw_card': {
        this.runningPlayerAnimation = true
        return this.playerDrawCardAnimation('2s', '0s')
      }
      case 'deal_draw_card': {
        this.runningPlayerAnimation = true
        return this.playerDrawCardAnimation('300ms', this.dealAnimationDelay)
      }
      case 'draw_card_2': {
        this.runningPlayerAnimation = true
        return this.playerDraw2CardsAnimation()
      }
      case 'player_plays_card': {
        this.runningPlayerAnimation = true
        return this.playerPlaysCardAnimation() 
      }  
      case 'deal_cards': {
        this.runningPlayerAnimation = true
        return this.playerDealCardsAnimation() 
      }
      case 'deal_cards_2': {
        this.runningPlayerAnimation = true
        return this.playerDealCardsAnimation() 
      }
    }

    return {value: this.playerAnimationState}
    
  }  
  
  playerCardsCopy!: Card[]
  subscribePlayerCards() {
    this.playerCards$.subscribe(value => {
      let animation = '*'
      
      if (this.playerCards) {
        if (value.length - this.playerCards.length == 2 ) {
          animation = 'draw_card_2'
        }

        if (value.length - this.playerCards.length == 1 ) {    
          animation = 'draw_card'
        }
      }
      
      if (!this.renderPlayerCards) {
        this.playerCardsCopy = value
      }
      else {
        this.playerCards = value
      }
      
    
      this.playerAnimationState = animation
      this.calculateStackFactor();        
    })

    
  }

  stackFactorStartBreaking: boolean = false
  oldStackFactor: number = this.stackFactor

  calculateStackFactor(): number {

    this.stackFactorAddition = (4 * this.displayModeServices.expanded)
    

    this.stackFactorStartBreaking = false

    let availableWidth = this.appWrapperSize.width - 44 - this.widthFactor
    if (this.screenRotation == 1) {
      availableWidth = this.appWrapperSize.height - 44 - this.widthFactor
    }
    let factor = availableWidth / (this.playerCards.length - 1)
    this.oldStackFactor = this.stackFactor

    if (this.stackFactor === this.cardPositionService.WIDTH + this.stackFactorAddition && factor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      this.stackFactorStartBreaking = true
    }

    if (factor >= this.cardPositionService.WIDTH + this.stackFactorAddition) {
      this.stackFactor = this.cardPositionService.WIDTH + this.stackFactorAddition
    }
    else {
      this.stackFactor = factor
    }

    return this.stackFactor
  }
  calculateStyle(last: boolean) { 
    if (last) {
      return {
        width: this.widthFactor + "px"
      }
    }
    
    return {
      width: this.stackFactor + "px"
    }
  }
  calculateCardClass(card: Card) {
    return  card.suit.toString() + "-" + card.number.toString();
  }
  
  playerPlayedCard!: PlayedCard
  playerPlaysCard(card: Card, handOrder: number) {
    
    //can't play if the game not started 
    // if (this.gameStatus !== GameStatus.running_game) {
    //   return 
    // }

    //can't play two cards in one turn, except 8 and J
    // if (this.playerMoves !== null && this.playerMoves.length !== 0 && 
    //   (!this.playerMoves.includes(PlayerMove.draw_card) && !this.playerMoves.includes(PlayerMove.play_card_stop))) {
    //   return 
    // }

    let position = this.cardPositionService.generateDeckPosition();
    position = {
      left: position.left,
      top: position.top,
      rotation: position.rotation
    }
    let cardBounding = this.player_cards_ref.nativeElement.children[handOrder].getBoundingClientRect();
    this.playerAnimationState = 'player_plays_card'
    // console.log(position);
    this.playerPlayedCard = {
      number: card.number,
      suit: card.suit,
      handOrder: handOrder,
      handPosition: {
        top: cardBounding.top,
        left: cardBounding.left,
        rotation: 0
      },
      deckPosition: position,
      player: 0
    }

    
  }  
  isPlayed(cardOrder: number) {
    // if(this.opponent === true) {
    //   return (cardOrder === this.opponentAnimationStartCard) ? true : false
    // }
    
    if (!this.playerPlayedCard) {
      return false
    }
    
    

    return this.playerPlayedCard.handOrder == cardOrder
    // return false
  }

  canRenderGlowingCards() {
    if (this.glowingStates === GlowingStates.glow_hand
      || this.glowingStates === GlowingStates.glow_all) 
    {
      return true
    }
    return false
  }
  
  canRenderPlayerCards() {
    // waiting_for_opponent
    // waiting_for_invited_opponent
    // waiting_for_match_start
    // match_started_select_turn
    if (this.gameStatus === GameStatus.waiting_for_opponent || 
      this.gameStatus === GameStatus.waiting_for_invited_opponent ||
      this.gameStatus === GameStatus.waiting_for_match_start ||
      this.gameStatus === GameStatus.match_started_select_turn || 
      this.gameStatus === GameStatus.match_started_deal_cards) {
        return false
      }
    return true
  }
}
