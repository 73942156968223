import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';
import { AppState } from 'src/app/state/app.state';
import { selectOpponentName, selectRematchStatus } from 'src/app/state/selectors/game.selector';
import { selectUserName } from 'src/app/state/selectors/user.selector';

@Component({
  selector: 'app-rematch-modal',
  templateUrl: './rematch-modal.component.html',
  styleUrls: ['./rematch-modal.component.scss']
})
export class RematchModalComponent implements OnInit {

  opponentName: string = ""
  opponentName$ = this.store.pipe(
    select(state => selectOpponentName(state.game))
  )

  userName: string = ""
  userName$= this.store.pipe(
    select(state => selectUserName(state.user))  
  )

  //0 for processing, 1 for accepted
  opponentRematchStatus: number = 0
  userRematchstatus: number = 0
  
  rematchStatus$ = this.store.pipe(
    select(state => selectRematchStatus(state.game))
  )

  countdown$: any 

  

  constructor(private store:Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeOpponentName()
    this.subscribeUserName()
    this.subscribeRematchStatus()
    this.countdown()
  }

  countdown() {
    this.countdown$ = timer(0, 1000).pipe(
      scan(acc => --acc, 31),
      takeWhile(time => time >= 0)
    )
  }

  subscribeRematchStatus() {
    this.rematchStatus$.subscribe(value => {
      this.opponentRematchStatus = value.opponentStatus
      this.userRematchstatus = value.playerStatus
    })
  }

  subscribeOpponentName(){
   this.opponentName$.subscribe(value => {
     this.opponentName = value
   })
  }
  subscribeUserName(){
    this.userName$.subscribe(value => {
      this.userName = value
    })
  }

  cropName(name: string) {
    
    if (name.length > 11) {
      return  name.slice(0,10) + "..."
    }

    return name
  }
  getRematchstatus(index: number) {
    if (index === 0) {
      return "Processing…"
    }

    return "Accepted"
  }
  getRematchStatusClass(index: number) {
    if (index === 0) {
      return "processing"
    }

    return "accepted"
  }

}
