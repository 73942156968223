import { createSelector } from "@ngrx/store";
import { User } from "src/app/models/user.model";

export const userSettings = (state: User) => state.settings
export const myGamesFilter = (state: User) => state.myGamesFilter
const userName = (state: User) => state.name
const userBalance = (state: User) => state.balance

const statisticsFilterError = (state: User) => state.statisticsFilterError

export const selectUserSettings = createSelector(
    userSettings,
    (settings) => {
        // console.log(userSettings);
        return settings
    }
)
export const selectMyTodayBalance = createSelector(
    userSettings,
    (userSettings) => {
        return userSettings.displayTodaysBalance
    }
)
export const selectMyGamesFilter = createSelector(
    myGamesFilter,
    (myGamesFilter) => {
        return myGamesFilter
    }
)

export const selectUserName = createSelector(
    userName,
    (name) => {
        return name
    }
)
export const selectUserNameAndBalance = createSelector(
    userName,
    userBalance,
    (name, balance) => {
        return {
            userName: name,
            userBalance: balance
        }
    }
)

export const selectStatisticsFilterError = createSelector(
    statisticsFilterError,
    (error) => {
        return error
    }
)