import { createAction, props } from "@ngrx/store"

const RESIZE_DISPLAY = '[DisplayMode] resize display'
const RESIZE_DISPLAY_DONE = '[DisplayMode] resize display done'
const DECK_POSITION = '[DisplayMode] set deck position'
const DECK_SIZE = '[DisplayMode] set deck size'
const TABLE_POSITION = '[DisplayMode] set table position'
const ACE_SWITCH_POSITION = '[DisplayMode] set ace switch positon'
const APP_WRAPPER_SIZE = '[DisplayMode] set app wrapper size'
export const resizeDisplay = createAction(
    RESIZE_DISPLAY,
    props<{ width: number, height: number, isPortrait: boolean}>()
)

export const setDeckSize = createAction(
    DECK_SIZE,
    props<{width: number, height: number}>()
)
export const setDeckPosition = createAction(
    DECK_POSITION,
    props<{left: number, top: number}>()
)

export const setTablePosition = createAction(
    TABLE_POSITION,
    props<{left: number, top: number, width: number, height: number}>()
)
export const setAceSwitchPosition = createAction(
    ACE_SWITCH_POSITION,
    props<{left: number, top: number}>()
)

export const setAppWrapperSize = createAction(
    APP_WRAPPER_SIZE,
    props<{width: number, height: number}>()
) 
