import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameComponent } from './layouts/game/game.component';
import { CardComponent } from './layouts/game/shared/card/card.component';
import { GameReplayComponent } from './layouts/game/game-replay/game-replay.component';

const routes: Routes = [
  { path: '', component: DashboardComponent},
  { path: 'game', component: GameComponent },
  { path: 'game-replay', component: GameReplayComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
