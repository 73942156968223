import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameTableComponent } from './game-table.component';
import { TableInfoBoxComponent } from './table-info-box/table-info-box.component';
import { TableControlLeftComponent } from './table-control-left/table-control-left.component';
import { TableControlRightComponent } from './table-control-right/table-control-right.component';
import { AceSwitchComponent } from './table-control-left/ace-switch/ace-switch.component';
import { SevenOfCutterComponent } from './table-control-left/seven-of-cutter/seven-of-cutter.component';
import { NumberOfCardsComponent } from '../shared/number-of-cards/number-of-cards.component';
import { GameSharedComponentsModule } from '../shared/game-shared-components.module';
import { SelectedSuitComponent } from './selected-suit/selected-suit.component';



@NgModule({
  declarations: [ GameTableComponent, TableInfoBoxComponent, TableControlLeftComponent, TableControlRightComponent, AceSwitchComponent, SevenOfCutterComponent, NumberOfCardsComponent, SelectedSuitComponent ],
  imports: [
    CommonModule,
    GameSharedComponentsModule
  ],
  exports: [
    GameTableComponent,
    NumberOfCardsComponent, //this component is from game/shared folder 
    TableInfoBoxComponent, TableControlLeftComponent, TableControlRightComponent, AceSwitchComponent, SevenOfCutterComponent, NumberOfCardsComponent, SelectedSuitComponent 
  ]
})
export class GameTableModule { }
