import { animate, animation, keyframes, query, style } from "@angular/animations";

const offset = 3/15
export const runTurnTimer = animation([
    query(':self', [
        animate('15s linear', keyframes([
            style({stroke: '{{stroke}}', strokeDashoffset: 0, offset: 0}),
            style({stroke: '{{stroke}}', strokeDashoffset: 0, offset: offset}),
            style({stroke: '{{stroke}}', strokeDashoffset: 158, offset: 1}),
        ]))
    ])
])

export const runTimeBankTimer = animation([
    query(':self', [
        animate('60s linear', keyframes([
            style({stroke: '{{stroke}}', strokeDashoffset: 0, offset: 0}),
            // style({stroke: '{{stroke}}', strokeDashoffset: 0, offset: offset}),
            style({stroke: '{{stroke}}', strokeDashoffset: 158, offset: 1}),
        ]))
    ])
])

//   strokeDashoffset: 0
// strokeDashoffset: 158
