import { Action, createReducer, on, select } from "@ngrx/store";
import { Card, CardSuit, CardValue, Game, GameControlsLeft, GameStatus, GlowingStates, PlayerInfoStates, PlayerMove } from "src/app/models/game.model";
import { GameType } from "src/app/models/lobby.model";
import * as GameActions from "../actions/game.actions"
import { gameStatus, playerAction, playerCards, playerMoves, selectPlayerCards, selecttableCards } from "../selectors/game.selector";
//1200x800 - rozliseni 
export const initialGameState: Game = {
    gameStatus: GameStatus.running_game,
    gameBuyIn: 50000,
    gametype: GameType.H2H,
    opponentName: 'Kapitan123Kapitan123',
    glowingState: GlowingStates.none,
    playerInfoState: PlayerInfoStates.none,
    opponentRematchStatus: 0,
    playerRematchstatus: 0,
    startDealCardForPlayer: 1,
    opponentCards: [
        { number: CardValue.three, suit: CardSuit.heart},
        { number: CardValue.three, suit: CardSuit.tile},
        // { number: CardValue.three, suit: CardSuit.heart},
        // { number: CardValue.three, suit: CardSuit.tile},
        // { number: CardValue.three, suit: CardSuit.tile},
        // { number: CardValue.nine, suit: CardSuit.heart},
        // { number: CardValue.nine, suit: CardSuit.tile}
    ],
    numberOfOpponentCards: 7,
    valueOfOpponentCards: 63,

    winner: 0,
    timers: [
        {
            turnTimer: false,
            timeBankTimer: false,
            timeOutTimer: false
        },
        {
            turnTimer: false,
            timeBankTimer: false,
            timeOutTimer: false
        }
    ],
    playerAction: [false, false],
    playerTurn: 0,
    playerMoves: [],
    displayGameControls: {
        left: null,
        right: '',
        bottom: '',
        modal: ''
    },
    tableCards: [
        { number: CardValue.two, suit: CardSuit.heart, handOrder: 0, handPosition: {
            rotation: 0,
            left: 0,
            top: 0
        }, player: 0, deckPosition: {
            rotation: 0,
            left: 0,
            top: 0
        }},
        { number: 3, suit: CardSuit.clover, handOrder: 0, handPosition: {
            rotation: 0,
            left: 0,
            top: 0
        }, player: 1, deckPosition: {
            rotation: 0,
            left: 0,
            top: 0
        }},
        // { number: 4, suit: CardSuit.pike, handOrder: 0, handPosition: {
        //     rotation: 0,
        //     left: 0,
        //     top: 0
        // }, player: 13, deckPosition: {
        //     rotation: 0,
        //     left: 0,
        //     top: 0
        // }},
        // { number: 11, suit: CardSuit.tile, handOrder: 0, handPosition: {
        //     rotation: 0,
        //     left: 0,
        //     top: 0
        // }, player: 1, deckPosition: {
        //     rotation: 0,
        //     left: 0,
        //     top: 0
        // }},
       
    ],
    playerCards: [
        { number: CardValue.two, suit: CardSuit.pike},
        { number: CardValue.three, suit: CardSuit.pike },
        { number: CardValue.four, suit: CardSuit.pike },
        { number: CardValue.five, suit: CardSuit.pike },
        { number: CardValue.six, suit: CardSuit.pike},
        { number: CardValue.seven, suit: CardSuit.pike },
        { number: CardValue.eight, suit: CardSuit.pike },
        { number: CardValue.nine, suit: CardSuit.pike },
        { number: CardValue.ten, suit: CardSuit.pike},
        { number: CardValue.jack, suit: CardSuit.pike },
        { number: CardValue.queen, suit: CardSuit.pike },
        { number: CardValue.king, suit: CardSuit.pike },
        { number: CardValue.ace, suit: CardSuit.pike },
        // { number: CardValue.seven, suit: CardSuit.tile },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 1, suit: CardSuit.heart},
        // { number: 2, suit: CardSuit.clover },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 1, suit: CardSuit.heart},
        // { number: 2, suit: CardSuit.clover },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 1, suit: CardSuit.heart},
        // { number: 2, suit: CardSuit.clover },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 4, suit: CardSuit.tile },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 1, suit: CardSuit.heart},
        // { number: 2, suit: CardSuit.clover },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
        // { number: 2, suit: CardSuit.clover },
        // { number: 3, suit: CardSuit.pike },
        // { number: 4, suit: CardSuit.tile },
    ],
    
    playerPlaysCard: null,
    opponentPlaysCard: {
        number: 20,
        suit: CardSuit.heart,
        handOrder: 6,
        handPosition: {
            rotation: 0,
            left: 0,
            top: 0
        },
        deckPosition: {
            rotation: 0,
            left: 0,
            top: 0
        },
        player: 1
    },
 
    // drawCards: [
    //     { number: 0, suit: CardSuit.clover },
    //     { number: 12, suit: CardSuit.clover },
    // ],
    // drawOpponentCards: 2,   
    cutterCard: {number: CardValue.five, suit: CardSuit.clover},
    selectedSuit: null
}

export const createGameReducer = createReducer(
    initialGameState,
    on( GameActions.switchGlowingState, 
        (state, {glowingState} ) => {
            return {
                ...state,
                glowingState: glowingState
            }
        }
    ),
    on( GameActions.drawCards,
        (state, { cards }) => {
            
            let playerMoves = [...state.playerMoves]
            playerMoves = playerMoves.concat([PlayerMove.draw_card])

            return {
                ...state,
                playerCards: state.playerCards.concat(cards),
                playerMoves: playerMoves
            }
        }
    ),
    on( GameActions.drawOpponentCards,
        (state, { numberOfCards }) => {

            let playerMoves = [...state.playerMoves]
            playerMoves = playerMoves.concat([PlayerMove.draw_card])

            return {
                ...state, 
                numberOfOpponentCards: state.numberOfOpponentCards + numberOfCards,
                playerMoves: playerMoves
            }
        }
    ),

    on( GameActions.selectSuit, 
        (state, { selectedSuit }) => {
            let playerMoves = [...state.playerMoves]
            playerMoves =  playerMoves.concat([PlayerMove.select_suit])

            return {
                ...state,
                selectedSuit: selectedSuit,
                playerMoves: playerMoves
            }
        }
    ),
    on( GameActions.addTableCard,
        (state, {playedCard}) => {
            // console.log(playedCard);
            
            // let tableCards = state.tableCards
            // tableCards.push(playedCard)
            // console.log(tableCards);
            
            return {
                ...state,
                // tableCards: tableCards
            }
        }
    ),
    on ( GameActions.shuffledCards,
        (state, {shuffledcards}) => {
            return {
                ...state,
                tableCards: shuffledcards
            }
        }
    ),
    on (GameActions.switchGameStatus, 
        (state, {status}) => {
            return {
                ...state,
                gameStatus: status
            }
        }
    ),
    on( GameActions.playerAction,
        (state, { player }) => {
            
            const actions = [...state.playerAction]
            actions[player] = true
            
            return {
                ...state,
                playerAction: actions
            }
        }
    ),
    on( GameActions.switchedTurn,
        (state, { playerTurn }) => {
            
            let playerMoves: PlayerMove[] = []

            return {
                ...state,
                playerTurn,
                playerMoves: playerMoves
            }
        }
    ),
    on( GameActions.turnTimerStart,
        (state, { player }) => {
            // console.log("start turn timer: " + player);
            
            const timers = state.timers.map(t => ({ ...t }))
            const playerTimers = timers[player]
            playerTimers.turnTimer = true
                     
            const actions = [...state.playerAction]
            actions[player] = false

            // console.log(timers);
            
            return {
                ...state,
                timers: timers,
                playerAction: actions
            }
        }
    ),
    on( GameActions.timeBankTimerStart,
        (state, { player }) => {
            // console.log("time bank timer start");
            
            const timers = state.timers.map(t => ({ ...t }))
            const playerTimers = timers[player]
            playerTimers.turnTimer = false
            playerTimers.timeBankTimer = true

            return {
                ...state,
                timers: timers
            }
        }
    ),
    on( GameActions.timeBankTimerDone,
        (state, { player }) => {
            // console.log("time bank timer start");
            
            const timers = state.timers.map(t => ({ ...t }))
            const playerTimers = timers[player]
            playerTimers.timeBankTimer = false

            return {
                ...state,
                timers: timers
            }
        }
    ),
    on( GameActions.turnOffAllTimers,
        (state, { player }) => {
            
            const timers = state.timers.map(t => ({ ...t }))
            const playerTimers = timers[player]
            playerTimers.turnTimer = false
            playerTimers.timeBankTimer = false
            playerTimers.timeOutTimer = false
            // console.log(timers);
            

            return {
                ...state,
                timers: timers
            }
        }
    ),
 
    on( GameActions.playerPlayesCard,
        (state, { playedCard }) => {

            let playerMoves = [...state.playerMoves]
            let gameStatus = state.gameStatus

            switch (playedCard.number) {
                case CardValue.ace: {
                    playerMoves = playerMoves.concat([PlayerMove.play_card_ace])
                    break
                }
                case CardValue.eight || CardValue.jack: {
                    playerMoves = playerMoves.concat([PlayerMove.play_card_stop])
                    break
                }
                case CardValue.two: {
                    playerMoves = playerMoves.concat([PlayerMove.play_card_two])
                    break
                }
                case CardValue.seven: {
                    //get symbol of card under deck and value of card in player hand
                    let cutterCard = state.cutterCard

                     //TODO: add check for sum of card in player hand
                    if (cutterCard.suit === playedCard.suit) {
                        playerMoves = playerMoves.concat([PlayerMove.play_card_seven_of_cutter])
                        gameStatus = GameStatus.seven_of_cutter_cards
                        break;    
                    } 
                    
                    // let sum = state.playerCards.reduce((sum: number, card: Card) => {
                    //     return sum + card.number
                    // }, 0)
                   
                    playerMoves = playerMoves.concat([PlayerMove.play_card])
                    break;    
                }
                default: {
                    playerMoves = playerMoves.concat([PlayerMove.play_card])
                }
                    
            }
            
            // console.log(playerMoves);
            

            let tableCards = [...state.tableCards]
            tableCards = tableCards.concat([playedCard])
            
            let playerCards = [...state.playerCards]
            playerCards.splice(playedCard.handOrder,1)

            return {
                ...state,
                playerPlaysCard: playedCard,
                tableCards: tableCards,
                playerMoves: playerMoves,
                playerCards: playerCards,
                gameStatus: gameStatus
            }
        }
    ),
    on( GameActions.playerPlayedCard,
        (state, { playedCard }) => {
            // let tableCards = selecttableCards(state)
            // tableCards = tableCards.concat([playedCard])
        
            if (!playedCard) {
                return state
            }
            let playerCards = [...state.playerCards]
            playerCards.splice(playedCard.handOrder,1)
            
            return {
                ...state,
                playerPlaysCard: null,
                playerCards: playerCards
            }
        }
    ),
    on( GameActions.opponentPlaysCard,
        (state, { playedCard }) => {
            // console.log("played card by opponent");
            // console.log(playedCard);
            
            let playerMoves = [...state.playerMoves]
            playerMoves = playerMoves.concat([PlayerMove.play_card])

            var tableCards = selecttableCards(state)
            tableCards = tableCards.concat([playedCard])
            return {
                ...state,
                opponentPlaysCard: playedCard,
                tableCards: tableCards,
                playerMoves: playerMoves
            }
        }
    ),
    on( GameActions.opponentPlayedCard,
        (state, { playedCard, deckPosition }) => {
            
            if (!playedCard) {
                return state
            }

            var numberOfOpponentCards = state.numberOfOpponentCards - 1
            var tableCards = selecttableCards(state)
            var newPlayedCard = {...playedCard}
            newPlayedCard.deckPosition = deckPosition
            
            tableCards = tableCards.concat([newPlayedCard])
            // console.log(tableCards);
            
            return {
                ...state,
                numberOfOpponentCards: numberOfOpponentCards,
                opponentPlaysCard: null,
                tableCards: tableCards
                
            }
        }
    ),
)

export function gameReducer(state: Game | undefined, action: Action): Game {
    return createGameReducer(state, action);
}