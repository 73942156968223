import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralModalComponent } from './general-modal/general-modal.component';
import { RematchModalComponent } from './rematch-modal/rematch-modal.component';
import { StatsBlockComponent } from './shared/stats-block/stats-block.component';
import { ModalGameVariantComponent } from './shared/modal-game-variant/modal-game-variant.component';



@NgModule({
  declarations: [
    GeneralModalComponent, 
    RematchModalComponent, 
    StatsBlockComponent, 
    ModalGameVariantComponent],
  imports: [
    CommonModule
  ],
  exports: [
    GeneralModalComponent, 
    RematchModalComponent, 
    StatsBlockComponent, 
    ModalGameVariantComponent
  ]
})
export class ModalsModule { }
