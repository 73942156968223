import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GameStatus } from 'src/app/models/game.model';
import { AppState } from 'src/app/state/app.state';
import { selectGameStatus } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-game-control',
  templateUrl: './game-control.component.html',
  styleUrls: ['./game-control.component.scss']
})
export class GameControlComponent implements OnInit {

  game_id="123456789123"
  give_up_modal: boolean = false

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.game_id = this.getGameId();
    this.subscribeGameStatus()
  }

  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }

  getGameId() {
    return this.game_id;
  }

  handleGiveUpModal() {
    this.give_up_modal = true
  }

  giveUpGame() {
    console.log('give up game');
    //TODO: send request to give up game
    //TODO: set global state of game
  }

  continueInGame() {
    this.give_up_modal = false
  }

  canRenderGameControl() {
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }
    // if (this.gameStatus === GameStatus.rematch) {
    //   return false
    // }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
  
    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
  
    return true
  }

  canRenderGameGiveUpButton() {
   
    return true
  }


}
