<div class="landscape_inner_border">


<div class="">
    <div class="column info">
        <div class="column name">Win:</div>
        <div class="column amount" [ngStyle]="calculateStyleForAmount('win')">{{wonGames}}</div>    
    </div>
    <div class="column chart" >
        <div class="bar-chart win" [ngStyle]="calculateStyleForWinLost('win')"></div>
    </div>
</div>

<div class="">
    <div class="column info">
        <div class="column name">Lost:</div>
        <div class="column amount" [ngStyle]="calculateStyleForAmount('lost')">{{lostGames}}</div>    
    </div>
    
    <div class="column chart">
        <div class="bar-chart lost" [ngStyle]="calculateStyleForWinLost('lost')"></div>
    </div>
</div>

</div>