export interface Lobby {
    gameType: GameType,
    buyInType: BuyInType ,
    opponent: {
        name: string, 
        exist: boolean,
        available: boolean
    }
    invitation: {
        name: string,
        buyIn: BuyInType
    }
}

export interface GameVariant {
    name: string,  
    icon: string, 
    type: GameType,
    classes: Array<string>
}

export enum GameType {
    H2H = "H2H",    
    FRIEND = "FRIEND",
}
export type GameTypeKeys = keyof typeof GameType;
    
export interface BuyIn {
    name: string,
    type: BuyInType,
    classes: any//Array<string>
}
export type BuyInType = BuyInValue | null;
export type BuyInValue = 0 | 1000 | 2000 | 5000 | 10000 | 20000 | 50000; 

export enum StartGameErrors {
    none = "none",
    notBuyIn = "notBuyIn",
    incorrectOpponentName = "incorrectOpponentName",
    notOpponentNameExist = "notOpponentNameExist",
    notOpponentAvailable = "notOpponentAvailable"
}

