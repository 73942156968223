import { animate, animation, group, keyframes, query, sequence, state, style, transition, trigger } from '@angular/animations';

export const dealDrawCard = animation([
  sequence([
    group([
      query(":self", [
        animate('{{delay}} linear',
          keyframes([
            style({ left: 'calc(50% + {{factor_x}})', offset: 0 }),
            style({ left: 'calc(50% + {{factor_x}})', offset: 1 }),
          ])
        )
      ]),
      query(":enter .flip-front", [
        animate('{{delay}} linear',
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0, offset: 1}),
          ])
        )
      ]),
      query(":enter .flip-back", [
        animate('{{delay}} linear',
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0, offset: 1}),
          ])
        )
      ])
    ]),
    group([
      query(":self", [
        animate(
          '{{time}} linear',
          keyframes([
            style({ left: 'calc(50% + {{factor_x}})', offset: 0 }),
            style({ left: 'calc(50% + {{factor_x}})', offset: 0.5 }),
            style({ left: '50%', offset: 1 })
          ])
        )
      ], {optional: true}),
      query(':enter .flip-front', [
        animate(
          "{{time}} linear",
          keyframes([ 
            style({opacity: 1, left: '{{from_x_pos}}', bottom: '{{from_y_pos}}', transform: 'rotateY(180deg)', offset: 0}),
            style({opacity: 1, left: '{{from_x_pos}}', bottom: '{{from_y_pos_2}}', transform: 'rotateY(90deg)', offset: 0.5}),
            style({opacity: 1, left: '0', bottom: '0', transform: 'rotateY(0deg)', offset: 1})
          ])
        )
      ], {optional: true}),
      query(':enter .flip-back', [
        animate(
          "{{time}} linear",
          keyframes([
            style({opacity: 1, left: '{{from_x_pos}}', bottom: '{{from_y_pos}}', transform: 'rotate(180deg) rotateY(0deg)', offset: 0}),
            style({opacity: 1, left: '{{from_x_pos}}', bottom: '{{from_y_pos_2}}', transform: 'rotate(180deg) rotateY(90deg)', offset: 0.5}),
            style({opacity: 1, left: '0', bottom: '0', transform: 'rotate(180deg) rotateY(180deg)', offset: 1})
          ])
        )
      ], {optional: true})
    ])
  ])

])
