import { transition, trigger, useAnimation } from '@angular/animations';
import { BoundAttribute } from '@angular/compiler/src/render3/r3_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, State, Store } from '@ngrx/store';
import { logging } from 'protractor';
import { Card, CardSuit, Game, GameStatus, PlayedCard } from 'src/app/models/game.model';
import { CardPositionService } from 'src/app/services/card-position.service';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { setTablePosition } from 'src/app/state/actions/display-mode.actions';
import { addTableCard, shuffleCards, switchGameStatus } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize, selectWindowDimensions } from 'src/app/state/selectors/display-mode.selector';
import { selectGameStatus, selectOpponentPlaysCard, selectPlayerCards, selectPlayerOnTurn, selectPlayerPlaysCard, selecttableCards as selectTableCards } from 'src/app/state/selectors/game.selector';
import { playCard } from './animations/play_card.animation';

@Component({
  selector: 'app-game-table',
  templateUrl: './game-table.component.html',
  styleUrls: ['./game-table.component.scss'],
  animations: [
  ]
})
export class GameTableComponent implements OnInit {
  
  @ViewChild('table_cards_ref', { static: false, read: ElementRef})  table_cards_ref!: ElementRef
  
  info_message_variant  : string = ''
  
  //"waiting_for_opponent",
  //"waiting_for_invited_opponent",
  table_image = "assets/images/game/table.png"

  tableCards!: PlayedCard[] 
  tableCards$ = this.store.pipe(
    select(state => selectTableCards(state.game))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  constructor(
    private store: Store<AppState>, 
    private cardPositionService: CardPositionService,
    private router: Router,
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeAppWrapperSize()
    this.subscribeGameStatus()

    this.subscribeTableCards();
    // this.shuffleTableCards();

  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }

  handleCancelGame() {
    //TODO: sent info to  server
    this.store.dispatch(switchGameStatus({status: GameStatus.game_closed_before_start}))
    // this.router.navigate(['/'])

  }
  handleCancelGameInvation() {
    //TODO: sent info to server
    this.router.navigate(['/'])
  }

  readyButtonActive: boolean = false
  handleReadySignal() {
    //TODO: sent info to server
    this.readyButtonActive = !this.readyButtonActive
  }

  oldTablePosition!: any
  tablePosition!: {top: number, left: number, width: number, height: number}
  ngAfterViewInit() {
    let tableRect = this.table_cards_ref?.nativeElement.getBoundingClientRect()
    this.tablePosition = {
      top: tableRect?.top,
      left: tableRect?.left,
      width: tableRect?.width,
      height: tableRect?.height
    }
    this.oldTablePosition = this.tablePosition
    this.setTablePosition()
    this.shuffleTableCards()
  }
  ngAfterViewChecked() {

    let tableRect = this.table_cards_ref?.nativeElement.getBoundingClientRect()
    this.tablePosition = {
      top: tableRect?.top,
      left: tableRect?.left,
      width: tableRect?.width,
      height: tableRect?.height
    }
    
    if (this.oldTablePosition.top !== this.tablePosition.top || this.oldTablePosition.left !== this.tablePosition.left
      || this.oldTablePosition.width !== this.tablePosition.width || this.oldTablePosition.height !== this.tablePosition.height) {
      this.oldTablePosition = this.tablePosition
      this.setTablePosition()
    }
  }

  
  setTablePosition() {
    // console.log({left: this.tableCardsRef.left, top: this.tableCardsRef.top });
    this.store.dispatch(setTablePosition(this.tablePosition))
  }

  subscribeTableCards() {
    this.tableCards$.subscribe(value => {
      if (!value) {
        return 
      }
      // if (this.tableCards.length !== value.length) {

      // }
      this.tableCards = value;
      // this.playCardAnimationState = 'new_card'  
    })
  }

  shuffleTableCards() {
    this.store.dispatch(shuffleCards())
    // this.playCardAnimationState = '*'
  }

  calculateRandomStyle(card: PlayedCard) {
    // card.deckPosition.rotation = 0
    let transform = 'rotate('+ card.deckPosition.rotation +'deg)'
    // console.log(transform);
    
    return {
      transform: transform,
      top: card.deckPosition.top + "px",
      left: card.deckPosition.left + "px"
    }
  }
  
  canRenderTableCards() {
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return  false
    }
    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return  false
    }
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      return  false
    }
    if (this.gameStatus === GameStatus.match_started_cutter) {
      return  false
    }
    if (this.gameStatus === GameStatus.match_started_table_info) {
      return  false
    }
    if (this.gameStatus === GameStatus.match_started_timebanks) {
      return  false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    return true
  } 
  
  canRenderTableInfo() {
    if (this.gameStatus === GameStatus.running_game) {
      return false
    }
    return true
  }

  tableWrapperSize() {
    let defaultWidth = 249
    let defaultHeight = 127

    let width = (this.displayModeServices.expanded * defaultWidth)
    let height = (this.displayModeServices.expanded * defaultHeight)

    return {
      width: width + 'px',
      height: height + 'px'
    }
  }
  selectedSuitPosition() {
    if (this.displayModeServices.rotation == 1) {
      let top = ((this.appWrapperSize.width * 0.44) - (this.displayModeServices.expanded * 127) ) /2
      return {
        top: top + 'px'
      }
    }
    let top = ((this.appWrapperSize.height * 0.44) - (this.displayModeServices.expanded * 127) ) /2
    return {
      top: top + 'px'
    }
  }
  buttonPosition() {
    let defaultHeight = 40
    let height = (this.displayModeServices.expanded * defaultHeight)

    if (this.displayModeServices.rotation == 1) {
      let bottom = (this.appWrapperSize.width * -0.26) + 2*height
      return {
        bottom: bottom + 'px'
      }
    }
    
   

    let bottom = (this.appWrapperSize.height * -0.26) + 2*height
    
    return {
      bottom: bottom + 'px'
    }
  }
}


