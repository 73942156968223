import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BuyInType, GameType, GameVariant } from 'src/app/models/lobby.model';
import { AppState } from 'src/app/state/app.state';
import { selectGameOptionsForRematch } from 'src/app/state/selectors/game.selector';
import { activeBuyInType, activeGameVariant, feeForUser, opponent, possibleUserWin } from 'src/app/state/selectors/lobby.selector';
import { selectUserNameAndBalance } from 'src/app/state/selectors/user.selector';

@Component({
  selector: 'app-stats-block',
  templateUrl: './stats-block.component.html',
  styleUrls: ['./stats-block.component.scss']
})
export class StatsBlockComponent implements OnInit {
  
  @Input() rematch: boolean = false;

  opponent: string = ""
  opponent$ = this.store.pipe(
    select(state => opponent(state.lobby))
  )
  
  buyIn!: BuyInType
  buyIn$ = this.store.pipe(
    select(state => activeBuyInType(state.lobby))
  )
  gameVariant!: GameVariant 
  gameVariant$ = this.store.pipe(
    select(state => activeGameVariant(state.lobby))
  )

  fee!: number | null
  fee$ = this.store.pipe(
    select(state => feeForUser(state.lobby))
  )

  possibleWin!: number 
  possibleWin$ = this.store.pipe(
    select(state => possibleUserWin(state.lobby))
  )

  gameOptions!: any
  gameOptions$ = this.store.pipe(
    select(state => selectGameOptionsForRematch(state.game))
  )
  
  playerName: string = ""
  userBalance: number =  1000;
  userNameAndBalance$ = this.store.pipe(
    select(state => selectUserNameAndBalance(state.user))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeUserNameAndBalance()

    if (this.rematch) {
      this.subscribeGameOptions()
      return 
    }

    this.subscribeBuyIn()
    this.subscribeGameVariant()
    this.subscribeFee()
    this.subscribePossibleWin()
    this.subscribeOpponentName()
  }

  subscribeUserNameAndBalance() {
    this.userNameAndBalance$.subscribe(value => {
      this.userBalance = value.userBalance;
      this.playerName = value.userName;
    });
  }
  subscribeGameOptions() {
    this.gameOptions$.subscribe(value => {
      this.opponent = value.opponentName;
      this.buyIn = value.gameBuyIn;
      let gamemType = value.gameType;
      if (gamemType === GameType.FRIEND) {
        this.gameVariant = {
          name: "",
          icon: "",
          type: gamemType,
          classes: []
        }
      }
      if (gamemType === GameType.H2H) {
        this.gameVariant = {
          name: "",
          icon: "",
          type: gamemType,
          classes: []
        }
      }
      this.fee = value.feeForUser
      this.possibleWin = value.possibleUserWin
      
    })
  }

  subscribeBuyIn() {
    this.buyIn$.subscribe(value => {
      this.buyIn = value
    })
  }
  subscribeGameVariant() {
    this.gameVariant$.subscribe(value => {
      //console.log(value);
      if (value == undefined) {
        return;
      }
      this.gameVariant = value
    })
  }
  subscribeFee() {
    this.fee$.subscribe(value => {
      if (value == null) {
        return
      }
      this.fee = value
    })
  }
  subscribePossibleWin() {
    this.possibleWin$.subscribe(value => {
      if(value == null) {
        return 
      }
      this.possibleWin = value
    })
  }

  subscribeOpponentName() {
    this.opponent$.subscribe(value => {
      this.opponent = value.name
    })
  }

  renderThousands(number: number | null) {
    if (!number) {
      return 
    }

    let string = number.toString().split("").reverse().join("")
    // console.log(string);
    string = string.replace(/(\d{3})/g, "$1 ")
    return string.split("").reverse().join("")
  }

}
