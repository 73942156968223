<div class="modal rematch">
    <div class="header">
        <div class="title">Rematch</div>
    </div>
    <div class="content">
        <div class="top">
            <div class="left">
                <app-stats-block [rematch]="true"></app-stats-block>
            </div>
            <div class="right">
                <div class="participants m-t-2">Participants: </div>
                <div class="participants-connection">
                    <div class="opponent">
                        <div class="name">{{cropName(opponentName)}}</div>
                        <div class="status" [ngClass]="getRematchStatusClass(opponentRematchStatus)">{{getRematchstatus(opponentRematchStatus)}}</div>
                    </div>
                    <div class="player">
                        <div class="name">{{cropName(userName)}}</div>
                        <div class="status" [ngClass]="getRematchStatusClass(userRematchstatus)">{{getRematchstatus(userRematchstatus)}}</div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="bottom">
            <div class="rematch-countdown">
                For answer, there is <span class="countdown">{{countdown$ | async}} sec.</span> remaining
            </div>
            <div class="buttons-wrapper">
                <ng-content select="buttons"></ng-content>
            </div>
        </div>        
    </div>
</div>    

