<div class="table_info_box" [ngStyle]="size()">
        
    <div 
        class="info_text" 
        *ngIf="gameStatus == 'waiting_for_opponent'">
        <p class="mb">Please wait…</p>
        <p>We are trying to find  </p>
        <p>you an opponent</p>
    </div>

    <div 
        class="info_text" 
        *ngIf="gameStatus == 'waiting_for_invited_opponent'">
        <p class="orange bold mb">{{opponentName}}</p>
        <p>received an invitation</p>
        <p class="orange bold mb mt">{{ waitingForInvitedFriendTimer$ | async }} sec.</p>
        <p>time to accept it</p>
    </div>

    <div
        class="info_text"
        *ngIf="gameStatus == 'waiting_for_match_start'">
        <p class="big mb">Match starts</p>
        <p class="orange bold bigger"> in {{ waitingForMatchStartTimer$ | async }} sec.</p>
    </div>

    <div
        class="info_text"
        *ngIf="gameStatus == 'match_started_table_info'">
        <p class="big mb">Match just started.</p>
        <p class="big">Good luck!</p>
    </div>

                
    <div
        class="info_text with_title" 
        *ngIf="gameStatus == 'game_closed_by_opponent_give_up'">
        <div class="title orange">you won!</div>
        <p class="big mt">Your opponent decided</p>
        <p class="big">to GAVE UP the game</p>
    </div>




    <div
        class="info_text with_title"
        *ngIf="gameStatus == 'lost_game'">
        <div class="title orange">you lost!</div>
        <p class="mt big">Rematch will be</p>
        <p class="big">offered in <span class="orange">{{ waitingForAvailableRematch$ | async }} sec.</span></p>
    </div>
    

    <div
        class="info_text green"
        *ngIf="gameStatus == 'player_online_again_opponent_turn'">
        
        <div class="m-b-7 ">
            <img class="exclamation_mark m-r-8" src="{{exclamation_mark_img}}" alt=""/>
            <div class="inline_box">
                <p>You are ONLINE</p>
                <p>again</p>
            </div>
            <img class="exclamation_mark m-l-8" src="{{exclamation_mark_img}}" alt=""/>    
        </div>
        
        <div>
            <div class="white_box">Wait for opponent’s turn</div>
        </div>
    </div>


    <div
        class="info_text green"
        *ngIf="gameStatus == 'player_online_again_player_turn'">
        <div class="m-b-7"> 
            <img class="exclamation_mark m-r-8" src="{{exclamation_mark_img}}" alt=""/>
            <div class="inline_box">
                <p>You are ONLINE</p>
                <p>again</p>
            </div>
            <img class="exclamation_mark m-l-8" src="{{exclamation_mark_img}}" alt=""/>    
        </div>  
        <div>
            <div class="white_box">It is your turn</div>
        </div>
    </div>

    <div
        class="info_text red"
        *ngIf="gameStatus == 'player_offline_opponent_turn'">
        <div class="m-b-7">
            <img class="exclamation_mark m-r-8" src="{{exclamation_mark_img}}" alt=""/>
            <div class="inline_box">
                <p class="bigger bold">You are OFFLINE</p>
            </div>
            <img class="exclamation_mark m-l-8" src="{{exclamation_mark_img}}" alt=""/>    
        </div>
        <div class="columns">
            <p>It may lead you to loss</p>
            <p>this game.</p>
        </div>
    </div>




    <div 
        class="info_text red"
        *ngIf="gameStatus == 'player_offline_player_turn'">
        <div class="m-b-7 columns">
            <p class="bigger bold mb">You are OFFLINE</p>
            <p>Time bank for your recovery</p>
        </div>
        <div>
            <img class="exclamation_mark m-r-16" src="{{exclamation_mark_img}}" alt=""/>
            <div class="white_box">{{ timeBankTimer$ | async }} sec.</div>
            <img class="exclamation_mark m-l-16" src="{{exclamation_mark_img}}" alt=""/>    
        </div>
    </div>



    <div
        class="info_text gray"
        *ngIf="gameStatus == 'lost_connection_with_server'">
        <div class="m-b-7">
            <p>Connection with MATATU</p>
            <p>SERVER was lost</p>
        </div>
        <div>
            <div class="white_box orange">Wait for reconnection {{serverReconnectionTimer$ | async}} sec.</div>
        </div>
    </div>



    <div
        class="info_text gray"
        *ngIf="gameStatus == 'opponent_online_again'">
        <div class="m-b-7">
            <p>Your opponet is ONLINE</p>
            <p>again</p>
        </div>
        <div>
            <div class="white_box">Wait for opponent’s turn</div>
        </div>
    </div>



    <div
        class="info_text orange"
        *ngIf="gameStatus == 'opponent_offline'">
        <div class="m-b-7 columns">
            <p class="bigger bold">Opponent is OFFLINE</p>
            <p>Time bank for recovery</p>
        </div>
        <div>
            <img class="exclamation_mark m-r-16" src="{{exclamation_mark_img}}" alt=""/>
            <div class="white_box">{{opponentTimeBankTimer$ | async}} sec.</div>
            <img class="exclamation_mark m-l-16" src="{{exclamation_mark_img}}" alt=""/>
        </div>
    </div> 
</div>
