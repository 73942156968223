import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-players',
  templateUrl: './online-players.component.html',
  styleUrls: ['./online-players.component.scss']
})
export class OnlinePlayersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
