import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GameStatus, PlayerInfoStates } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { playerInfoState, selectGameStatus, selectOpponentName, selectPlayerInfoState } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-game-opponent',
  templateUrl: './game-opponent.component.html',
  styleUrls: ['./game-opponent.component.scss']
})
export class GameOpponentComponent implements OnInit {

  @Input() player!: number
  
  opponentName: string = ''
  opponentName$ = this.store.pipe(
    select(state => selectOpponentName(state.game))
  )

  playerInfoState: PlayerInfoStates = PlayerInfoStates.none
  playerInfoState$ = this.store.pipe(
    select(state => selectPlayerInfoState(state.game))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )
  
  constructor(private store: Store<AppState>, 
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeAppWrapperSize()

    this.subscribeGameStatus()
    this.subscribeOpponentName()
    this.subscribePlayerInfoState()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }

  subscribePlayerInfoState() {
    this.playerInfoState$.subscribe(value => {
      this.playerInfoState = value
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }

  subscribeOpponentName() {
    this.opponentName$.subscribe(value => {
      this.opponentName = value
    })
  }


  canRenderOpponentCards() {
    
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false 
    }

    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return false
    }
    
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    
    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    
    return true
  }

  canRenderPlayerInfo() {
    if (this.gameStatus === GameStatus.waiting_for_match_start && this.playerInfoState === PlayerInfoStates.opponent_is_ready ) {
      return true
    }
    return false
  }
  
  barWrapperSize() {
    let defaultHeight = 40
    let height = (this.displayModeServices.expanded * defaultHeight)
    return {
      height: height + 'px'
    }
  }

  opponentBarPosition() {
    let defaultWidthControls = 143 * this.displayModeServices.expanded
    let size = (315 * this.displayModeServices.expanded) 

    let left = Math.max((this.appWrapperSize.width - size) / 2, defaultWidthControls)

    
    return {
      left: left + 'px'
    }

  }


}
