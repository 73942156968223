import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, State, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BuyIn, BuyInType, GameType, GameVariant } from 'src/app/models/lobby.model';
import { selectGameType } from 'src/app/state/actions/lobby.actions';
import { AppState } from 'src/app/state/app.state';
import { activeGameType, activeGameVariant } from 'src/app/state/selectors/lobby.selector';

@Component({
  selector: 'app-landscape',
  templateUrl: './landscape.component.html',
  styleUrls: ['./landscape.component.scss']
})
export class LandscapeComponent implements OnInit {
  
  content_items = ['game_type', 'game_buy_in'];
  selected_content = this.content_items[0];
  

  switch_lobby_content_right_icon = "assets/images/svg/switch_right.svg"
  switch_lobby_content_left_icon = "assets/images/svg/switch_left.svg"

  selected_game_type!: GameType
  selected_game_type$ = this.state.pipe(
    select(state => activeGameType(state.lobby))
  )


  @Input() gameTypes!: GameVariant[]
  @Input() buyInTypes!: BuyIn[]

  constructor(private state:State<AppState>, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.subscribeSelectedGameType()    
    this.route.queryParams.subscribe(params => {
      // console.log(params);
      if (params['c'] === 'invite') {
          this.selected_content = this.content_items[1]
      }
    });
    
  }
  signalStartTheGame: boolean = false
  inputEvent($event: any) {
    // console.log($event);
    this.signalStartTheGame = true
    //reset signal 
    setTimeout(() => {
      this.signalStartTheGame = false
    }, 100)
  }
  
  subscribeSelectedGameType() {
    this.selected_game_type$.subscribe(value => {
      // console.log(value);
      this.selected_game_type = value;
    })
  }
  switchContent(index: number) {
    this.selected_content = this.content_items[index];
  }
}
