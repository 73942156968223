import { Component, Input, OnInit } from '@angular/core';
import { select, State, Store } from '@ngrx/store';
import { Card, CardSuit, GameControlsLeft, GameStatus, GlowingStates, PlayerMove } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { switchTurn } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectGameStatus, selectGlowingState, selectNextPlayerOnTurn, selectPlayerMoves, selectPlayerOnTurn, selectSelectedSuit } from 'src/app/state/selectors/game.selector';


@Component({
  selector: 'app-table-control-left',
  templateUrl: './table-control-left.component.html',
  styleUrls: ['./table-control-left.component.scss']
})
export class TableControlLeftComponent implements OnInit {

  @Input() replay: boolean = false

  GameControlsLeft = GameControlsLeft
  displayControls: GameControlsLeft | null = null
  
  play_left_active: boolean = false
  replay_play_pause_active: boolean = true
  play_right_active: boolean = false
  step_left_active: boolean = false
  step_right_active:boolean = true


  nextPlayerOnTurn!: number
  nextPlayerOnTurn$ = this.store.pipe(
    select(state => selectNextPlayerOnTurn(state.game))
  )

  //replay, play, pause
  play_pause_replay: "replay" | "play" | "pause"  = "play"
  
  // selectedSuit: CardSuit | null = null
  // selectedSuit$ = this.store.pipe(
  //   select(state => selectSelectedSuit(state.game))
  // )

  playerMoves: PlayerMove[] = []
  playerMoves$ = this.store.pipe(
    select(state => selectPlayerMoves(state.game))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  
  glowingStates: boolean = false
  glowingStates$ = this.store.pipe(
    select(state => selectGlowingState(state.game))
  )

  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )
  

  constructor(private store:Store<AppState>,
    private displayModeServices: DisplayModeService ) { }

  ngOnInit(): void {
    this.subscribeAppWrapperSize()

    this.subscribeNextplayerOnTurn()
    this.subscribePlayerMoves()
    this.subscribeGameStatus()
    this.subscribeGlowingState()

    // this.subscribeSelectedSuit()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }

  subscribeGlowingState() {
    this.glowingStates$.subscribe(value => {
      if (value === GlowingStates.glow_switch_button
        || value === GlowingStates.glow_all) {
        this.glowingStates = true
        return
      }
      this.glowingStates = false
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }

  // subscribeSelectedSuit() {
  //   this.selectedSuit$.subscribe(value => {
  //     this.selectedSuit = value
  //     console.log(value);
      
  //     if (this.selectedSuit) {
  //       // this.display_controls = GameControlsLeft.ace_switch
  //     }
      
  //   })
  // }
  subscribePlayerMoves() {
    this.playerMoves$.subscribe(value => {
      // console.log(value);      
      this.playerMoves = value      
      this.setControls() 
    })
    
  }

  subscribeNextplayerOnTurn() {
    this.nextPlayerOnTurn$.subscribe(value => {
      this.nextPlayerOnTurn = value
    })
  }
  handleSwitchButton() {
    this.store.dispatch(switchTurn({playerTurn: this.nextPlayerOnTurn}))
  }


  setControls() {
    // this.displayControls = GameControlsLeft.sevent_of_cutter
    // return 
    // console.log(this.playerMoves);
    // this.displayControls = GameControlsLeft.sevent_of_cutter
    // return 
    
    if (this.playerMoves === null || this.playerMoves[this.playerMoves.length - 1] === PlayerMove.play_card_stop) {
      this.displayControls = null
      return 
    }

    if (this.playerMoves[this.playerMoves.length - 1] === PlayerMove.play_card_ace
      || this.playerMoves[this.playerMoves.length - 1] === PlayerMove.select_suit)  
      {
      this.displayControls = GameControlsLeft.ace_switch
      return 
    }

    if (this.playerMoves[this.playerMoves.length - 1] === PlayerMove.play_card_seven_of_cutter) {
      this.displayControls = GameControlsLeft.sevent_of_cutter
      return 
    }

    //TODO: render sitch turn button on the end of the turn 
    // if (this.playerMoves.length !== 0) {
    //   this.displayControls = GameControlsLeft.switch_turn
    // }

  }
  canRenderLeftControls() {
    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    return true
  }

  replayControlsSize() {

    // let width = Math.max(this.appWrapperSize.width / 3.9, 138)
    // let height = Math.max(width * 0.5, 83)

    let width = 138 * this.displayModeServices.expanded 
    let height = 83 * this.displayModeServices.expanded

    let defaultWidth = 249
    let widthTable = (this.displayModeServices.expanded * defaultWidth)
    
    let empty = (this.appWrapperSize.width * -0.48 + widthTable) / -2
    let left = Math.max(empty - (this.displayModeServices.expanded * 16), 0)

    return {
      width: width + 'px',
      height: height + 'px',
      left: left + 'px'
    }
  }

  buttonPosition() {
    let defaultWidth = 249
    let width = (this.displayModeServices.expanded * defaultWidth)
    console.log(this.displayModeServices.rotation);
    
    if (this.displayModeServices.rotation == 1) {

    }
    let empty = (this.appWrapperSize.width * -0.48 + width) / 2
    let right = empty
    return {
      right: right + 'px'
    }
  }

  aceButtonPosition() {
    let defaultWidth = 249
    let defaultHeight = 127
    let width = (this.displayModeServices.expanded * defaultWidth)
    
    if (this.displayModeServices.rotation == 0) {
      let empty = (this.appWrapperSize.width * -0.48 + width) / 2
      let right = empty/2
      return {
        right: right + 'px'
      }
    } 
    
    // let height = (this.displayModeServices.expanded * defaultWidth)

    // console.log(this.displayModeServices.rotation);
    // console.log(this.appWrapperSize);
    
    let empty = (this.appWrapperSize.height * -0.48 + width) / 2
    let right = empty/2
    return {
      right: right + 'px'
    }
  

    
  }

}
