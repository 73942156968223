<div class="glow" [ngStyle]="arrowSize()">

    <div
    *ngIf="is_player_turn && player === 0 && glowingState" 
    class="glow-vertical"></div>
    <div
    *ngIf="is_player_turn && player === 0 && glowingState" 
    class="glow-top"></div>
    <div
    *ngIf="is_player_turn && player === 0 && glowingState" 
    class="glow-bottom"></div>
        
    <img
        *ngIf="is_player_turn"
        src="{{active_player_arrow_icon}}" alt="">
    
        <img 
        *ngIf="!is_player_turn"
        src="{{inactive_player_arrow_icon}}" alt="">
    
    
</div>

