import { GameVariant, GameType } from './../../../../models/lobby.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState } from 'src/app/state/app.state';
import { select, Store } from '@ngrx/store';
import * as LobbyActions from 'src/app/state/actions/lobby.actions'
import { Observable } from 'rxjs';
import { isActiveGameVariant } from 'src/app/state/selectors/lobby.selector';


@Component({
  selector: 'app-game-type',
  templateUrl: './game-type.component.html',
  styleUrls: ['./game-type.component.scss']
})
export class GameTypeComponent implements OnInit {

  @Input() gameVariant: GameVariant = {
    name: '',
    icon: '',
    type: GameType.H2H,
    classes: []
  };
  @Output() startTheGameEmitter = new EventEmitter<any>()

  isActive!: boolean
  isActive$ = this.store.pipe(
    select(state => isActiveGameVariant(state.lobby, {type: this.gameVariant.type}))
  )
  
  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeLobbyGameType()
    // console.log(this.gameVariant);
  }
  inputEvent($event: any) {
    console.log($event);
    this.startTheGameEmitter.emit(true)
  }

  selectGameType(): void {
    this.store.dispatch(LobbyActions.selectGameType({gameType: this.gameVariant.type! }))
  }
  subscribeLobbyGameType() {
    this.isActive$.subscribe(value => {
      // console.log(value);
      this.isActive = value
    })
  }
  calculateClass(): string[] {
    var result = this.gameVariant.classes;
    if (this.isActive) {
      result = result.concat('active')
    }

    return result;
  }

}
