import { createAction, createSelector, State } from "@ngrx/store";
import { DisplayMode } from "src/app/models/display-mode.model";
import { AppState } from "../app.state";

export const selectDisplaMode = (state: AppState) => state.displayMode;
 
export const displayWidth = (state: DisplayMode) => state.width;
export const displayHeight = (state: DisplayMode) => state.height;
const deckPosition = (state: DisplayMode) => state.deckPosition
const tablePosition = (state: DisplayMode) => state.tablePosition
const aceSwitchPosition = (state: DisplayMode) => state.aceSwitchPosition
const appWrapperSize = (state: DisplayMode) => state.appWrapperSize

export const selectAceSwitchPosition = createSelector(
    aceSwitchPosition,
    (aceSwitchPosition) => {
        return aceSwitchPosition
    }
)
export const selectWindowDimensions = createSelector(
    displayWidth,
    displayHeight,
    (width, height) => {
        // console.log(width, height);
        
        return {
            width: width,
            height: height
        }
    }
)

export const selectTablePosition = createSelector(
    tablePosition,
    (position) => {
        return position
    }
)

export const selectDeckPosition = createSelector(
    deckPosition,
    (position) => {
        return position;
    }
)

export const isPortraitMode = createSelector(
    displayWidth,
    displayHeight,
    (width, height) => {        
        return (height > width)
    }
);
export const isMobileMode = createSelector(
    displayWidth,
    displayHeight,
    isPortraitMode,
    (width, height, portrait) => {
        
        if (!portrait) {
            if (height < 500) {
                return true
            }
        }

        if (width < 500) {
            return true
        }
        return false
    }
)

export const isMobilePortraitMode = createSelector(
    isPortraitMode,
    isMobileMode,
    (portrait, mobile) => {
        //console.log("landscape: " + !portrait);
        //console.log("mobile: " + mobile);
        
        return (portrait && mobile)
    }
)
export const isMobileLandscapeMode = createSelector(
    isPortraitMode,
    isMobileMode,
    (portrait, mobile) => {
        //console.log("landscape: " + !portrait);
        //console.log("mobile: " + mobile);
        
        return (!portrait && mobile)
    }
)

export const selectAppWrapperSize = createSelector(
    appWrapperSize,
    (appWrapperSize) => {
        return appWrapperSize
    }
)