import { Injectable, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { log, table } from 'node:console';
import { Position } from '../models/game.model';
import { shuffleCards } from '../state/actions/game.actions';
import { AppState } from '../state/app.state';
import { selectAppWrapperSize, selectTablePosition } from '../state/selectors/display-mode.selector';
import { DisplayModeService } from './display-mode.service';


@Injectable({
  providedIn: 'root'
})
export class CardPositionService {


  HEIGHT = 75
  WIDTH = 60

  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  tablePosition!: any
  tablePosition$ = this.store.pipe(
    select(state => selectTablePosition(state.displayMode))
  )

  constructor(private store:Store<AppState>, private displayModeService: DisplayModeService) {

    this.appWrapperSize$.subscribe(value => {
 
      this.appWrapperSize = value     
      // this.setScreenRotation()
      this.calculateCardSize()

    })
    // this.calculateCardSize()

    this.tablePosition$.subscribe(value => {
      this.tablePosition = value
      // console.log("Table position:", value);    
    })
  }

  calculateCardSize() {    
    // console.log("calculateCardSize");
  
    // if (this.appWrapperSize.width <= this.appWrapperSize.height && this.appWrapperSize.width < 720) {
    //   // portrait mobile
    //   this.screenRotation = 0
      
    //   // width = Math.min(this.appWrapperSize.height / 8.7, this.appWrapperSize.width / 4.6)
    // }
    // else {
    //   // landscape
    //   this.screenRotation = 0
    //   // width = Math.min(this.appWrapperSize.width / 8.7, this.appWrapperSize.height / 4.6)

    // } 
    
    // height = width * 1.3
    // this.WIDTH = width
    // this.HEIGHT = height
    // this.cardWidth = width

    this.displayModeService.expanded
    let defaultHeight = 75
    let defaultWidth = 60

    let width = (this.displayModeService.expanded * defaultWidth)
    let height = (this.displayModeService.expanded * defaultHeight)
    this.WIDTH = width
    this.HEIGHT = height

    return {
      width: width,
      height: height
    }
  }


  // table_image = {width: 498, height: 254}
  // table_factor = this.table_image.width / (window.innerWidth*0.4 )
  // table_height = this.table_image.height / this.table_factor
  // tableSize = {width: Math.max(200, window.innerWidth*0.32), height: Math.max(95, this.table_height * 0.75)}

  moveToCenter() {
    // console.log("moveToCenter");
    // console.log(this.appWrapperSize);
    // console.log(this.screenRotation);
    

    // console.log("tablePosition:", this.tablePosition);


    let center_y = (this.tablePosition.height) / 2    
    let center_x = (this.tablePosition.width) / 2   

    if (this.appWrapperSize.width > this.appWrapperSize.height || this.appWrapperSize.width > 720) {
      // console.log("landscape");
          
      return {
        top: center_y - this.HEIGHT/2,
        left: center_x - this.WIDTH/2
      }
    }

    // console.log("portrait");
    return {
      left: center_y - this.WIDTH/2,
      top: center_x - this.HEIGHT/2 
    }
  }

  // rotate(point: any, rotation: number) {
  //   let tmp_x = point.left;
  //   let tmp_y = point.top;

  //   let x = tmp_x * Math.cos(rotation) - tmp_y * Math.sin(rotation)
  //   let y = tmp_y * Math.cos(rotation) + tmp_x * Math.sin(rotation)

  //   return {
  //     left: x,
  //     top: y,
  //   }
  // }

  screenRotation!: number
  setScreenRotation() {
    console.log("setScreenRotation");
    
    if (this.appWrapperSize.width > this.appWrapperSize.height || this.appWrapperSize.width > 720) {
      //landscape mobile and descktop
      this.screenRotation = 0
      return 
    } 
    this.screenRotation = 1
  }



  generateDeckPosition(): Position {
    let top_rigth = this.moveToCenter();
    let rotation = this.getRandomNumber(0,180);
    let random_top_effect = this.getRandomNumber(this.tablePosition.height * -0.05, this.tablePosition.height * 0.05);
    let random_left_effect = this.getRandomNumber(this.tablePosition.width * -0.2 , this.tablePosition.width * 0.2); 
    // let top = top_rigth.top + random_top_effect;
    // let left = top_rigth.left + random_left_effect;

    return {
      rotation: rotation,
      left: top_rigth.left + random_left_effect,
      top: top_rigth.top + random_top_effect
    }
  }



  getRandomNumber(min: number, max: number) {
    let rnd = Math.floor(Math.random() * (max - min + 1) + min);
    return rnd
  }
}
