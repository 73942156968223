<div class="cards_opponent"
   
    [ngClass]="{'down': canRenderCards()}"
    #opponent_cards_ref
    [@opponent_animations]="handleOpponntAnimations()"
    (@opponent_animations.start)="opponentAnimationStart($event)"
    (@opponent_animations.done)="opponentAnimationStop($event)">


    <ng-container *ngIf="(canRenderCards() && canRenderCardsPlaceholders());  else placeholders">
        <app-card 
            *ngFor="let card of opponentCards; last as isLast; let indexOfelement = index;"
            [card]="card"
            [ngStyle]="calculateStyle(isLast)"
            (click)="opponentPlaysCard()">
        </app-card>
    </ng-container>

    <ng-template #placeholders >
        <ng-container *ngIf="canRenderCardsPlaceholders()">
            <app-card 
            class="placeholder"
            *ngFor="let card of opponentCardsPlaceholder; last as isLast; let indexOfelement = index;"
            [card]="getCard(indexOfelement)"
            [ngClass]="{'last': isLast, 'played': isPlayed(indexOfelement)}"
            [ngStyle]="calculateStyle(isLast)"
            (click)="opponentPlaysCard()">
         </app-card>
        </ng-container>
    </ng-template>
        

    
</div>