import { animate, animation, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const opponentPlayCard = animation([
    group([
        query("app-card.played", [
            animate(
                '2s linear',
                keyframes([
                    style({ left: '{{from_x}}', top: '{{from_y}}', offset: 0 }),
                    style({ left: '{{from_x}}', top: '{{from_y}}', transform: 'rotate(0deg)', offset: 0.6 }),
                    style({ left: '{{from_x}}', top: '{{from_y_2}}', transform: 'rotate(0deg)', offset: 0.7 }),
                    style({ left: '{{to_x}}', top: '{{to_y}}', transform: 'rotate({{to_rotation}}deg)', offset: 1 }),
                ])
            )
        ], {optional: true}),
        query("app-card.played .flip-front", [
            animate(
                '2s linear',
                keyframes([
                    style({ transform: 'rotateY(180deg)', offset: 0}),
                    style({ transform: 'rotateY(0deg)', offset: 0.5 }),
                    style({ transform: 'rotateY(0deg)', offset: 0.6 }),
                ])
            )
        ], {optional: true}),
        query("app-card.played .flip-back", [
            animate(
                '2s linear',
                keyframes([
                    style({ transform: 'rotate(180deg) rotateY(0deg)', offset: 0}),
                    style({ transform: 'rotate(180deg) rotateY(180deg)', offset: 0.5}),
                    style({ transform: 'rotate(180deg) rotateY(180deg)', offset: 0.6 }),
                    
                ])
            )
        ], {optional: true}),
    ])
])