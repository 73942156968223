<app-header></app-header>
<app-subheader></app-subheader>

<div class="inner_wrapper">
    <h1>Statistics</h1>
    <form action="">
        <div class="selections_wrapper">
            <div class="column">
                <h2 class="selection_title">Opponent: </h2>
                <app-select
                class="opponents"
                [type]="'opponents'"
                (selectedEmmiter)="selectOpponent($event)"
                [selectedIndex]="selectedOpponentName"
                [selected]="selectedOpponentShort()"
                [list]="opponents">
                
                    <div class="specific_opp_option" *ngIf="(selectedOpponentName[0] !== 0)">
                        <div> 
                            <input type="text" [formControl]="specificOpponentName"/> 
                            <button [ngClass]="{inactive: specificOpponentButtonInactive()}" class="confirm" (click)="clickSpecificOpponent()">CONFIRM</button>
                        </div>
                        <div class="mostCommonOpponents" >
                            <div class="opp_list" *ngFor="let commonOpponent of mostCommonOpponents; index as index">                        
                                <label class="opp_list_option" *ngIf="(index < 5)">
                                    {{getLabel(commonOpponent.label)}}
                                    <input (change)="clickMostCommonOpponent($event.target)" type="radio" name="{{commonOpponent.name}}" value="{{commonOpponent.value}}"/>
                                    <span class="checkmark_circle"></span>
                                </label>        
                            </div>
                        </div>
                    </div>
                </app-select>
            </div>
            <div class="column">
                <h2 class="selection_title">Dates: </h2>
                <app-select
                class="dates"
                [type]="'dates'"
                (selectedEmmiter)="selectedDate($event)"
                [selectedIndex]="selectedDateIndex"
                [selected]="selectedDateShort()"
                [list]="dates"></app-select>
            </div>
            
        </div>

    </form>

    <div class="statistics_wrapper">
           <div *ngFor="let item of userStatistics; index as elementIndex" class="box-outer-wrapper">
                
                <div class="box-wrapper">
                    <div class="bar_chart">
                        <div class="win_bar" [ngStyle]="winBarStyle(item.win, item.lost)">
                            <span [ngStyle]="winBarNumberStyle(item.win, item.lost)">{{item.win}}</span>
                        </div>
                        <div class="lost_bar" [ngStyle]="lostBarStyle(item.lost, item.win)">
                            <span [ngStyle]="lostBarNumberStyle(item.lost, item.win)">{{item.lost}}</span>
                        </div>
                    </div>
                    <div class="bar_buy_ins">
                        {{item.buyIn}}
                    </div>
                </div>
           </div>
    </div>
</div>

<app-footer></app-footer>

<app-general-modal *ngIf="statisticsFilterError === 'incorrectOpponentName'">
    <header>Nickname doesn't not exist</header>
    <content>Please, check USERNAME you typed in</content>
    <footer>
        <button (click)="closeErrorModal()">OK</button>
    </footer>
</app-general-modal>
