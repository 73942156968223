<div
    #selected_suit
    [@selected_suit]="handleAnimation()"
    (@selected_suit.start)="handleAnimationStart($event)"
    (@selected_suit.done)="handleAnimationDone($event)"
    class="selected_suit" 
    [ngStyle]="slectedSuitSize()"
    *ngIf="selectedSuit !== null">
    <div class="icon" [ngClass]="calculateClass()">
    </div>
</div>