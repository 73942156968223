<div class="row">
    
    <app-modal-game-variant></app-modal-game-variant>

    <div class="row buy-in">
        <div class="row">
            <div class="text-left">Buy-in</div>
            <div class="text-right">{{renderThousands(buyIn)}} UGX</div>
        </div>
        <div class="row m-t-4">
            <span class="text-left">Possible win</span>
            <span class="text-right">{{renderThousands(possibleWin)}} UGX</span>
        </div>
        <div class="row fee m-t-6" *ngIf="fee">
            (fee {{renderThousands(fee)}} UGX for each applicable)
        </div>  
    </div>
    
</div>

<div class="row invited-opponent m-t-10" *ngIf="gameVariant.type === 'FRIEND' && !rematch">
    <div class="text-left">You play agains:</div>
    <div class="text-right">{{opponent}}</div>
</div>

<div class="row free-game m-t-10" *ngIf="buyIn != null && buyIn == 0 && !rematch">
    Enjoy your PLAY FOR FUN!
</div>

<div class="row balance m-t-10" *ngIf="buyIn != null && buyIn > 0">
    <div class="row">
        <div class="text-left">Balance available:</div>
        <div class="text-right">{{renderThousands(userBalance)}} UGX</div>
    </div>
    <div class="row m-t-4">
        <div class="text-left">Will be debited:</div>
        <div class="text-right">{{renderThousands(buyIn)}} UGX   </div>
    </div>
</div>
