<img class="menu-icon-open" src="{{menu_icon}}" alt="" (click)="handleToggleMenu()"/>

<div class="menu_wrapper" [ngClass]="{'active': openMenu}">
    <div class="menu-header">
        <i class="menu-icon-close" (click)="handleToggleMenu()"></i>
        <span>Play matatu</span> 
    </div>
    <ng-container *ngFor="let item of links; index as elementIndex">
        <div class="horizontal-line" *ngIf="getHorizontalLine(elementIndex) === true" ></div>
        <a href="{{item.url}}">
            <div class="menu-link">
                <span class="icon" [ngClass]="getItemIcon(elementIndex)"></span>
                <span class="label">{{item.label}}</span>
            </div>
        </a>
        
    </ng-container>
    
</div>