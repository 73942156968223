import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayModeService } from 'src/app/services/display-mode.service';

@Component({
  selector: 'app-game-replay-opponent',
  templateUrl: './game-replay-opponent.component.html',
  styleUrls: ['./game-replay-opponent.component.scss']
})
export class GameReplayOpponentComponent implements OnInit {

  opponentName: string = "opponentName"

  constructor(private router: Router,
    private displayModeServices: DisplayModeService) { }

  ngOnInit(): void {
  }

  handleCloseClick() {
    this.router.navigate(['/my-games'])
  }

  barWrapperSize() {
    let defaultHeight = 40
    let height = (this.displayModeServices.expanded * defaultHeight)
    return {
      height: height + 'px'
    }
  }
  replaySize() {
    
    // let width = 96 * this.displayModeServices.expanded
    let height = 34 * this.displayModeServices.expanded
  
    return {
      // width: width + 'px',
      height: height + 'px'
    }
  }
  
  opponentBarPosition() {
    let defaultWidthControls = 143 * this.displayModeServices.expanded
    let size = (315 * this.displayModeServices.expanded) 

    let left = Math.max((this.displayModeServices.appWrapperSize.width - size) / 2, defaultWidthControls)

    
    return {
      left: left + 'px'
    }

  }
}
