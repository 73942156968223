<app-player-cards
    [player]="0"
    [ngClass]="{player: true}"
    [replay]="true">
</app-player-cards>

<div class="player-bar-wrapper" [ngStyle]="barWrapperSize()">
    <app-number-of-cards
        [player]="0"
        [replay]="true">
    </app-number-of-cards>
    <app-player-bar
        [player]="0"
        [name]="player_name"
        [ngClass]="{player: true}"
        [replay]="true">
    </app-player-bar>
</div>
