import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GameStatus } from 'src/app/models/game.model';
import { BuyInValue } from 'src/app/models/lobby.model';
import { AppState } from 'src/app/state/app.state';
import { selectGameReplayBuyIn } from 'src/app/state/selectors/game-replay.selector';
import { isPlayerTurn, selectGameBuyIn, selectGameStatus } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-opponent-bar',
  templateUrl: './opponent-bar.component.html',
  styleUrls: ['./opponent-bar.component.scss']
})
export class OpponentBarComponent implements OnInit {

  @Input() player!: number
  @Input() name!: string
  @Input() replay: boolean = false
  
  isPlayerTurn!: boolean 
  isPlayerTurn$ = this.store.pipe(
    select(state => isPlayerTurn(state.game, this.player))
  )

  gameBuyIn!: BuyInValue
  gameBuyIn$ = this.store.pipe(
    select(state => selectGameBuyIn(state.game))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )

  gameReplayBuyIn!: BuyInValue
  gameReplayBuyIn$ = this.store.pipe(
    select(state => selectGameReplayBuyIn(state.gameReplay))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    
    this.cutOpponentName()
    
    if (this.replay === true) {
      this.subscribeGameReplayBuyIn();
      return 
    }
    
    this.subscribeIsPlayerTurn()
    this.subscribeGameBuyIn()
    this.subscribeGameStatus()
  }

  subscribeGameReplayBuyIn() {
    this.gameReplayBuyIn$.subscribe(value => {
      this.gameReplayBuyIn = value
    })
  }

  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }

  subscribeGameBuyIn() {
    this.gameBuyIn$.subscribe(value => {
      this.gameBuyIn = value
    })
  }
  subscribeIsPlayerTurn() {    
    this.isPlayerTurn$.subscribe(value => {
      this.isPlayerTurn = value
    })
  }
  cutOpponentName() {
    if (this.name.length > 15) {
      this.name = this.name.slice(0, 15) + "..."
    }
  }
  transformBuyInText() {
    
    let buyIn =  (this.replay) ? (this.gameReplayBuyIn) : (this.gameBuyIn)

    if (buyIn === undefined) {
      return "UGX"
    }

    if (buyIn === 0) {
      return "FREE GAME"
    }

    return buyIn + " UGX"
  }

  //TODO: add conditions for game replay state

  canRenderPlayerArrow() {

    if (this.replay === true) {
      return true
    }

    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }

    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    return true
  }

  canRenderOpponentDetail() {
    if (this.replay === true) {
      return true
    }

    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }

    return true
  }

  canRenderTimerBar() {

    if (this.replay === true) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }
    
    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_cutter) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_table_info) {
      return false
    }  
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }

    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    

    return true
  }

  canRenderPlayerTimerDial() {

    if (this.replay === true) {
      return true
    }

    if (this.gameStatus === GameStatus.waiting_for_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_invited_opponent) {
      return false
    }

    if (this.gameStatus === GameStatus.waiting_for_match_start) {
      return false
    }

    if (this.gameStatus === GameStatus.match_started_select_turn) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_cutter) {
      return false
    }
    if (this.gameStatus === GameStatus.match_started_table_info) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    return true
  }


}
