import { animate, animation, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const draw2Cards = animation([
    group([
        query(":self", [  
          animate(
            '2s linear',
            keyframes([
              style({ left: 'calc(50% + {{factor_x}})', offset: 0 }),
              style({ left: 'calc(50% + {{factor_x}})', offset: 0.25 }),
              style({ left: '50%', offset: 1 })
              // style({ left: 'calc(50% + {{factor_x}})', offset: 0 }),
              // style({offset: 0.25 }),
              // style({offset: 1 })
            ])
          )
        ], {optional: true}),
        query("app-card:not(.last):enter .flip-front", [
          animate(  
            '2s linear',
            keyframes([
              style({ left: '{{from_x_pos}}', bottom: '{{from_y_pos}}', transform: 'rotateY(180deg)', zIndex: 1, offset: 0}),
              style({ left: '{{from_x_pos}}', bottom: '{{from_y_pos_2}}', transform: 'rotateY(90deg)', zIndex: 1, offset: 0.25}),
              style({ left: '0', bottom: '0', transform: 'rotateY(0deg)', zIndex: 1, offset: 1}),
            ])
          )
        ], {optional: true}),
        query("app-card:not(.last):enter .flip-back", [
          animate(  
            '2s linear',
            keyframes([
              style({ left: '{{from_x_pos}}', bottom: '{{from_y_pos}}', transform: 'rotate(180deg) rotateY(0deg)', zIndex: 1, offset: 0}),
              style({ left: '{{from_x_pos}}', bottom: '{{from_y_pos_2}}', transform: 'rotate(180deg) rotateY(90deg)', zIndex: 1, offset: 0.25}),
              style({ left: '0', bottom: '0', transform: 'rotate(180deg) rotateY(180deg)', zIndex: 1, offset: 1}),
            ])
          )
        ], {optional: true}),
        query("app-card.last:enter .flip-front", [
          animate(
            '2s linear',
            keyframes([
              style({ visibility: 'hidden', left: '{{from_x_pos_2}}', bottom: '{{from_y_pos}}', transform: 'rotateY(180deg)', offset: 0}),
              style({ visibility: 'hidden', left: '{{from_x_pos_2}}', bottom: '{{from_y_pos}}', transform: 'rotateY(180deg)', offset: 0.25}),
              style({ visibility: 'visible', left: '{{from_x_pos_2}}', bottom: '{{from_y_pos_2}}', transform: 'rotateY(90deg)', offset: 0.5}),
              style({ left: '0', bottom: '0', transform: 'rotateY(0deg)', offset: 1}),
              
            ])
          )
        ], {optional: true}),
        query("app-card.last:enter .flip-back", [
          animate(
            '2s linear',
            keyframes([
              style({ visibility: 'hidden', left: '{{from_x_pos_2}}', bottom: '{{from_y_pos}}', transform: 'rotate(180deg) rotateY(0deg)', offset: 0}),
              style({ visibility: 'hidden', left: '{{from_x_pos_2}}', bottom: '{{from_y_pos}}', transform: 'rotate(180deg) rotateY(0deg)', offset: 0.25}),
              style({ visibility: 'visible', left: '{{from_x_pos_2}}', bottom: '{{from_y_pos_2}}', transform: 'rotate(180deg) rotateY(90deg)', offset: 0.5}),
              style({ left: '0', bottom: '0', transform: 'rotate(180deg) rotateY(180deg)', offset: 1}),
            ])
          )
        ], {optional: true}),
      ])
])
