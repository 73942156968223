import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { MyGamesComponent } from './my-games/my-games.component';
import { StatisticsComponent } from './statistics/statistics.component';

const routes: Routes = [
  { path: 'stats', component: StatisticsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'my-games', component: MyGamesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
