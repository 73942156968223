<ng-container *ngIf="canRenderLeftControls() && !replay">
    <div class="button-wrapper"
        [ngStyle]="buttonPosition()"
         *ngIf="displayControls === GameControlsLeft.switch_turn">
        <button
            (click)="handleSwitchButton()"
            class="button"
            [ngClass]="{'glow': glowingStates}">
            Switch turn
        </button>
    </div>

    <app-ace-switch 
    [ngStyle]="aceButtonPosition()"
    *ngIf="displayControls === GameControlsLeft.ace_switch">
        
    </app-ace-switch> 

    <app-seven-of-cutter 
        [ngStyle]="aceButtonPosition()"
        *ngIf="displayControls === GameControlsLeft.sevent_of_cutter">

    </app-seven-of-cutter>
</ng-container>

<ng-container *ngIf="replay">
    <!-- <app-number-of-cards class="opponent" [player]="1"></app-number-of-cards> -->
    <div class="controlsWrapper">
        <div class="replay-controls" [ngStyle]="replayControlsSize()">
            <div class="row">
                <button class="play-left" [ngClass]="{inactive: !play_left_active}">
                    <i class="vertical-line left"></i>
                    <i class="triangle-left"></i>
                </button>
                <button class="replay_play_pause" [ngSwitch]="play_pause_replay" [ngClass]="{inactive: !replay_play_pause_active}">
    
                    <ng-container *ngSwitchCase="'replay'" >
                        <i class="small-triangle"></i>
                        <i class="tree-quarters-circle"></i>
                    </ng-container>
                        
                    <ng-container *ngSwitchCase="'pause'">
                        <i class="vertical-line pause"></i>
                        <i class="vertical-line pause"></i>
                    </ng-container>
    
                    <ng-container *ngSwitchDefault>
                        <i class="triangle-right play"></i>
                    </ng-container>
                        
                
                </button>
                <button class="play-right" [ngClass]="{inactive: !play_right_active}">
                    <i class="triangle-right"></i>
                    <i class="vertical-line right"></i>
                </button>  
            </div>
            <div class="row">
                <button class="step-left" [ngClass]="{inactive: !step_left_active}">
                    <i class="triangle-left bigger"></i>
                    <i class="square left"></i>
                </button>
                <button class="step-right" [ngClass]="{inactive: !step_right_active}">
                    <i class="square right"></i>
                    <i class="triangle-right bigger"></i>
                </button>
            </div>
        </div>
    </div>

</ng-container>