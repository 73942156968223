import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerItems = [
    {
      name: "Home",
      icon: "assets/images/svg/home.svg",
      classes: "home",
    },
    {
      name: "Odds",
      icon: "assets/images/svg/1-x-2.png",
      classes: "odds",
    },
    {
      name: "Ticket",
      icon: "assets/images/svg/ikona-ticket-b-l.svg",
      classes: "ticket",
    },
    {
      name: "Live",
      icon: "assets/images/svg/live.svg",
      classes: "live",
    },
    {
      name: "Virtuals",
      icon: "assets/images/svg/virtuals.svg",
      classes: "virtuals"
    }
  ]
  
  constructor() { }

  ngOnInit(): void {
  }

}
