import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as signalR from "@microsoft/signalr";
//import * as signalRMsgPack from "@microsoft/signalr-protocol-msgpack";

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubConnection: signalR.HubConnection
  constructor(private route: ActivatedRoute) { 
    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl('http://192.168.1.170:5000/hubs/v1/matatu')
    //.withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
    .configureLogging(signalR.LogLevel.Information)
    .build();

    this.hubConnection.onclose(async () => {
      await this.startConnection();
    });

    this.hubConnection.on('StartGameRecieve', this.startGameRecieve);
    this.hubConnection.on("HelloWordAction", this.helloWorlAction)
    this.startConnection();
  }

  helloWorlAction(user: string, message: string) {
    console.log(user);
    console.log(message);
  }

  private async startConnection(){
    try {
      await this.hubConnection.start();
      console.log("Connection started");
    } catch (err) {
        console.log('Error while starting connection: ' + err);
        setTimeout(() => this.startConnection(), 5000);
    }
  }

  private startGameRecieve(data: Array<string>) {

  }

  public startGame(bet: number){
    this.hubConnection.send("startGame", bet).catch(err => console.error(err));    
  }

}
