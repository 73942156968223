import { createSelector } from '@ngrx/store';
import { gameVariants } from 'src/app/data/game-variants.data';
import { BuyInType, GameType, GameVariant, Lobby, StartGameErrors } from 'src/app/models/lobby.model';



export const selectBuyInType = (state: Lobby) => state.buyInType;
export const selectGameType = (state: Lobby) => state.gameType;
export const opponent = (state: Lobby) => state.opponent;
export const selectInvitation = (state: Lobby) => state.invitation;

export const opponentNameExist = createSelector(
    opponent,
    (opponent) => {
        return  opponent.exist
    }
)
export const invitation = createSelector(
    selectInvitation,
    (invitation) => {
        return invitation
    }
)

export const activeGameVariant = createSelector(
    selectGameType,
    (gameType: GameType | null) => {

        if(gameType == null) {
            return;
        }   
        return gameVariants.get(gameType);
    }
);
export const activeGameType = createSelector(
    selectGameType,
    (gameType) => {
        return gameType
    }
)
export const isActiveGameVariant = createSelector(
    selectGameType,
    (gameType: GameType | null, props: any) => {
        if (gameType === props.type) {
            return true
        }
        return false
    }
);

export const activeBuyInType = createSelector(
    selectBuyInType,
    (buyIn) => {
        return buyIn
    }
)
export const isSelectedBuyIn = createSelector(
    selectBuyInType,
    (buyIn) => {
        if (buyIn !== null) {
            return true
        }
        return false
    }
);
export const isActiveBuyInType = createSelector(
    selectBuyInType,
    (buyInType: BuyInType, props: any) => {
        if (buyInType === props.buyIn) {
            return true
        }
        return false
    }
)

export const selectOpponent = createSelector(
    opponent,
    (opponent) => {
        return opponent
    }
)

/**
 * return fee for one user
*/
export const feeForUser = createSelector(
    selectBuyInType,
    (buyIn) => {
        if (buyIn == null || buyIn == 0) {
            return null
        }
        var fee = buyIn/100;
        return (fee > 50) ? fee : 50;
    }
)

/**
 * return possible win for user without fee
 */
export const possibleUserWin = createSelector(
    selectBuyInType,
    feeForUser,
    (buyIn, fee) => {

        if (buyIn == 0) {
            return 0;
        }

        if (buyIn == null || fee == null) {
            return null
        }
        
        var win = buyIn * 2 - (fee * 2);
        return win;
    }
)


//TODO: add check for available user balance 
export const canStartTheGame = createSelector(
    selectBuyInType,
    selectGameType,
    opponent,
    (buyInType: BuyInType, gameType: GameType, opponent) => {
        
        if (buyInType === null) {
            return StartGameErrors.notBuyIn
        }
        
        if (gameType === GameType.FRIEND && opponent.name == '') {
            return StartGameErrors.incorrectOpponentName
        }

        if (gameType === GameType.FRIEND && opponent.name != '' && !opponent.available) {
            //return StartGameErrors.none
            return StartGameErrors.notOpponentAvailable
        }
        

        if (buyInType != null && gameType != null) {
            return StartGameErrors.none
        }


        return StartGameErrors.none
    }
)