import { animate, keyframes, query, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CardSuit } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { AppState } from 'src/app/state/app.state';
import { selectAceSwitchPosition, selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectSelectedSuit } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-selected-suit',
  templateUrl: './selected-suit.component.html',
  styleUrls: ['./selected-suit.component.scss'],
  animations: [
    trigger(
      'selected_suit', [
        transition('void => selected_suit', [
          query(':self', [
            animate('1s linear', keyframes([
              style({ transform: 'translate(-30%, -15%) scale(0)', offset: 0 }),
              style({ transform: 'translate(-30%, -15%) scale(1)', offset: 1 })
            ]))
          ]) 
        ]),
        transition('* => void', [
          query(':self', [
            animate('1s linear', keyframes([
              style({ transform: 'translate(-30%, -15%) scale(1)', offset: 0 }),
              style({ transform: 'translate(-30%, -15%) scale(0)', offset: 1 })
            ]))
          ])
        ])
      ]
    )
  ]
})
export class SelectedSuitComponent implements OnInit {

  // @ViewChild('selected_suit', {static: false, read: ElementRef}) selected_suit!: ElementRef
  @Input() replay: boolean = false

  selectedSuit: CardSuit | null = null
  selectedSuit$ = this.store.pipe(
    select(state => selectSelectedSuit(state.game))
  )
  
  aceSwitchPosition!: {left: number, top: number} 
  aceSwitchPosition$ = this.store.pipe(
    select(state => selectAceSwitchPosition(state.displayMode))
  )

    
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  constructor(private el: ElementRef, private store: Store<AppState>,
    private display: DisplayModeService) { 
    
  }

  ngOnInit(): void {
    this.subscribeAppWrapperSize()

    this.selectedSuit$.subscribe(value => {
      this.selectedSuit = value
      // console.log(this.selectedSuit);
      if (this.selectedSuit !== null) {
        this.animationState = 'selected_suit'
      
        //TODO: remove (testing animation for selected suit)
        // setTimeout(() => {
        //   this.selectedSuit = null
        // }, 4000)

      } 

    })
    this.subscribeAceSwitchPosition()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }
  
  selectedSuitPosition!: {left: number, top: number}
  ngAfterViewInit() {
    
    this.setScreenRotation()

    let selectedSuitRect = this.el?.nativeElement.getBoundingClientRect();
    this.selectedSuitPosition = {
      left: selectedSuitRect?.left,
      top: selectedSuitRect?.top
    }
    // console.log(this.el.nativeElement.getBoundingClientRect());

  }


  screenRotation: number = 0  
  size: number = 59
  ngAfterViewChecked() {

    this.setScreenRotation()

    let selectedSuitRect = this.el?.nativeElement.getBoundingClientRect();
    this.selectedSuitPosition = {
      left: selectedSuitRect?.left,
      top: selectedSuitRect?.top
    }

    this.size = 0.1135 * screen.availWidth
    // console.log(this.selectedSuitPosition);
  }

  setScreenRotation() {
    // this.screenRotation = (screen.availWidth > screen.availHeight) ? 0 : 1
    if (screen.availWidth > screen.availHeight || screen.availWidth > 720) {
      this.screenRotation = 0
      return 
    } 
    this.screenRotation = 1
  }

  subscribeAceSwitchPosition() {
    this.aceSwitchPosition$.subscribe(value => {
      this.aceSwitchPosition = value
      // console.log(this.aceSwitchPosition);
    })
  }

  animationState: any 
  runningAnimation: boolean = false
  
  animationLandscape() {

    let from_x = (this.selectedSuitPosition.left - this.aceSwitchPosition.left) * -1
    let from_y = (this.selectedSuitPosition.top - this.aceSwitchPosition.top) * -1
    // console.log(from_x);
    let borderRadius: number[] = [0,0,0,0]
    switch (this.selectedSuit) {
      case CardSuit.heart: {

        break;
      }
      case CardSuit.clover: {
        borderRadius = [0,9,0,0];
        from_x += this.size;
        break;
      }
      case CardSuit.tile: {
        borderRadius = [0,0,9,0]
        from_x += this.size;
        from_y += this.size;
        break;
      }
      case CardSuit.pike: {
        from_y += this.size;
        break;
      }
      default:
        break;
    }

    return {
      value: this.animationState,
      params: {
        from_x: from_x,
        from_y: from_y,
        radius_0: borderRadius[0],
        radius_1: borderRadius[1],
        radius_2: borderRadius[2],
        radius_3: borderRadius[3]
      }
    }
  }
  animationPortrait() {

    // console.log('ANIMATE PORTRAIT SUIT SELECTED');
    
    // console.log(this.selectedSuitPosition);
    // console.log(this.aceSwitchPosition);
    

    let from_x = this.selectedSuitPosition.top - window.innerHeight;
    let from_y = this.aceSwitchPosition.left - this.selectedSuitPosition.left;
    let borderRadius: number[]= [0, 0, 0, 0]

    switch (this.selectedSuit) {
      case CardSuit.heart: {
        break;
      }
      case CardSuit.clover: {
        borderRadius = [0,9,0,0];
        from_x += this.size;
        break;
      }
      case CardSuit.tile: {
        borderRadius = [0,0,9,0]
        from_x += this.size;
        from_y += this.size;
        break;
      }
      case CardSuit.pike: {
        from_y += this.size;
        break;
      }
      default:
        break;
    }

    return {
      value: this.animationState,
      params: {
        from_x: from_x,
        from_y: from_y,
        radius_0: borderRadius[0],
        radius_1: borderRadius[1],
        radius_2: borderRadius[2],
        radius_3: borderRadius[3]
      }
    }
  }

  handleAnimation() { 
    // return {}
    // console.log('HANDLE ANIMATION');
    
    return this.animationState
    // if (this.screenRotation === 1) {
    //   return this.animationPortrait()
    // }

    // return this.animationLandscape()
    

  }
  handleAnimationStart($event: any) {
    // console.log($event);
  }
  handleAnimationDone($event: any) {
    // console.log($event);
    
    if ($event.toState === 'selected_suit') {
    }

    this.animationState = '*'
    this.runningAnimation = false

  }

  calculateClass() {
    if (this.selectedSuit === null) {
      return ""
    }

    return this.selectedSuit
  }

  slectedSuitSize() {
    let defaultSize = 44
    let size = this.display.expanded * defaultSize

    let defaultWidth = 249
    let width = (this.display.expanded * defaultWidth)
    
  

    if (this.display.rotation == 1) {
      let empty = (this.appWrapperSize.height * -0.48 + width) / -2
      let left = empty
      return {
        width: size + 'px',
        height: size + 'px',
        left: left + 'px'

      }
    }

    let empty = (this.appWrapperSize.width * -0.48 + width) / -2
    let left = empty
    return {
      width: size + 'px',
      height: size + 'px',
      left: left + 'px'
    }
  }
}
