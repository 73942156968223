import { BuyIn } from './../../../../models/lobby.model';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import * as LobbyActions from 'src/app/state/actions/lobby.actions'
import { isActiveBuyInType } from 'src/app/state/selectors/lobby.selector';
import { isPortraitMode } from 'src/app/state/selectors/display-mode.selector';


@Component({
  selector: 'app-buy-in',
  templateUrl: './buy-in.component.html',
  styleUrls: ['./buy-in.component.scss']
})
export class BuyInComponent implements OnInit {

  @Input() buyInType: BuyIn = {
    name: '',
    type: null,
    classes: []
  };
  
  isActive!: boolean
  isActive$ = this.store
    .pipe(select(state => isActiveBuyInType(state.lobby, {buyIn: this.buyInType.type})))

  constructor(private store: Store<AppState>) { }

  // isPortrait!: boolean
  // isPortrait$ = this.store.pipe(select(state => isPortraitMode(state.displayMode)));

  ngOnInit(): void {
    this.subscribeLobbyBuyIn()
    // this.subscribePortraitMode()
    
  }

  subscribeLobbyBuyIn() {
    this.isActive$.subscribe(value => {
      this.isActive = value      
    });
  }
  // subscribePortraitMode() {
  //   this.isPortrait$.subscribe(value => {
  //     this.isPortrait = value
  //   })
  // }

  selectBuyIn(): void {
    this.store.dispatch(LobbyActions.selectBuyInType({buyInType: this.buyInType.type}));
  }
  calculateClass() {    
    
    if (this.isActive) {
      return "active"
    }
    return ""
  }

}
