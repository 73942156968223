import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-give-up-modal',
  templateUrl: './give-up-modal.component.html',
  styleUrls: ['./give-up-modal.component.scss']
})
export class GiveUpModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
