import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { AppState } from '../state/app.state';
import { selectAppWrapperSize } from '../state/selectors/display-mode.selector';

@Injectable({
  providedIn: 'root'
})
export class DisplayModeService {

  resize$: Observable<Event> = fromEvent(window, 'resize')
  
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )
  
  defaultWidth: number = 522
  defaultHeight: number = 296
  
  // percentual expansion
  expanded: number = 1
  rotation: number = 1


  constructor(private store:Store<AppState>) {  
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value   
      this.calculateExpansion()
      this.setRotation()
    })    
  }


  setRotation() {
    if (this.appWrapperSize.width > this.appWrapperSize.height || this.appWrapperSize.width > 720) {
      this.rotation = 0
      return 
    } 
    this.rotation = 1
  }
  calculateExpansion() {
    let width = this.appWrapperSize.width / this.defaultWidth
    let height = this.appWrapperSize.height / this.defaultHeight

    this.expanded = Math.max(Math.min(width, height), 1)
  }



}
