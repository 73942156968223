import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BuyInType, GameVariant } from 'src/app/models/lobby.model';
import { selectBuyInType } from 'src/app/state/actions/lobby.actions';
import { AppState } from 'src/app/state/app.state';
import { activeBuyInType, activeGameVariant } from 'src/app/state/selectors/lobby.selector';

@Component({
  selector: 'app-modal-game-variant',
  templateUrl: './modal-game-variant.component.html',
  styleUrls: ['./modal-game-variant.component.scss']
})
export class ModalGameVariantComponent implements OnInit {

  buyInType!: BuyInType
  buyInType$ = this.store.pipe(
    select(state => activeBuyInType(state.lobby))
  )

  gameVariant!: GameVariant 
  gameVariant$ = this.store.pipe(
    select(state => activeGameVariant(state.lobby))
  )

  constructor(private store:Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeGameVariant()
    this.subscribeBuyInType()
  }
  subscribeBuyInType() {
    this.buyInType$.subscribe(value => {
      //console.log(value);
      
      this.buyInType = value;
    })
  }
  subscribeGameVariant() {
    this.gameVariant$.subscribe(value => {
      //console.log(value);
      if (value == undefined) {
        return;
      }
      this.gameVariant = value
    })
  }

}
