import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() list!: any[]
  @Input() type!: string
  @Input() selectedIndex!: any[]
  @Input() selected!: any[]
  @Output() selectedEmmiter = new EventEmitter<number>();
  @Output() selectedNameEmmiter = new EventEmitter<string>();

  constructor(private element: ElementRef, private renderer: Renderer2) {
    // console.log("CONSTRUCTOR");

    this.renderer.listen('window', 'click', (e: any) => {      
      if (!(this.element.nativeElement.classList.value.indexOf(this.type) !== -1 && this.element.nativeElement.contains(e.target))) {
        this.openSelectOptions = false
      }
     })
  }

  ngOnInit(): void {
  }

  openSelectOptions: boolean = false
  toggle() {
    this.openSelectOptions = !this.openSelectOptions
  }

  requiredOne(index: number, $event: Event) {
    //at least first element from list is required
    if (this.selectedIndex.includes(0) && index === 0) {
      $event.preventDefault()
    }
  }
  select(index: number, $event: Event) {
    // $event.preventDefault()
    this.selectedEmmiter.emit(index);
  }

  selectedOptions() {
    let result = ""
    this.selected.forEach((value, index) => {
      if (index !== 0) {
       result += ", " 
      }
      result += value
    })
    return result
  }

  isItemSelected(index: number) {    
    return this.selectedIndex.includes(index)
  }

}
