import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, State, Store } from '@ngrx/store';
import { Game, GameStatus } from 'src/app/models/game.model';
import { BuyInValue } from 'src/app/models/lobby.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { switchGameStatus } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { isPortraitMode, selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectGameBuyIn, selectGameStatus, selectPlayerOnTurn } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  isPortrait!: boolean
  isPortrait$ = this.store.pipe( 
    select(state => isPortraitMode(state.displayMode))
  )

  playerTurn!: number
  playerTurn$ = this.store.pipe(
    select(state => selectPlayerOnTurn(state.game))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  
  gameBuyIn!: BuyInValue
  gameBuyIn$ = this.store.pipe(
    select(state => selectGameBuyIn(state.game))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )
  


  constructor(private store: Store<AppState>, private displayModeServices: DisplayModeService, private router: Router) { }

  ngOnInit(): void {
    // this.subscribePortraitMode()
    this.subscribeAppWrapperSize()

    this.subscribeGameStatus()
    this.subscribePlayerTurn()
    this.subscribeGameBuyIn()

    //not working anymore
    // setTimeout(() => {
    //   window.scrollTo(0,document.body.scrollHeight);
    //   console.log('scroll');
    // }, 2000)

  }
  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }
  subscribeGameBuyIn() {
    this.gameBuyIn$.subscribe(value => {
      this.gameBuyIn = value
    })
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
    })
  }
  subscribePlayerTurn() {
    this.playerTurn$.subscribe(value => {
      this.playerTurn = value
    })
  }

  subscribePortraitMode() {
    // this.isPortrait$.subscribe(value => {
    //   if (value) {
    //     alert("rotate mobile device")
    //   }
    // })
  }

  acceptRematchGame() {
    //TODO: sent info to server 
    //TDOD: wait for new game
  }

  refuseRematchGame() {
    //TODO: sent info to server 
    //return to lobby 
    // this.router.navigate(['/'])
    this.store.dispatch(switchGameStatus({status: GameStatus.table_closed}))
  }

  handleModalOKButton() {
    this.gameStatus = null
    //TODO: sent info to server 
    //return to lobby 
    this.router.navigate(['/'])
  }

  handleModalLowCreditOKButton() {
    //TODO: sent info to server 
    this.store.dispatch(switchGameStatus({status: GameStatus.table_closed}))
  }

  fontSize() {
    let defaultFont = 16
    let fontSize = defaultFont * this.displayModeServices.expanded
    // console.log(this.displayModeServices.expanded, fontSize);
    
    return {
      "fontSize": fontSize + 'px'
    }
  }
  
  topTable() {
    let index = 0

    if (this.gameStatus?.indexOf("seven") != -1){
      index = 1
      return {
        zIndex: index
      }
    }

    return {
    }
  }

}
  