import { animate, animation, group, keyframes, query, style } from "@angular/animations";

export const flipCards = animation([
    group([
        query("app-card:not(.placeholder) .flip-front", [
            animate( '1s linear', keyframes([
                style({top: "-20px", transform: 'rotateY(180deg)', offset: 0}),
                style({top: "0px",transform: 'rotateY(0deg)', offset: 1}),
            ]))
        ], {optional: true}),
        query("app-card:not(.placeholder) .flip-back", [
            animate( '1s linear', keyframes([
                style({top: "-20px", transform: 'rotate(180deg) rotateY(0deg)', offset: 0}),
                style({top: "0px", transform: 'rotate(180deg) rotateY(180deg)', offset: 1}),
            ]))
        ], {optional: true}),

    ])
    

])