import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CardSuit, GlowingStates } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { setAceSwitchPosition } from 'src/app/state/actions/display-mode.actions';
import { selectSuit } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectGlowingState } from 'src/app/state/selectors/game.selector';
// import { selectedClover, selectedHeart, selectedPike, selectedTile } from './animations/suits.animations';

@Component({
  selector: 'app-ace-switch',
  templateUrl: './ace-switch.component.html',
  styleUrls: ['./ace-switch.component.scss'],
  animations:[
    trigger(
      'ace_switch', [
        transition('* => heart', [
          // useAnimation(selectedHeart)
        ]),
        transition('* => clover', [
          // useAnimation(selectedClover)
        ]),
        transition('* => pike', [
          // useAnimation(selectedPike)
        ]),
        transition('* => tile', [
          // useAnimation(selectedTile)
        ]),
        
      ]
    )
  ]
})
export class AceSwitchComponent implements OnInit {

  // @ViewChild('ace_switch', {static: false, read: ElementRef}) ace_switch!: ElementRef 

  CardSuit = CardSuit
  selected: boolean = false 
  glowing: boolean = false

  constructor(private el: ElementRef, private store: Store<AppState>,private displayModeServices: DisplayModeService) { }

  glowingStates: boolean = false
  glowingStates$ = this.store.pipe(
    select(state => selectGlowingState(state.game))
  )
  
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )


  ngOnInit(): void {
    this.subscribeGlowingState()
    this.subscribeAppWrapperSize()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }

  subscribeGlowingState() {
    this.glowingStates$.subscribe(value => {
      
      if (value === GlowingStates.glow_ace_switch
        || value === GlowingStates.glow_all) {
        this.glowingStates = true
        return 
      }

      this.glowingStates = false
    })
  }

  aceSwitchPosition!: {left: number, top: number}
  ngAfterViewInit() {
    
    // let aceSwitchPositionRect = this.el?.nativeElement.getBoundingClientRect()
    // this.aceSwitchPosition = {
    //   left: aceSwitchPositionRect.left,
    //   top: aceSwitchPositionRect.top
    // }
    // this.store.dispatch(setAceSwitchPosition(this.aceSwitchPosition))
    // console.log(this.el.nativeElement.getBoundingClientRect());
    
  }
  ngAfterViewChecked() {
    let aceSwitchPositionRect = this.el?.nativeElement.getBoundingClientRect()
    this.aceSwitchPosition = {
      left: aceSwitchPositionRect.left,
      top: aceSwitchPositionRect.top
    }
    this.store.dispatch(setAceSwitchPosition(this.aceSwitchPosition))
    // console.log(this.el.nativeElement.getBoundingClientRect());

  }


  animation: any = '*'
  handleAnimation() {
    return {
      value: this.animation
    }
  }

  selectSuit(selectedSuit: CardSuit) {
    console.log("Selected Suit: ", selectedSuit);    
    this.selected = true
    switch (selectedSuit) {
      case CardSuit.heart: {
        this.animation = "heart"
        break;
      }
      case CardSuit.clover: {
        this.animation = "clover";
        break;
      }
      case CardSuit.pike: {
        this.animation = "pike";
        break;
      }
      case CardSuit.tile: {
        this.animation = "tile";
        break;
      } 
    }
    this.store.dispatch(selectSuit({selectedSuit: selectedSuit}))
  }

  buttonSize() {
    let defaultSize = 59


    // let width = this.appWrapperSize.width / 8.8
    // let height = this.appWrapperSize.height / 4.7

    // let size = Math.min(width, height)

    let size = this.displayModeServices.expanded * defaultSize

    return {
      width: size + 'px',
      height: size + 'px'
    }
  }
}
