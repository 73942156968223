import { animate, animation, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const opponentDrawCard = animation([
    group([
        query(":self", [
            animate('2s linear', 
            keyframes([
                style({ left: '{{factor_x}}', offset: 0}),
                style({ left: '{{factor_x}}', offset: 0.5}),
                style({ left: '0', offset: 1}),
                // style({ backgroundColor: 'green', offset: 0}),
                // style({ backgroundColor: 'green', offset: 0.5}),
                // style({ backgroundColor: 'green', offset: 1}),
            ]))
        ], {optional: true}),
        query(":enter", [
            animate('2s linear',             
                    keyframes([
                        // style({left: '{{from_x_pos}}', offset: 0}),
                        // style({left: '{{from_x_pos}}', offset: 0.5}),
                        // style({left: '0', offset: 1})
                        style({ left: '{{from_x_pos}}', top: '{{from_y_pos}}', zIndex:2, offset: 0 }),
                        style({ left: '{{from_x_pos}}', top: '{{from_y_pos_2}}', zIndex:2, offset: 0.5 }),
                        style({ left: '0', top: '0', zIndex:2, offset: 1 })
                    ])
            )
        ])
        // query(":enter", [
        //     animate('2s linear',             
        //         keyframes([
        //             style({left: '50px', offset: 0}),
        //             style({left: '50px', offset: 1})
        //             // style({ left: '{{from_x_pos}}', bottom: '{{from_y_pos}}', zIndex:2, offset: 0 }),
        //             // style({ left: '{{from_x_pos}}', bottom: '{{from_y_pos_2}}', zIndex:2, offset: 0.5 }),
        //             // style({ left: '0', bottom: '0', zIndex:2, offset: 1 })
        //         ])
        //     )
        // ], {optional: true})
    ])
])