<div class="modal give-up">
    <div class="content">
        <ng-content select="content">
            
        </ng-content>
    </div>
    <div class="footer m-t-16">
        <ng-content select="footer">            
        </ng-content>    
    </div>
</div>
