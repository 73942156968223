import { Action, createReducer } from "@ngrx/store";
import { GameReplay } from "src/app/models/game-replay.model";

const initialState: GameReplay = {
    buyIn: 0
}

export const createGameReplayReducer = createReducer(
    initialState,
    
)

export function gameReplayReducer(state: GameReplay | undefined, action: Action): GameReplay {
    return createGameReplayReducer(state, action);
}