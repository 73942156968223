import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  
  openMenu: boolean = false
  
  menu_icon = "assets/images/svg/menu-icon.svg"

  links = [
    {label: 'My Games', icon: 'my-games', url: "/my-games", section: '0'},
    {label: 'My Stats', icon: 'statistics', url: "/stats", section: '0'},
    {label: 'My Settings', icon: 'settings', url: "/settings", section: '0'},
    {label: 'Bonuses', icon: 'bonuses', url: "", section: '1'},
    {label: 'Competitions', icon: 'competitions', url: "", section: '1'},
    {label: 'Rules / Help', icon: 'rules-help', url: "", section: '1'},
    {label: 'News', icon: 'news', url: "", section: '1'},
    {label: 'Transactions', icon: 'transactions', url: "", section: '2'},
    {label: 'Deposits / Withdrawals', icon: 'deposits', url: "", section: '2'},
    {label: 'Home Page Fortebet', icon: 'home', url: "", section: '3'},
    {label: 'Sports Betting', icon: 'sports-betting', url: "", section: '3'},
    {label: 'Live Betting', icon: 'live-betting', url: "", section: '3'},
    {label: 'Virtuals', icon: 'virtuals', url: "", section: '3'},
    {label: 'Online Casino', icon: 'casino', url: "", section: '3'},
  ]
  constructor(private element: ElementRef, private renderer: Renderer2) { 
    this.renderer.listen('window', 'click', (e: any) => {
      // console.log('close menu');
      // console.log(!this.element.nativeElement.contains(e.target));
      // console.log(e.target.classList.value.indexOf("menu-icon-open") === -1);
      if (this.openMenu === true && !this.element.nativeElement.contains(e.target) && e.target.classList.value.indexOf("menu-icon-open") === -1) {
        this.openMenu = false;
      }

      
      
    })
  }

  ngOnInit(): void {

  }

  handleToggleMenu() {
    this.openMenu = !this.openMenu
  }

  getHorizontalLine(elementIndex: number) {

    if (elementIndex === 0) {
      return true
    }

    if (this.links[elementIndex].section !== this.links[elementIndex - 1].section) {
      return true 
    }

    return false
  }

  getItemIcon(elementIndex: number) {
    return this.links[elementIndex].icon
  }

}