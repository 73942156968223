import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivePlayerArrowComponent } from './active-player-arrow/active-player-arrow.component';
import { CardComponent } from './card/card.component';



@NgModule({
  declarations: [CardComponent],
  imports: [
    CommonModule
  ],
  exports: [
    CardComponent
  ]
})
export class GameSharedComponentsModule { }
