<div [ngStyle]="fontSize()">

    <app-game-control></app-game-control>
    <app-game-opponent [player]="1" class="game_opponent"></app-game-opponent>
    <app-game-table [ngStyle]="topTable()" class="game_table"></app-game-table>
    <app-game-player [player]="0" class="game_player"></app-game-player>

    <app-general-modal class="narrow" *ngIf="gameStatus === 'game_closed_before_start'">
        <header>This table was closed</header>
        <content>
            <p class="bold black">You canceled the game <br> before it started.</p>
            <ng-container *ngIf="(gameBuyIn !== null && gameBuyIn !== 0); else freeGame">
                <p class="m-t-8">Your buy-in {{gameBuyIn}} UGX was <br> transfered back to your account.</p>
            </ng-container>
            <ng-template #freeGame>

            </ng-template>
        </content>
        <footer>
            <button (click)="handleModalOKButton()">OK</button>
        </footer>
    </app-general-modal>

    <app-general-modal class="narrow" *ngIf="gameStatus == 'game_closed_by_give_up'">
        <header>This table was closed</header>
        <content>
            <p class="bold">You give up the game</p>
            <p class="m-t-8">Good luck next time! </p>
            <p>Thank you for playing.</p>
        </content>
        <footer>
            <button (click)="handleModalOKButton()">OK</button>
        </footer>
    </app-general-modal>

    <app-rematch-modal *ngIf="gameStatus == 'rematch'">
        <ng-container ngProjectAs="buttons">
        
            <button class="refuse m-r-10" (click)="refuseRematchGame()">
                refuse
            </button>
            <button class="accept" (click)="acceptRematchGame()">
                accept
            </button>
        </ng-container>
    </app-rematch-modal>

    <app-general-modal class="narrow" *ngIf="gameStatus == 'table_closed'">
        <header>This table was closed</header>
        <content>
            <p class="m-t-8">Good luck next time! </p>
            <p>Thank you for playing.</p>
        </content>
        <footer>
            <button (click)="handleModalOKButton()">OK</button>
        </footer>
    </app-general-modal>

    <app-general-modal *ngIf="gameStatus == 'low_credit_for_rematch'">
        <header>Low credit for rematch</header>
        <content>
            <p class="bold black">Your credit for rematch is low. You are not eligible to play rematch.</p>
        </content>
        <footer>
            <button (click)="handleModalLowCreditOKButton()">OK</button>
        </footer>
    </app-general-modal>

</div>