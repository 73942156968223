import { animate, animation, group, keyframes, query, sequence, state, style, transition, trigger } from '@angular/animations';

export const dealOpponentDrawCard = animation([
    sequence([
        group([
            query(":self", [
                animate('{{delay}} linear', 
                keyframes([
                    style({ left: '{{factor_x}}', offset: 0}),
                    style({ left: '{{factor_x}}', offset: 1}),
                ])
                )
            ]),
            query(':enter', [
                animate('{{delay}} linear', 
                keyframes([
                    style({opacity: 0, offset: 0}),
                    style({opacity: 0, offset: 1}),
                ]))
            ])
        ]),
        group([
            query(":self", [
                animate('{{time}}  linear', 
                keyframes([
                    style({ left: '{{factor_x}}', offset: 0}),
                    style({ left: '{{factor_x}}', offset: 0.5}),
                    style({ left: '0', offset: 1}),
                ]))
            ], {optional: true}),
            query(":enter", [
                animate('{{time}} linear', 
                    keyframes([
                        style({ opacity: 1, left: '{{from_x_pos}}', bottom: '{{from_y_pos}}', zIndex:2, offset: 0 }),
                        style({ opacity: 1, left: '{{from_x_pos}}', bottom: '{{from_y_pos_2}}', zIndex:2, offset: 0.5 }),
                        style({ opacity: 1, left: '0', bottom: '0', zIndex:2, offset: 1 })
                    ])
                )
            ], {optional: true})
        ])
    ])

])