import { animate, animation, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const opponentDraw2Cards = animation([
    group([
        query(":self", [
            animate( '2s linear', keyframes([
                style({ left: '{{factor_x}}', offset: 0 }),
                style({ left: '{{factor_x}}', offset: 0.5 }),
                style({ left: '0', offset: 1 })
            ]))
        ], {optional: true}),
        query("app-card:not(.last):enter", [
            animate( '2s linear', keyframes([
                style({ left: '{{from_x_pos}}', top: '{{from_y_pos}}', zIndex:2, offset: 0 }),
                style({ left: '{{from_x_pos}}', top: '{{from_y_pos_2}}', zIndex:2, offset: 0.5 }),
                style({ left: '0', top: '0', zIndex:2, offset: 1 })
            ]))
        ], {optional: true}),
        query("app-card.last:enter", [
            animate( '2s linear', keyframes([
                style({ visibility: 'hidden', left: '{{from_x_pos_2}}', top: '{{from_y_pos}}', offset: 0}),
                style({ visibility: 'hidden', left: '{{from_x_pos_2}}', top: '{{from_y_pos}}', offset: 0.25 }),
                style({ visibility: 'visible', left: '{{from_x_pos_2}}', top: '{{from_y_pos_2}}', offset: 0.5 }),
                style({ left: '0', top: '0', offset: 1 })
            ]))
        ], {optional: true}),
    ])
])