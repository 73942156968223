import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { UserService } from './services/user.service';
import { Observable } from 'rxjs';
import { User } from './models/user.model';
import { Store } from '@ngrx/store';
import { initialUserState } from './state/reducers/user.reducer';
import * as UserActions from 'src/app/state/actions/user.actions';
import * as DisplayModeActions from "src/app/state/actions/display-mode.actions"
import { AppState } from './state/app.state';
import { DisplayModeService } from './services/display-mode.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'angular-matatu';
  username: string  = "player001";

  // load$: Observable<Event> = fromEvent(window, 'resize')

  matatuAppWrapperSize!: {width: number, height: number}
  @ViewChild('matatuAppWrapper', {static:false, read: ElementRef}) matatuAppWrapper!: ElementRef;

  constructor (
    private store: Store<AppState>,
    private display: DisplayModeService
  ) {}

  ngOnInit() {
    this.getUser();   
  }

  oldMatatuAppWrapperSize!: {width: number, height: number}
  ngAfterViewInit() {
    let matatuAppWrapperRect = this.matatuAppWrapper.nativeElement.getBoundingClientRect()
    this.matatuAppWrapperSize = { width: matatuAppWrapperRect.width, height: matatuAppWrapperRect.height}
    this.oldMatatuAppWrapperSize = this.matatuAppWrapperSize
    this.store.dispatch(DisplayModeActions.setAppWrapperSize(this.matatuAppWrapperSize))
    this.onResize();
  }
  ngAfterViewChecked() {
    let matatuAppWrapperRect = this.matatuAppWrapper.nativeElement.getBoundingClientRect()
    this.matatuAppWrapperSize = { width: matatuAppWrapperRect.width, height: matatuAppWrapperRect.height}
    
    if (this.oldMatatuAppWrapperSize.width !== this.matatuAppWrapperSize.width || 
      this.oldMatatuAppWrapperSize.height !== this.matatuAppWrapperSize.height ) {
        this.oldMatatuAppWrapperSize = this.matatuAppWrapperSize
        this.store.dispatch(DisplayModeActions.setAppWrapperSize(this.matatuAppWrapperSize))
        this.display.calculateExpansion()
      }    
  }

  getUser() {
    this.store.dispatch(UserActions.getUserRequest({userToken: this.username}));
  }

  onResize() {
    // console.log(this.matatuAppWrapperSize);

    this.display.resize$.subscribe(event => {
      // console.log(this.matatuAppWrapperSize);
      this.store.dispatch(DisplayModeActions.setAppWrapperSize(this.matatuAppWrapperSize))
    })
   
  }
 
}
