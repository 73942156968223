<div class="cards_number_wrapper" [ngStyle]="numberSizeAndPosition()">
    <!-- <img class="" src="{{cards_icon}}" alt=""/> -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 35 31">
        <defs>
          <linearGradient id="icon-a" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#606265"/>
            <stop offset="99.499%" stop-color="#383A3D"/>
          </linearGradient>
          <linearGradient id="icon-red" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#606265"> 
              <animate attributeName="stop-color" from="#606265" to="#c50000" dur="1s" begin="2s" fill="freeze"/>
            </stop>
            <stop offset="99.499%" stop-color="#383A3D"> 
              <animate attributeName="stop-color" from="#383A3D" to="#c50000" dur="1s" begin="2s" fill="freeze"/>
            </stop>
          </linearGradient>
          <linearGradient id="icon-green" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stop-color="#606265"> 
              <animate attributeName="stop-color" from="#606265" to="#1c9735" dur="1s" begin="2s" fill="freeze"/>
            </stop>
            <stop offset="99.499%" stop-color="#383A3D"> 
              <animate attributeName="stop-color" from="#383A3D" to="#1c9735" dur="1s" begin="2s" fill="freeze"/>
            </stop>
          </linearGradient>
          <path id="icon-b" d="M19.4847256,3.89859592 C22.5222917,3.89859592 24.9847256,6.3610298 24.9847256,9.39859592 L24.9847256,25.7364826 C24.9847256,28.7740487 22.5222917,31.2364826 19.4847256,31.2364826 L8.46320477,31.2364826 C5.42563865,31.2364826 2.96320477,28.7740487 2.96320477,25.7364826 L2.96320477,9.39859592 C2.96320477,6.3610298 5.42563865,3.89859592 8.46320477,3.89859592 L19.4847256,3.89859592 Z M26.0133909,4.35423665 L27.2427188,4.68180488 C30.1781283,5.46294466 31.9245089,8.47580309 31.1433691,11.4112125 C31.1417449,11.4173162 31.1401101,11.423417 31.1384649,11.429515 L27.7340149,24.0457508 L27.7347256,9.39859592 C27.7347256,7.49874138 27.0925371,5.74887801 26.0133909,4.35423665 Z M34.0794263,10.0542984 L34.7092295,10.5805041 C37.0427471,12.5251129 37.3580201,15.9932196 35.4134113,18.3267373 L35.3894223,18.3553261 L35.3894223,18.3553261 L30.5838805,24.0422939 L33.7996615,12.1229786 C33.9836137,11.4317155 34.0742855,10.7377077 34.0794263,10.0542984 Z"/>
          <filter id="icon-c" width="103%" height="103.7%" x="-1.5%" y="-1.8%" filterUnits="objectBoundingBox">
            <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetInner1"/>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
            <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.2 0"/>
          </filter>
        </defs>
        <g [class]="getIconColor()" fill-rule="evenodd" transform="rotate(-15 8.205 19.467)">
          <use xlink:href="#icon-b"/>
          <use fill="#000" filter="url(#icon-c)" xlink:href="#icon-b"/>
        </g>
    </svg>
      
    <span class="value">{{valueOfCards}}</span>
</div>

