import { BuyInValue, GameType } from "./lobby.model";

/**
 * playerTurn - 0 for player, 1 for opponent
 */
export interface Game {

    gameStatus: GameStatus | null
    gameBuyIn: BuyInValue,
    gametype: GameType,

    glowingState: GlowingStates,
    playerInfoState: PlayerInfoStates,

    opponentRematchStatus: number,
    playerRematchstatus: number,

    startDealCardForPlayer: number, 
    
    playerTurn: number,
    playerMoves: PlayerMove[],
    
    winner: number | null,

    playerAction: boolean[],
    timers: GameTimers[],
    
    displayGameControls: GameControls,
    
    tableCards: PlayedCard[],    
    playerCards: Card[],
    
    opponentName: string,
    opponentCards: Card[] | null
    numberOfOpponentCards: number,
    valueOfOpponentCards: number | null,

    cutterCard: Card
    selectedSuit: CardSuit | null


    playerPlaysCard: PlayedCard | null,
    opponentPlaysCard: PlayedCard | null,
    

    // players: GamePlayer[],
    // drawCards: Card[],
    // drawOpponentCards: number,
}


export enum GlowingStates {
    none = 'none',
    glow_deck = 'glow_deck',
    glow_hand = 'glow_hand',
    glow_arrow = 'glow_arrow',
    glow_switch_button = 'glow_switch_button',
    glow_ace_switch = 'glow_ace_switch',
    glow_all = 'glow_all'
}

export enum PlayerInfoStates {
    none = 'none',
    pick_or_retaliate  = 'pick_or_retaliate',
    opponent_is_ready = 'opponent_is_ready',
    credit_added = 'credit_added',
    you_lost = 'you_lost'
    
}

export enum GameStatus {
    waiting_for_opponent = 'waiting_for_opponent',
    waiting_for_invited_opponent = 'waiting_for_invited_opponent',
    waiting_for_match_start = 'waiting_for_match_start',

    match_started_select_turn = 'match_started_select_turn',
    match_started_deal_cards = 'match_started_deal_cards',
    match_started_cutter = 'match_started_cutter',
    match_started_table_info = 'match_started_table_info',
    match_started_timebanks = 'match_started_timebanks',
    
    seven_of_cutter_cards = 'seven_of_cutter_cards',
    seven_of_cutter_cards_values = 'seven_of_cutter_cards_values',
    seven_of_cutter_gray_arrows = 'seven_of_cutter_gray_arrows',
    seven_of_cutter_colors = 'seven_of_cutter_colors',
    seven_of_cutter_info_box = 'seven_of_cutter_info_box',

    running_game = 'running_game',
    game_closed_before_start = 'game_closed_before_start',
    game_closed_by_give_up = 'game_closed_by_give_up',
    game_closed_by_opponent_give_up = 'game_closed_by_opponent_give_up',
    rematch = 'rematch',
    low_credit_for_rematch = 'low_credit_for_rematch',
    table_closed ='table_closed',

    lost_game="lost_game",

    player_online_again_opponent_turn="player_online_again_opponent_turn",
    player_online_again_player_turn="player_online_again_player_turn",
    player_offline_opponent_turn="player_offline_opponent_turn",
    player_offline_player_turn="player_offline_player_turn",
    lost_connection_with_server='lost_connection_with_server',
    opponent_online_again='opponent_online_again',
    opponent_offline='opponent_offline'

}
export enum PlayerMove {
    draw_card = 'draw_card',
    play_card = 'play_card',
    play_card_ace = 'play_card_ace',
    select_suit = 'select_suit',
    play_card_two = 'play_card_two',
    play_card_stop = 'play_card_stop',
    play_card_seven_of_cutter = 'play_card_seven_of_cutter',
}
export interface GamePlayer {
    cards: Card[] | number,    
    turnAction: boolean
}
export interface GameTimers {
    turnTimer: boolean,
    timeBankTimer: boolean,
    timeOutTimer: boolean
}
export interface GameControls {
    left: GameControlsLeft | null, 
    right: string,
    bottom: string,
    modal: string
}
export enum GameControlsLeft {
    switch_turn = 'switch_turn',
    ace_switch = 'ace_switch',
    sevent_of_cutter = 'seven_of_cutter'
}

export enum GameMessageVariant {
    //TODO: make one source of truth 
    // this.info_message_variant = "waiting_for_opponent"
    // this.info_message_variant = "waiting_for_invited_opponent"
    // this.info_message_variant = "match_countdown"
    // this.info_message_variant = "won_by_opponent_give_up"
    // this.info_message_variant = "match_started"
    // this.info_message_variant = "lost_game"
    // this.info_message_variant = "player_online_again_opponent_turn"
    // this.info_message_variant = "player_online_again_player_turn"
    // this.info_message_variant = "player_offline_opponent_turn"
    // this.info_message_variant = "player_offline_player_turn"
    // this.info_message_variant = "opponent_offline"
    // this.info_message_variant = "opponent_online_again"
    // this.info_message_variant = "lost_connection_with_server"
}

export interface Card {
    number: CardValue,
    suit: CardSuit,
}
export interface PlayedCard extends Card {
    handOrder: number,
    handPosition: Position
    deckPosition: Position
    player: number
}
export interface Position {
    rotation: number,
    top: number,
    left: number
}
export enum CardValue {
    two = 20,
    three = 3,
    four = 4, 
    five = 5,
    six = 6,
    seven = 7,
    eight = 8,
    nine = 9,
    ten = 10,
    jack = 11,
    queen = 12,
    king = 13,
    ace = 15
}
export enum CardSuit  {
    heart = "heart",
    tile = "tile",
    clover = "clover",
    pike = "pike",
    back = "back"
}