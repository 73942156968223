<div class="inner-wrapper" (click)="toggleGameDetail()">

    <div class="table-column buy-in">{{getBuyIn(false)}}</div>
    <div class="table-column invite" (click)="toggleInviteOpponentDetail($event)">
        <img class="user_icon" 
        [ngClass]="{'active': inviteOpponentDetailActiveClass(), 'online': (game.opponentOnline === true)}" 
        src="{{user_icon}}" alt="opponent">
    </div>
    <div class="table-column opponent">
        {{getOpponentName()}}
    </div>
    <div class="table-column won-lost">
        <div [ngClass]="getGameResultClass()">
            {{getGameResult()}}
        </div>
    </div>
</div>
<div class="game-detail" [ngClass]="{'active': gameDetailActiveClass()}">
    <div class="detail">
        <div class="row id">
            <div class="column name">ID: </div>
            <div class="column value"> {{game.gameId}}</div>
        </div>
        <div class="row">
           <div class="column name"> Buy-in:</div> 
           <div class="column value"> {{getBuyIn(true)}}</div>
        </div>
        <div class="row">
            <div class="column name">Played:</div> 
            <div class="column value">{{getPlayed()}}</div>
        </div>
        <div class="row">
            <div class="column name">Game Length:</div> 
            <div class="column value">{{getGameLength()}}</div>
        </div>
        <div class="row">
            <div class="column name">Winning:</div> 
            <div class="column value">{{getGameWin()}}</div>
        </div>
    </div>
    

    <div class="row buttons">
        <div class="column">
            <button class="replay" (click)="replayGameDetail()"><span>Replay</span></button>
            
        </div>
        <div class="column right">
            <button class="ok" (click)="closeGameDetail()">OK</button>
        </div>
        
    </div>
</div>
<div class="invite-opponent-detail" [ngClass]="{'active': inviteOpponentDetailActiveClass()}">
    <p>Would you like to invite</p>
    <p class="orange">{{game.opponent}}</p>
    <p>for your next Matatu game?</p>
    
    <div class="row buttons">
        <div class="column left">
            <button class="no" (click)="closeInviteOpponent()">NO</button>
        </div>
        <div class="column right">
            <button class="yes" (click)="inviteOpponent()">YES</button>
        </div>
        
    </div>
</div>