<div class="left">
    <app-menu>
    </app-menu>
    <a href="/">
        <div class="logo">
            <img src={{logo_icon}} alt="">
        </div>    
    </a>
    
    
</div>

<div class="right">
    <app-user
        [user]="user$ | async"
    ></app-user>
</div>

