import { animate, animation, group, keyframes, query, style } from "@angular/animations";

export const slideUnderDeck = animation([
    group([
        query("app-card.bottom_card", [
            animate(
                '1s linear', 
                keyframes([
                    style({
                        zIndex: 2, 
                        transform: "rotate(0deg)",
                        top: 0,
                        left: '42.2px',
                        offset: 0}),
                    style({
                        zIndex: 1, 
                        transform: "rotate(0deg)",
                        left: '-42.2px',
                        offset: 0.5}),
                    style({
                        transform: "rotate(-90deg)",
                        left: '16px',
                        top: '2px',
                        offset: 1}),
                ])
            )
        ]),
        query("app-card.bottom_card .flip-front", [
            animate(
                '1s linear', 
                keyframes([
                    style({transform: 'rotateY(180deg)', offset: 0}),
                    style({transform: 'rotateY(0deg)', offset: 1}),
                ])
            )
        ]),
        query("app-card.bottom_card .flip-back", [
            animate(
                '1s linear', 
                keyframes([
                    style({transform: 'rotateY(0deg)', offset: 0}),
                    style({transform: 'rotateY(180deg)',offset: 1}),
                ])
            )
        ])
    ])
    
])
