<div class="lobby-landscape-items" [ngSwitch]="selected_content">
    <div *ngSwitchCase="content_items[0]" class="landscape-select-game-type">
        <div class="title">Choose your game</div>

        <div class="lobby-landscape-wrapper">
            <div class="gameTypes">
                <app-game-type 
                    *ngFor="let gameType of gameTypes; first as isFirst;"  
                    [gameVariant]="gameType" >
                </app-game-type>  
            </div>
    
            <div class="switch-lobby-content" (click)="switchContent(1)">
                <img src="{{switch_lobby_content_right_icon}}" alt="">    
            </div>  
        </div>
        
    </div>

    <div *ngSwitchDefault class="landscape_select_buy_in">
        <div class="selected-game-type"> 
            <div class="title right">Game type</div>
            <ng-container *ngFor="let gameType of gameTypes; first as isFirst;" >
                <app-game-type      
                    (startTheGameEmitter)="inputEvent($event)"
                    *ngIf="selected_game_type === gameType.type"
                    [gameVariant]="gameType" 
                    [ngClass]="{'buy-in-content': true}">
                </app-game-type> 
            </ng-container>
            <app-start-the-game
                [startTheGameSignal]="signalStartTheGame"
                [ngClass]="{'landscape': true}">
            </app-start-the-game>
            <div class="switch-lobby-content left" (click)="switchContent(0)">
                <img src="{{switch_lobby_content_left_icon}}" alt="">    
            </div>  
        </div>
        <div class="buy-in-types">
            <div class="title">Select your buy-in</div>
            <ng-container *ngFor="let buyInType of buyInTypes; first as isFirst;" >
                <app-buy-in 
                    *ngIf="!(selected_game_type === 'FRIEND' && buyInType.type === 0) "
                    [buyInType]="buyInType"
                    [ngClass]="{ 'first': isFirst, 'friend': (selected_game_type === 'FRIEND')}"> 
                </app-buy-in>
            </ng-container>

        </div>
    </div>
</div>