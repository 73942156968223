import { createAction, props } from "@ngrx/store";
import { Card, CardSuit, GameStatus, GlowingStates, PlayedCard, PlayerInfoStates, Position } from "src/app/models/game.model";

const START_GAME = '[Game] start player turn'
const SWITCH_TURN = '[Game] switch player turn'
const SWITCHED_TURN = '[Game] swithed player turn'
const STAY_ON_TURN = '[Game] stay on turn'

const DRAW_CARDS = '[Game] draw cards'
const DRAW_OPPONENT_CARDS = '[Game] draw opponent cards'

const PLAYER_PLAYS_CARD = '[Game] players plays card'   
const PLAYER_PLAYED_CARD = '[Game] players played card'   
const OPPONENT_PLAYS_CARD = '[Game] opponent plays card'
const OPPONENT_PLAYED_CARD = '[Game] opponent played card'

const ADD_TABLE_CARD = '[Game] add card to table cards'

const PLAYER_ACTION = '[Game] player played action'
const SELECT_SUIT = '[Game] select suit after ace'


const TURN_TIMER_START = '[Game] turn timer start'
const TURN_TIMER_DONE = '[Game] turn timer done'
const TIME_BANK_TIMER_START = '[Game] time bank timer start'
const TIME_BANK_TIMER_DONE = '[Game] time bank timer done'
const TURN_OFF_ALL_TIMERS = '[Game] turn off all timers'

const SWITCH_GAME_STATUS = '[Game] switch game status'
const SWITCH_GLOWING_STATE = '[Game] switchglowing state'
const SWITCH_PLAYER_INFO_STATE = '[Game] switch player info state'

export const SHUFFLE_CARDS = '[Game] shuffle table cards'
export const SHUFFLED_CARDS = '[Game] shuffled table cards'

export const switchGlowingState = createAction(
    SWITCH_GLOWING_STATE,
    props<{glowingState: GlowingStates}>()
)
export const switchPlayerInfoState = createAction(
    SWITCH_PLAYER_INFO_STATE,
    props<{playerInfoState: PlayerInfoStates}>()
)
export const switchGameStatus = createAction(
    SWITCH_GAME_STATUS,
    props<{status: GameStatus}>()
)

export const startGame = createAction(
    START_GAME,
    props<{playerTurn: number}>()
)
export const switchTurn = createAction(
    SWITCH_TURN,
    props<{playerTurn: number}>()
)
export const switchedTurn = createAction(
    SWITCHED_TURN,
    props<{playerTurn: number}>()
)
export const stayOnTurn = createAction(
    STAY_ON_TURN,
    props<{playerTurn: number}>()
)

export const turnTimerStart = createAction(
    TURN_TIMER_START,
    props<{player: number}>()
)
export const turnTimerDone = createAction(
    TURN_TIMER_DONE,
    props<{player: number}>()
)
export const timeBankTimerStart = createAction(
    TIME_BANK_TIMER_START,
    props<{player: number}>()
)
export const timeBankTimerDone = createAction(
    TIME_BANK_TIMER_DONE,
    props<{player: number}>()
)
export const turnOffAllTimers = createAction(
    TURN_OFF_ALL_TIMERS,
    props<{player: number}>()
)

export const playerAction = createAction(
    PLAYER_ACTION,
    props<{player: number}>()
)
export const drawCards = createAction(
    DRAW_CARDS,
    props<{cards: Card[]}>()
)
export const drawOpponentCards = createAction(
    DRAW_OPPONENT_CARDS,
    props<{ numberOfCards: number}>()
)
export const playerPlayesCard = createAction(
    PLAYER_PLAYS_CARD,
    props<{playedCard: PlayedCard}>()
)
export const playerPlayedCard = createAction(
    PLAYER_PLAYED_CARD,
    props<{playedCard: PlayedCard}>()
)
export const opponentPlaysCard = createAction(
    OPPONENT_PLAYS_CARD,
    props<{playedCard: PlayedCard}>()
)
export const opponentPlayedCard = createAction(
    OPPONENT_PLAYED_CARD,
    props<{ playedCard: PlayedCard, deckPosition: Position}>()
)
export const addTableCard = createAction(
    ADD_TABLE_CARD,
    props<{playedCard: PlayedCard}>()
)
export const shuffleCards = createAction(
    SHUFFLE_CARDS,
)
export const shuffledCards = createAction(
    SHUFFLED_CARDS,
    props<{shuffledcards: PlayedCard[]}>()
)
export const selectSuit = createAction(
    SELECT_SUIT,
    props<{selectedSuit: CardSuit}>()
)


