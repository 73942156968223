<app-header></app-header>
<app-subheader></app-subheader>

<div class="inner_wrapper">

    <h1>My Games</h1>

    <form>

        <div class="radios_wrapper">

                <div class="radio_wrapper" *ngFor="let item of gameResults; index as elementIndex">
                    <label>
                        {{item.label}}
                        <input 
                            type="radio" 
                            [checked]="(selectedGameResults === elementIndex)"
                            (change)="selectGameResult(elementIndex)"
                            name="game_results" value="{{item.value}}">
                        <div class="checkmark_circle"></div>
                    </label>                
                </div>        
        </div>

        <div class="selections_wrapper">
            
            <div class="column">
                <h2 class="selection_title">Buy-ins:</h2>
                <app-select
                class="buyIns"
                [type]="'buyIns'"
                (selectedEmmiter)="selectedBuyIn($event)"
                [selectedIndex]="selectedBuyIns"
                [selected]="selectedBuyInsShorts()"
                [list]="buyIns">
                </app-select>
            </div>
            
            <div class="column">
                <h2 class="selection_title">Dates:</h2>
                <app-select
                class="dates"
                [type]="'dates'"
                (selectedEmmiter)="selectedDate($event)"
                [selectedIndex]="selectedDateIndex"
                [selected]="selectedDateShort()"
                [list]="dates">
                </app-select>
            </div>

        </div>
    </form>

    <div class="my-games-table">
      <ng-container *ngIf="myGames.length > 0">
        <div class="my-games-header">
          <div class="table-column"> 
            <div class="table-column-inner">
              Buy-in
              <i class="full_arrow_down"></i>
            </div>
              
          </div>
          <div class="table-column">
            <div class="table-column-inner">
              Invite
              <i class="arrow_down"></i>
            </div>
          </div>
          <div class="table-column">
            <div class="table-column-inner">
              Opponent
              <i class="full_arrow_down"></i>
            </div>
            
          </div>
          <div class="table-column">
            <div class="table-column-inner">
              W/L
              <i class="full_arrow_down"></i>
            </div>
           
          </div>
        </div>
        <div class="my-games-content">
          <ng-container *ngFor="let item of myGames; index as elementIndex; last as last">
            <ng-container *ngIf="elementIndex === 0 || getDayBar(item.gameStarted, myGames[elementIndex-1].gameStarted)">
              <div class="date-bar">
                {{getDayBarDate(item.gameStarted)}}
              </div>
            </ng-container>
            <app-game-in-table 
              [game]="item"
              [elementIndex]="elementIndex"
              [toggleElementIndex]="toggleElementIndex"
              (emitToggleElement)="emitToggleElement($event)"
              [ngClass]="{last: last}">
            </app-game-in-table> 
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="myGames.length === 0">
        <div class="notice">
          No games found
        </div>
      </ng-container>
    </div>

</div>


<app-footer></app-footer>