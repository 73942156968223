import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  
  @Input() user!: User | null;
  
  user_icon = "assets/images/svg/user.svg"
  eye_icon = "assets/images/svg/eye.svg"

  //user_name = 'Abu_Bali';
  //user_balance = '134,932,030 UGX';
  

  constructor() { 
  }
  
  ngOnInit(): void {
    //this.store.dispatch();
    //this.user = this.store.select('user');
    //console.log(this.user);
    
  }

}
