import { animate, animation, group, keyframes, query, style } from "@angular/animations";

const time = 7
const factor = 1.75
const delay = 1/8;

export const dealCards = animation([
    group([
        query("app-card:nth-child(1)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 7, top: '{{from_top}}px', left: '{{from_left}}px', offset: 0}),
                    style({zIndex: 7, top: '{{from_top_2}}px', left: '{{from_left}}px', offset: 1/8}),
                    style({zIndex: 7, top: '0px', left: '0px', offset: 1})
                ])
            )
        ]),
        query("app-card:nth-child(2)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 6, top: '{{from_top}}px', left: '{{from_left_2}}px', offset: 0}),
                    style({zIndex: 6, top: '{{from_top}}px', left: '{{from_left_2}}px', offset: ((1/time/factor)) }),
                    style({zIndex: 6, top: '{{from_top_2}}px', left: '{{from_left_2}}px', offset: ((1/time/factor) + (1/8)) }),
                    style({zIndex: 6, top: '0px', left: '0px', offset: 1})
                ])
            )
        ]),
        query("app-card:nth-child(3)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 5, top: '{{from_top}}px', left: '{{from_left_3}}px', offset: 0}),
                    style({zIndex: 5, top: '{{from_top}}px', left: '{{from_left_3}}px', offset: ((2/time/factor)) }),
                    style({zIndex: 5, top: '{{from_top_2}}px', left: '{{from_left_3}}px', offset: ((2/time/factor) + (1/8)) }),
                    style({zIndex: 5, top: '0px', left: '0px', offset: 1})
                ])
            )
        ]),
        query("app-card:nth-child(4)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 4, top: '{{from_top}}px', left: '{{from_left_4}}px', offset: 0}),
                    style({zIndex: 4, top: '{{from_top}}px', left: '{{from_left_4}}px', offset: ((3/time/factor)) }),
                    style({zIndex: 4, top: '{{from_top_2}}px', left: '{{from_left_4}}px', offset: ((3/time/factor) + (1/8)) }),
                    style({zIndex: 4, top: '0px', left: '0px', offset: 1})
                ])
            )
        ]),
        query("app-card:nth-child(5)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 3, top: '{{from_top}}px', left: '{{from_left_5}}px', offset: 0}),
                    style({zIndex: 3, top: '{{from_top}}px', left: '{{from_left_5}}px', offset: ((4/time/factor)) }),
                    style({zIndex: 3, top: '{{from_top_2}}px', left: '{{from_left_5}}px', offset: ((4/time/factor) + (1/8)) }),
                    style({zIndex: 3, top: '0px', left: '0px', offset: 1})
                ])
            )
        ]),
        query("app-card:nth-child(6)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 2, top: '{{from_top}}px', left: '{{from_left_6}}px', offset: 0}),
                    style({zIndex: 2, top: '{{from_top}}px', left: '{{from_left_6}}px', offset: ((5/time/factor)) }),
                    style({zIndex: 2, top: '{{from_top_2}}px', left: '{{from_left_6}}px', offset: ((5/time/factor) + (1/8)) }),
                    style({zIndex: 2, top: '0px', left: '0px', offset: 1})
                ])
            )
        ]),
        query("app-card:nth-child(7)", [
            animate(
                (time/factor) + 's linear',
                keyframes([
                    style({zIndex: 1, top: '{{from_top}}px', left: '{{from_left_7}}px', offset: 0}),
                    style({zIndex: 1, top: '{{from_top}}px', left: '{{from_left_7}}px', offset: ((6/time/factor)) }),
                    style({zIndex: 1, top: '{{from_top_2}}px', left: '{{from_left_7}}px', offset: ((6/time/factor) + (1/8)) }),
                    style({zIndex: 1, top: '0px', left: '0px', offset: 1})
                ])
            )
        ])
    ])
])