import { createSelector } from "@ngrx/store";
import { GameReplay } from "src/app/models/game-replay.model";

const buyIn = (state: GameReplay) => state.buyIn

export const selectGameReplayBuyIn = createSelector(
    buyIn,
    (buyIn) => {
        return buyIn
    }
)