import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { now } from 'moment';
import { asyncScheduler, BehaviorSubject, from, fromEvent, interval, Observable, of, SchedulerLike, Subject,  Subscription,  timer } from 'rxjs';
import { filter, map, observeOn, repeatWhen, take, takeLast, takeUntil, tap } from 'rxjs/operators';
import * as GameActions from '../state/actions/game.actions';
import { AppState } from '../state/app.state';
import { playerTurn, selectNextPlayerOnTurn, selectPlayerTimers, selectTimers } from '../state/selectors/game.selector';

@Injectable({
  providedIn: 'root'
})
export class GameTimersService {


  // miliseconds
  private TURN_TIME = 14000
  private TIMEBANK_TIME = 59000
  private TIMEOUT_TIME = 299000

  timers!: any
  timers$ = this.store.pipe(
    select(state => selectTimers)
  )

  turnTimerInterupt_timeOut!: any
  turnTimerInterupt_event!: any  
  turnTimer!: Observable<number> 
  

  timeBankTimer_timeOut!: any
  timeBankTimer_event!: any
  timeBankTimer!: Observable<any> 

  constructor(private store: Store<AppState>) { 
    this.timers$.subscribe(value => {
      this.timers = value
      // console.log(this.timers);
    })
  }

  timeBankTimerReset(from: number, to: number, event: any): Observable<number> {
    this.timeBankTimer_timeOut = interval(to - from)
    this.timeBankTimer_event = event
    this.timeBankTimer = timer(0,1000).pipe(
      takeUntil(this.timeBankTimer_timeOut),
      takeUntil(this.timeBankTimer_event)
    )    
    return this.timeBankTimer
  }
  // subscibtonTimeBank$!: Subscription
  // subscribeTimeBankTimer(from: number, to: number, event: any) {
  //   this.subscibtonTimeBank$ = this.timeBankTimerReset(from, to, event).subscribe(
  //     (n) => { 
  //       console.log(n);
  //     }
  //   )
  // }
  // unsubscribeTimeBankTimer() {
  //   this.subscibtonTimeBank$.unsubscribe()
  // }
  

  turnTimerReset(from: number, to: number, event: any): Observable<number> {
    this.turnTimerInterupt_timeOut = interval(to - from)
    this.turnTimerInterupt_event = event

    this.turnTimer = timer(0,1000).pipe(
      takeUntil(this.turnTimerInterupt_timeOut),
      // takeUntil(this.turnTimerInterupt_event)
    )
    // console.log(this.turnTimer);
    return this.turnTimer
  }  

  // playerTurnSubscription$!: Subscription
  // startPlayerTurn() {
  //   let event = fromEvent(document, '');
  //   this.playerTurnSubscription$ = this.turnTimerReset(0, this.TURN_TIME, event).subscribe(
  //     (n) => {
  //       console.log(n);
  //     }
  //   )
  // }
  // stopPlayerTurn() {
  //   this.playerTurnSubscription$.remove
  // }

}
