<app-table-control-left >
</app-table-control-left>

<app-selected-suit [ngStyle]="selectedSuitPosition()">
</app-selected-suit>

<div 
    class="table-wrapper"
    (click)="shuffleTableCards()">
    <div class="table-wrapper-inner" [ngStyle]="tableWrapperSize()">
        <img src="{{table_image}}" alt=""/>
        <ng-container *ngIf="canRenderTableCards()">
            <div 
                class="table_cards"
                #table_cards_ref>
                <app-card
                    *ngFor="let card of tableCards"
                    [card]="card"
                    [ngStyle]="calculateRandomStyle(card)"
                    class=""> 
                </app-card>
            </div>
        </ng-container>
    </div>
</div>

<div class="button-wrapper" [ngStyle]="buttonPosition()">
    <!-- waiting_for_opponent -->
    <button
        *ngIf="gameStatus == 'waiting_for_opponent'"
        class="button"
        (click)="handleCancelGame()">
        Cancel
    </button>

    <!-- waiting_for_invited_opponent -->
    <button
        *ngIf="gameStatus == 'waiting_for_invited_opponent'"
        class="button"
        (click)="handleCancelGameInvation()">
        Cancel invitation
    </button>

    <!-- match_countdown -->
    <button
        *ngIf="gameStatus == 'waiting_for_match_start'"
        class="button"
        [ngClass]="{green: readyButtonActive}"
        (click)="handleReadySignal()">
        I’m ready!
    </button>

</div>


<app-table-control-right>
</app-table-control-right>


<app-table-info-box 
    *ngIf="canRenderTableInfo()" 
    [tableWrapperSize]="tableWrapperSize()"
></app-table-info-box>





