import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DisplayMode } from 'src/app/models/display-mode.model';
import { AppState } from 'src/app/state/app.state';
import { isMobileLandscapeMode, isMobilePortraitMode, isPortraitMode } from 'src/app/state/selectors/display-mode.selector';
import { selectMyTodayBalance } from 'src/app/state/selectors/user.selector';

export enum SubheaderContent {
  PLAYERS,
  BALANCE,
  WINLOST
}

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent implements OnInit {

  // isMobilePortrait!: boolean 
  // isMobilePortrait$ = this.store.pipe(
  //   select(state => isMobilePortraitMode(state.displayMode))
  // )
  
  SubheaderContent = SubheaderContent;
  selected_content:SubheaderContent = SubheaderContent.PLAYERS

  button_left = "assets/images/svg/arrow_button_left.svg";
  button_right = "assets/images/svg/arrow_button_right.svg";

  //selected_content = SubheaderContent.PLAYERS;
  
  myTodayBalance: boolean = false;
  myTodayBalance$ = this.store.pipe(
    select(state => selectMyTodayBalance(state.user))
  )

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    // this.subscribeIsMobilePortrait()
    this.subscribeMyTodayBalance()
  }

  subscribeMyTodayBalance() {
    this.myTodayBalance$.subscribe(value => {
      this.myTodayBalance = value
    })
  }
  // subscribeIsMobilePortrait() {
  //   this.isMobilePortrait$.subscribe(value => {
  //     this.isMobilePortrait = value
  //   });
  // }
  switchContent(direction: number): void {
    
    let new_content = this.selected_content + direction;
  
    if (new_content < 0 || new_content > 2 ) {
      return;  
    }

    this.selected_content = new_content;
  }
  

  

}
