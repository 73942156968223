import { createAction, props } from "@ngrx/store";
import { FilerDateType, FilterBuyInValue, FilterGameResult, User, UserMyGamesFilter, UserSettings } from "src/app/models/user.model";

const GET_USER = '[User] get User';
const GET_USER_REQUEST = '[User] get user request';
const GET_USER_DONE = '[User] get user done';

const REFRESH_USER_BALANCE_REQUEST = '[number] refresh user balance request';
const REFRESH_USER_BALANCE_DONE = '[number] refresh user balance done';

const REFRESH_USER_TODAYS_BALANCE_REQUEST = '[number] refresh user todays balance request';
const REFRESH_USER_TODAYS_BALANCE_DONE = '[number] refresh user todays balance done';

const REFRESH_USER_WINLOST_REQUEST = '[UserWinLostRate] refresh user win lost rate request';
const REFRESH_USER_WINLOST_DONE = '[UserWinLostRate] refresh user win lost rate done';

const CHANGE_SETTINGS = '[UserSettings] change settings'

const CHANGE_MY_GAMES_FILTER_RESULTS = '[UserMyGamesFilter] change my games filter game results'
const CHANGE_MY_GAMES_FILTER_BUY_INS = '[UserMyGamesFilter] change my games filter buy ins'
const CHANGE_MY_GAMES_FILTER_DATE = '[UserMyGamesFilter] change my games filter date'

const CHANGE_STATISTICS_FILTER_DATE = '[UserstatisticFilter] change statistic date filter'
const CHANGE_STATISTICS_FILTER_OPPONENT = '[UserstatisticFilter] change opponent date filter'

export const changeStatisticsFilterDate = createAction(
    CHANGE_STATISTICS_FILTER_DATE,
    props<{date: FilerDateType}>()
)
export const changeStatisticsFilterOpponent = createAction(
    CHANGE_STATISTICS_FILTER_OPPONENT,
    props<{opponent: string}>()
)

export const changeMyGamesFilterResults = createAction(
    CHANGE_MY_GAMES_FILTER_RESULTS,
    props<{values: FilterGameResult}>()    
)
export const changeMyGamesFilterBuyIn = createAction(
    CHANGE_MY_GAMES_FILTER_BUY_INS,
    props<{values: FilterBuyInValue[]}>()    
)
export const changeMyGamesFilterDate = createAction(
    CHANGE_MY_GAMES_FILTER_DATE,
    props<{values: FilerDateType}>()    
)


export const getUser = createAction(
    GET_USER,
    props<{ user: User }>()
);

/**
 *  @param {string} userToken from bamp server after login redirection
*/
export const getUserRequest = createAction(
    GET_USER_REQUEST,
    props<{ userToken: string }>()
);

export const getUserDone = createAction(
    GET_USER_DONE,
    props<{ user: User }>()
);

export const changeSettings = createAction(
    CHANGE_SETTINGS,
    props<{key: keyof UserSettings}>()
)

