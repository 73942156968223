import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { delay } from 'rxjs/operators';
import { Card, CardSuit, GameStatus, PlayedCard, Position } from 'src/app/models/game.model';
import { CardPositionService } from 'src/app/services/card-position.service';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { opponentPlayedCard, switchGameStatus } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize, selectDeckPosition, selectTablePosition } from 'src/app/state/selectors/display-mode.selector';
import { selectGameStatus, selectNumberOfOpponentCards, selectOpponentCards, selectOpponentPlaysCard, selectStartDealCardForPlayer } from 'src/app/state/selectors/game.selector';
import { dealOpponentDrawCard } from './animations/deal_opponent_draw_card.animation';
import { dealCards } from './animations/opponent_deal_cards.animation';
import { dealCards2 } from './animations/opponent_deal_cards_2.animation';
import { opponentDrawCard } from './animations/opponent_draw_card.animation';
import { opponentDraw2Cards } from './animations/opponent_draw_cards.animation';
import { flipCards } from './animations/opponent_flip_cards.animation';
import { opponentPlayCard } from './animations/opponent_play_card.animation copy';

@Component({
  selector: 'app-opponent-cards',
  templateUrl: './opponent-cards.component.html',
  styleUrls: ['./opponent-cards.component.scss'],
  animations: [
    trigger(
      'opponent_animations', [        
        transition('* => opponent_draw_card', [
          useAnimation(opponentDrawCard)
        ], {params: { from_x_pos: '50px', from_x_pos_2: 0, from_y_pos: 0, from_y_pos_2: 0, factor_x: 0}}),
        transition('* => deal_opponent_draw_card', [
          useAnimation(dealOpponentDrawCard)
        ], {params: { delay: '0ms', time: '1000ms', from_x_pos: '50px', from_x_pos_2: 0, from_y_pos: 0, from_y_pos_2: 0, factor_x: 0}}),
        transition('* => opponent_draw_card_2', [
          useAnimation(opponentDraw2Cards)
        ], {params: { from_x_pos: 0, from_x_pos_2: 0, from_y_pos: 0, from_y_pos_2: 0, factor_x: 0}}),
        transition('* => opponent_plays_card', [
          useAnimation(opponentPlayCard)
        ], {params: { from_x: 0, to_x: 0, from_y: 0, from_y_2: 0, to_y: 0, from_rotation: 0, to_rotation: 0}}),
        transition('* => deal_cards', [
          useAnimation(dealCards)
        ], {params: { from_top: 0,from_top_2:0, from_left: 0, from_left_2:0, from_left_3:0,from_left_4:0, from_left_5:0, from_left_6: 0, from_left_7: 0} }),
        transition('* => deal_cards_2', [
          useAnimation(dealCards2)
        ], {params: { from_top: 0,from_top_2:0, from_left: 0, from_left_2:0, from_left_3:0,from_left_4:0, from_left_5:0, from_left_6: 0, from_left_7: 0} }),
        transition('* => flip_cards', [
          useAnimation(flipCards)
        ])
      ]
    ),
  ]
})


export class OpponentCardsComponent implements OnInit {

  @Input() replay: boolean = false
  @Input() player!: number
  @ViewChild('opponent_cards_ref', {static:false, read: ElementRef}) opponent_cards_ref!: ElementRef;
  

  stackFactorAddition = 4
  stackFactor = this.cardPositionService.WIDTH + this.stackFactorAddition
  widthFactor = this.cardPositionService.WIDTH
  heightFactor = this.cardPositionService.HEIGHT

  backCard: Card = {
    number: 0, 
    suit: CardSuit.back
  }

  opponentAnimationState!: any
  runningOpponetAnimation: boolean = false

  lastOpponentCardRef!: any  
  
  opponentCardDeckPosition!: Position
  opponentHandPosition!: Position
  
  opponentPlayedCard!: PlayedCard
  opponentPlayedCard$ = this.store.pipe(
    select(state => selectOpponentPlaysCard(state.game))
  )
  opponentAnimationStartCard: number = -1
  
  numberOfOpponentCards!: number;
  numberOfOpponentCards$ = this.store.pipe(
    select(state => selectNumberOfOpponentCards(state.game))
  )
  opponentCardsPlaceholder: number[] = []
  opponentCards: Card[] | null = null
  opponentCards$ = this.store.pipe(
    select(state => selectOpponentCards(state.game))
  )    
  
  deckPosition!: any
  deckposition$ = this.store.pipe(
    select(state => selectDeckPosition(state.displayMode))
  )
  tablePosition!: any
  tablePosition$ = this.store.pipe(
    select(state => selectTablePosition(state.displayMode))
  )

  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  
  selectStartDealCardForPlayer!: number
  selectStartDealCardForPlayer$ = this.store.pipe(
    select(state => selectStartDealCardForPlayer(state.game))
  )

  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  constructor(private store: Store<AppState>, 
    private cardPositionService: CardPositionService,
    private displayModeServices: DisplayModeService) { }

  renderOpponentCards: boolean = false
  ngOnInit(): void {
    this.subscribeAppWrapperSize()

    this.subscribeGameStatus()    
    this.subscribeSelectStartDealCardForPlayer()
    this.setScreenRotation()

    this.subscribeDeckPosition()
    this.subscribeTablePosition()

    this.renderOpponentCards = this.canRenderOpponentCard()
    this.subscribeNumberOfOpponentCards()
    this.subscribeOpponentCards()
    this.subscribeOpponentPlayedCard()
  }
  
  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }

  screenRotation: number = 0
  setScreenRotation() {
    if (this.appWrapperSize.width > this.appWrapperSize.height || this.appWrapperSize.width > 720) {
      this.screenRotation = 0
      return 
    } 
    this.screenRotation = 1
  }

  subscribeSelectStartDealCardForPlayer() {
    this.selectStartDealCardForPlayer$.subscribe(value => {
      this.selectStartDealCardForPlayer = value
    })
  }

  subscribeDeckPosition() {
    this.deckposition$.subscribe(value => {
      this.deckPosition = value
    })
  }
  subscribeTablePosition() {
    this.tablePosition$.subscribe(value => {
      this.tablePosition = value
    })
  }
  subscribeOpponentCards() {
    this.opponentCards$.subscribe(value => {
      this.opponentCards = value
      // console.log(this.opponentCards);
    })
  }
  subscribeOpponentPlayedCard() {
    this.opponentPlayedCard$.subscribe(value => {
      if (value === null) {
        return 
      }
  
      this.opponentPlayedCard = value
    })
  }
  
  numberOfOpponentCardsCopy: number = 0
  subscribeNumberOfOpponentCards() {
    // console.log("opponent subscribe cards");
    this.numberOfOpponentCards$.subscribe(value => {
      // console.log(value);
      let opponentAnimationState = '*'
      // console.log(this.numberOfOpponentCards);
      // console.log(value);
      // console.log(value - this.numberOfOpponentCards);
      
      if (value - this.numberOfOpponentCards === 1) {
        // console.log("draw opponent one card");  

        this.addOpponentCard([value - 1])
        this.numberOfOpponentCards = value
        this.calculateStackFactor()

        this.opponentAnimationState = 'opponent_draw_card'

        return
      }


      if (value - this.numberOfOpponentCards === 2) {
        // console.log("draw opponent two card");  

        this.addOpponentCard([value - 2, value - 1])
        this.numberOfOpponentCards = value
        this.calculateStackFactor()

        this.opponentAnimationState = 'opponent_draw_card_2'
        return
      }

      // console.log(value);
      if (!this.renderOpponentCards) {
        this.numberOfOpponentCardsCopy = value
      }
      else {
        this.numberOfOpponentCards = value
      }
      // console.log(this.numberOfOpponentCards);
      // console.log(this.numberOfOpponentCardsCopy);
      this.generateOpponentCards(this.numberOfOpponentCards)
      this.calculateStackFactor()

      
      // if (!this.numberOfOpponentCards) {
      //   this.numberOfOpponentCards = value
      //   this.generateOpponentCards(this.numberOfOpponentCards)
      //   this.calculateStackFactor()
      //   return 
      // }

      // console.log(value);
      // if (value - this.numberOfOpponentCards == 1) {
      //   // console.log("draw opponent one card");  
      //   this.addOpponentCard([value - 1])
      //   this.opponentAnimationState = 'opponent_draw_card'
      // }
      // if (value - this.numberOfOpponentCards == 2) {
      //   // console.log('ADD TWO CARDS');
        
      //   // console.log("draw opponent two card");  
      //   this.addOpponentCard([value - 2, value - 1])
      //   //this.addOpponentCard(value - 1)
      //   this.opponentAnimationState = 'opponent_draw_card_2'
      // }

      // this.numberOfOpponentCards = value
      // // console.log(this.numberOfOpponentCards);
      // //TODO: add animation for shrinking hand
      // this.generateOpponentCards(this.numberOfOpponentCards)

      // this.calculateStackFactor()
    })
    
  
  }
  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value
      // console.log(this.gameStatus);      
     this.chooseDealCardsAnimation()

      if (this.gameStatus === GameStatus.seven_of_cutter_cards) {
        //wait for opponent cards
        this.opponentAnimationState = "flip_cards"
      }
    })
  }
  
  getCard(indexOfelement: number) {
    
    return (indexOfelement ===  this.opponentAnimationStartCard) ? this.opponentPlayedCard : this.backCard
  }

  ngAfterViewInit() {
    // console.log('AFTER INIT');
    
    this.setScreenRotation()
    this.calculateStackFactor()
    
    if (this.screenRotation === 1) {
      this.stackFactor = 0.1142 * window.innerHeight
      this.widthFactor = 0.1142 * window.innerHeight
      this.heightFactor = 0.2547 * window.innerWidth  
    }  

    let cardBounding = this.opponent_cards_ref?.nativeElement.getBoundingClientRect()
    this.opponentCardsRect = {top: cardBounding?.top, left: cardBounding?.left}

    if (!this.canRenderCards()) {
 
      let lastCardrectRect = this.opponent_cards_ref.nativeElement.lastElementChild?.getBoundingClientRect();
      this.lastOpponentCardRef = {top: lastCardrectRect?.top, left: lastCardrectRect?.left}

      if (this.opponent_cards_ref.nativeElement.lastElementChild === null) {
        this.lastOpponentCardRef = {top: this.opponentCardsRect.top, left: this.opponentCardsRect.left}
      }
     

      
    }

 
  }

  opponentCardsWidth!: number
  opponentCardsHeight!: number
  opponentCardsRect!: {top: number, left: number}

  // widthDiff!: number
  ngAfterViewChecked() {
    // console.log('AFTER CHECKED');
    
    this.calculateStackFactor()  
    this.setScreenRotation()

    this.opponentCardsWidth = this.opponent_cards_ref.nativeElement.getBoundingClientRect().width
    this.opponentCardsHeight = this.opponent_cards_ref.nativeElement.getBoundingClientRect().height

    let cardBounding = this.opponent_cards_ref.nativeElement.getBoundingClientRect()
    this.opponentCardsRect = {top: cardBounding.top, left: cardBounding.left}

    if (!this.canRenderCards()) {

      let lastCardrectRect = this.opponent_cards_ref.nativeElement.lastElementChild?.getBoundingClientRect();
      this.lastOpponentCardRef = {top: lastCardrectRect?.top, left: lastCardrectRect?.left}

      if (this.opponent_cards_ref.nativeElement.lastElementChild === null) {
        this.lastOpponentCardRef = {top: this.opponentCardsRect.top - this.cardPositionService.HEIGHT + 16, left: this.opponentCardsRect.left - this.cardPositionService.WIDTH}
      }

    }
 
  }


  opponentPlaysCard() {
    //can't play if the game not started 
    if (this.gameStatus !== GameStatus.running_game) {
      return 
    }
    
    //select middle card (if event number of cards - select middle randomly from two cards in middle)
    let middleCard =  Math.round(this.numberOfOpponentCards / 2)
    if (this.numberOfOpponentCards % 2 === 0) {
      let random = Math.round(Math.random())      
      middleCard = middleCard + random
    }
    //switch from back card to active card 
    this.opponentAnimationStartCard = middleCard - 1
    
    //get opponent hand position of animated card
    let boundingBox = { top: 0, left: 0, rotation: 0 }
    if (this.opponent_cards_ref.nativeElement) {
      boundingBox = this.opponent_cards_ref.nativeElement.children[this.opponentAnimationStartCard].getBoundingClientRect()
    }
    this.opponentHandPosition = {
      top: boundingBox.top,
      left: boundingBox.left,
      rotation: 0,
    }

    // console.log(this.opponentHandPosition);
    

    //run animation
    this.opponentAnimationState = 'opponent_plays_card'
    // this.store.dispatch(opponentPlayedCard({playedCard: this.opponentPlayedCard, deckPosition: this.opponentCardDeckPosition}))  

  }

  canRenderCards() {
    if (this.opponentCards !== null && this.gameStatus?.indexOf('seven_of_cutter') !== -1 ) {
      return true
    }
    return false
  }

  canRenderCardsPlaceholders() {
    if (this.gameStatus === GameStatus.game_closed_by_give_up) {
      return false
    }
    if (this.gameStatus === GameStatus.game_closed_before_start) {
      return false
    }

    if (this.gameStatus === GameStatus.table_closed) {
      return false
    }
    return true

  }

  handleOpponntAnimations() {
    
    if(this.opponentCardsRect === undefined) {
      return {value: this.opponentAnimationState}
    }

    // console.log("handle opponent card animation");
    
    if (this.runningOpponetAnimation) {
      return {value: this.opponentAnimationState}
    }

    switch (this.opponentAnimationState) {
      case 'opponent_draw_card': {
        // console.log("opponent_draw_card");
        this.runningOpponetAnimation = true
        let handle = this.opponentDrawCardAnimation('2s', '0s')
        // console.log(handle);
        
        return handle
      }
      case 'deal_opponent_draw_card': {
        this.runningOpponetAnimation = true
        return this.opponentDrawCardAnimation('300ms', this.dealAnimationDelay)
      }
      case 'opponent_draw_card_2': {
        this.runningOpponetAnimation = true
        return this.opponentDraw2CardsAnimation()
      }
      case 'opponent_plays_card': {
        this.runningOpponetAnimation = true
        return this.opponentPlaysCardAnimation()
      }
      // case 'deal_cards': {
      //   this.runningOpponetAnimation = true
      //   return this.opponentDealCardsAnimation()
      // }
      // case 'deal_cards_2': {
      //   this.runningOpponetAnimation = true
      //   return this.opponentDealCardsAnimation()
      // }
      case 'flip_cards': {
        this.runningOpponetAnimation = true
        return {
          value: 'flip_cards'
        }
      }
      
    }

    return {value: this.opponentAnimationState}

  }

  opponentDealCardsAnimationLandscape() {
    let from_top = this.deckPosition.top - this.opponentCardsRect.top
    let from_top_2 = this.heightFactor / 2
    let from_left = this.deckPosition.left - this.opponentCardsRect.left

   
    return { 
      value: this.opponentAnimationState, 
      params: {
        from_top: from_top,
        from_top_2: from_top_2,
        from_left: from_left,
        from_left_2: from_left - (this.stackFactor),
        from_left_3: from_left - (this.stackFactor * 2),
        from_left_4: from_left - (this.stackFactor * 3),
        from_left_5: from_left - (this.stackFactor * 4),
        from_left_6: from_left - (this.stackFactor * 5),
        from_left_7: from_left - (this.stackFactor * 6),
      }
    };
  }
  opponentDealCardsAnimationPortrait() {
    let from_top = this.deckPosition.left - this.opponentCardsRect.left
    let from_top_2 = this.heightFactor / 2
    let width = this.numberOfOpponentCards * this.stackFactor
    let from_left = width + this.stackFactor - 2*this.widthFactor

   
    return { 
      value: this.opponentAnimationState, 
      params: {
        from_top: from_top,
        from_top_2: from_top_2,
        from_left: from_left,
        from_left_2: from_left - (this.stackFactor),
        from_left_3: from_left - (this.stackFactor * 2),
        from_left_4: from_left - (this.stackFactor * 3),
        from_left_5: from_left - (this.stackFactor * 4),
        from_left_6: from_left - (this.stackFactor * 5),
        from_left_7: from_left - (this.stackFactor * 6),
      }
    };
  }
  opponentDealCardsAnimation() {
    
    // console.log('DEAL OPPONENT CARDS ANIMATION');
    // console.log(this.opponentAnimationState);
    
    // console.log(this.opponentCardsRect);
    // console.log(this.deckPosition);

    if (this.screenRotation === 1) {
      return this.opponentDealCardsAnimationPortrait()
    }
    
    return this.opponentDealCardsAnimationLandscape()
 
  }


  drawCardAnimationLandscape(time: string, delay: string) {
    
    console.log(this.deckPosition);
    console.log(this.lastOpponentCardRef);
    

    let from_y_pos = this.lastOpponentCardRef.top + this.deckPosition.top 
    let from_y_pos_2 = from_y_pos - this.heightFactor;

    if (this.opponentAnimationState === "deal_opponent_draw_card"){
      from_y_pos = this.lastOpponentCardRef.top - this.deckPosition.top 
      from_y_pos_2 = from_y_pos + this.heightFactor;
    }
    
    let shift = this.stackFactor/2
    let from_x_pos = this.deckPosition.left - this.lastOpponentCardRef.left - (this.stackFactor)
  

    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.opponentCardsPlaceholder.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.opponentCardsPlaceholder.length - 1) 
      let fr = (fr1 - fr2)/2
      from_x_pos += fr

    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('SHIFTED');
      from_x_pos += this.stackFactor / 2
    }

    return { 
      value: this.opponentAnimationState, 
      params: {
        time: time,
        delay: delay,
        from_x_pos: from_x_pos + "px",
        factor_x: shift + "px",
        from_y_pos: from_y_pos + "px",
        from_y_pos_2: from_y_pos_2 + "px"
      }};
  }
  drawCardAimationPortrait(time: string, delay: string) {
  
    let from_y_pos = this.deckPosition.left - this.lastOpponentCardRef.left
    let from_y_pos_2 = from_y_pos - this.heightFactor;
    let shift = this.stackFactor/2
    let from_x_pos = this.lastOpponentCardRef.top - this.deckPosition.top - this.stackFactor
    
    if (this.opponentAnimationState === "deal_opponent_draw_card"){
      from_y_pos = -this.deckPosition.left + this.lastOpponentCardRef.left
      from_y_pos_2 = from_y_pos + this.heightFactor;
    }

    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.opponentCardsPlaceholder.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.opponentCardsPlaceholder.length - 1) 
      let fr = (fr1 - fr2)/2
      from_x_pos += fr

    }
    else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {      
      // console.log('SHIFTED');
      from_x_pos += this.stackFactor / 2
    }

    return { 
      value: this.opponentAnimationState, 
      params: {
        time: time,
        delay: delay,
        from_x_pos: from_x_pos + "px",
        factor_x: shift + "px",
        from_y_pos: from_y_pos + "px",
        from_y_pos_2: from_y_pos_2 + "px"
      }};
  }
  opponentDrawCardAnimation(time: string, delay: string) {
    if (this.screenRotation === 1) {
      return this.drawCardAimationPortrait(time, delay)
    }
    
    return this.drawCardAnimationLandscape(time, delay)
  }

  
  draw2CardsAimationPortrait() {
    
    let from_x_pos = this.lastOpponentCardRef.top - this.deckPosition.top - this.stackFactor
    let from_y_pos = this.deckPosition.left - this.lastOpponentCardRef.left
    
    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.opponentCardsPlaceholder.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.opponentCardsPlaceholder.length - 1) 
      let fr = (fr1 - fr2)/2
      if (this.opponentCardsPlaceholder.length % 2 == 0) {
        from_x_pos += fr
      } else {
        from_x_pos += fr - (this.oldStackFactor - this.stackFactor - 1)
      }

    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('SHIFTED');
      from_x_pos += this.stackFactor 
    }

    let from_x_pos_2 = from_x_pos - this.stackFactor;
    let from_y_pos_2 = from_y_pos - this.heightFactor;

    return { value: 'opponent_draw_card_2', params: {
      from_x_pos: from_x_pos + "px",
      from_x_pos_2: from_x_pos_2 + "px",
      factor_x: this.stackFactor + "px",
      from_y_pos: from_y_pos + "px",
      from_y_pos_2: from_y_pos_2 + "px"
    }};
  }
  draw2CardsAnimationLandscape() {
    // console.log('draw 2 cards landscape');
    
    let from_y_pos = this.deckPosition.top - this.lastOpponentCardRef.top
    let from_y_pos_2 = from_y_pos - this.heightFactor;
    let from_x_pos = this.deckPosition.left - this.lastOpponentCardRef.left - this.stackFactor


    if (this.stackFactorStartBreaking) {
      // console.log('BREAKING POINT');
      let fr1 = this.oldStackFactor * (this.opponentCardsPlaceholder.length - 2) + this.widthFactor      
      let fr2 = this.stackFactor * (this.opponentCardsPlaceholder.length - 1) 
      let fr = (fr1 - fr2)/2
      if (this.opponentCardsPlaceholder.length % 2 == 0) {
        from_x_pos += fr
      } else {        
        from_x_pos += fr - (this.oldStackFactor - this.stackFactor - 1)
      }

    } else if (this.stackFactor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      // console.log('SHIFTED');
      from_x_pos += this.stackFactor 

    }
   
    let from_x_pos_2 = from_x_pos - this.stackFactor;
    
    return { value: 'opponent_draw_card_2', params: {
      from_x_pos: from_x_pos + "px",
      from_x_pos_2: from_x_pos_2 + "px",
      factor_x: this.stackFactor + "px",
      from_y_pos: from_y_pos + "px",
      from_y_pos_2: from_y_pos_2 + "px"
    }};
  }
  opponentDraw2CardsAnimation() {
    if (this.screenRotation === 1) {
      return this.draw2CardsAimationPortrait()
    }
    
    return this.draw2CardsAnimationLandscape()
  }

  
  playsCardAnimationLandscape() {

    this.opponentCardDeckPosition = this.cardPositionService.generateDeckPosition()

    let from_x = 0;
    let from_y = 0;
    let from_y_2 = from_y + this.heightFactor/2
    let from_rotation = 0

    
    
    let to_x = this.tablePosition.left - this.opponentHandPosition.left + this.opponentCardDeckPosition.left
    let to_y = this.tablePosition.top - this.opponentHandPosition.top + this.opponentCardDeckPosition.top    
    let to_rotation = this.opponentCardDeckPosition.rotation


    return { value: 'opponent_plays_card', params: {
      from_x: from_x + "px",
      to_x: to_x + "px",
      from_y: from_y + "px",
      from_y_2: from_y_2 + "px",
      to_y: to_y + "px",
      from_rotation: from_rotation, 
      to_rotation: to_rotation
    }};
  }
  playsCardAnimationPortrait() {
    // console.log(this.tablePosition);
    
    this.opponentCardDeckPosition = this.cardPositionService.generateDeckPosition()

    let from_x = 0;
    let from_y = 0;
    let from_y_2 = from_y + this.heightFactor/2
    let from_rotation = 0

    
    let to_x = this.opponentHandPosition.top - this.tablePosition.top - this.tablePosition.height + this.opponentCardDeckPosition.left + this.cardPositionService.WIDTH
    let to_y = this.tablePosition.left  - this.opponentHandPosition.left + this.opponentCardDeckPosition.top
    let to_rotation = this.opponentCardDeckPosition.rotation

    return { value: 'opponent_plays_card', params: {
      from_x: from_x + "px",
      to_x: to_x + "px",
      from_y: from_y + "px",
      from_y_2: from_y_2 + "px",
      to_y: to_y + "px",
      from_rotation: from_rotation, 
      to_rotation: to_rotation
    }};
  }
  opponentPlaysCardAnimation() {

    if (this.screenRotation === 1) {
      return this.playsCardAnimationPortrait()
    }

    return this.playsCardAnimationLandscape()
  }

  opponentAnimationStart($event: any) {
    // console.log("OPPONENT Animation Start:" , $event.fromState, $event.toState);
    
    // console.log($event.fromState);
    // console.log($event.toState);

  }
  opponentAnimationStop($event: any) {
      // console.log("OPPONENT Animation Stop:" , $event.fromState, $event.toState);

    
    // console.log($event.fromState);
    // console.log($event.toState);    

    if ($event.toState === 'opponent_plays_card') {
      //remove card from oponent hand when animation ends
      this.store.dispatch(opponentPlayedCard({playedCard: this.opponentPlayedCard, deckPosition: this.opponentCardDeckPosition}))  
      // reset played opponent card 
      this.opponentAnimationStartCard = -1
    }
    
    if ($event.toState === 'flip_cards') {
      //TODO: if cards are fliped switch game state 
      this.store.dispatch(switchGameStatus({status: GameStatus.seven_of_cutter_cards_values}))
    }

    if ($event.toState === 'deal_cards') {
      //TODO: if both players have cards - switch game status
    }

    this.runningOpponetAnimation = false
    this.opponentAnimationState = '*'

    if ($event.toState === '*' && this.dealAnimationQueue.length > 0) {
      this.chooseDealCardsAnimation()
    }
  }

  dealAnimationQueue: number[] = [0,1,2,3,4,5,6]
  dealAnimationDelay: string = '0ms'
  chooseDealCardsAnimation() {
    // console.log("DEAL");
    
    if (this.gameStatus === GameStatus.match_started_deal_cards) {
      
      if (this.selectStartDealCardForPlayer === 0) {
        this.dealAnimationDelay = '100ms'
      }
      

      if (!this.numberOfOpponentCardsCopy) {
        return
      }
      let index = this.dealAnimationQueue?.shift()
      console.log(index);
      
      if (index === undefined) {
        return 
      }

      
      // this.generateOpponentCards(this.numberOfOpponentCards)
      this.addOpponentCard([1])
      // this.numberOfOpponentCards += 1

      this.opponentAnimationState = 'deal_opponent_draw_card'            
    }
  }
  generateOpponentCards(numberOfCards: number) {
    if (numberOfCards === undefined ) {
      this.opponentCardsPlaceholder = []
      // console.log(this.opponentCardsPlaceholder );
      return 
    }
    if (numberOfCards === 0) {
      this.opponentCardsPlaceholder = []
      return
    }
    
    this.opponentCardsPlaceholder = Array.from(Array(numberOfCards).keys ())
    // console.log(this.opponentCards);
    
  }
  addOpponentCard(numberOfCard: number[]) {    
    this.opponentCardsPlaceholder = this.opponentCardsPlaceholder.concat(numberOfCard)
    //  console.log(this.opponentCards);
  }

  stackFactorStartBreaking: boolean = false
  oldStackFactor: number = this.stackFactor
  calculateStackFactor(): number {

    this.stackFactorAddition = (this.displayModeServices.expanded * 4)
    this.stackFactorStartBreaking = false
    let availableWidth = this.appWrapperSize.width - 44 - this.widthFactor
    if (this.screenRotation == 1) {
      availableWidth = this.appWrapperSize.height - 44 - this.widthFactor
    }

    let factor = availableWidth / (this.opponentCardsPlaceholder.length - 1)
    this.oldStackFactor = this.stackFactor

    if (this.stackFactor === this.cardPositionService.WIDTH + this.stackFactorAddition && factor < this.cardPositionService.WIDTH + this.stackFactorAddition) {
      this.stackFactorStartBreaking = true
    }

    if (factor >= this.cardPositionService.WIDTH + this.stackFactorAddition) {
      this.stackFactor = this.cardPositionService.WIDTH + this.stackFactorAddition
    }
    else {
      this.stackFactor = factor
    }

    return this.stackFactor
  }
  calculateStyle(last: boolean) { 
    if (last) {
      return {
        width: this.widthFactor + "px"
      }
    }
    return {
      width: this.stackFactor + "px"
    }
  }
  isPlayed(cardOrder: number) {
    return (cardOrder === this.opponentAnimationStartCard) ? true : false
  }

  canRenderOpponentCard() {
    if (this.gameStatus === GameStatus.waiting_for_opponent || 
      this.gameStatus === GameStatus.waiting_for_invited_opponent ||
      this.gameStatus === GameStatus.waiting_for_match_start ||
      this.gameStatus === GameStatus.match_started_select_turn || 
      this.gameStatus === GameStatus.match_started_deal_cards) {
        return false
      }
    return true
  }
}
