import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Card } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { setTablePosition } from 'src/app/state/actions/display-mode.actions';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-game-replay-board',
  templateUrl: './game-replay-board.component.html',
  styleUrls: ['./game-replay-board.component.scss']
})
export class GameReplayBoardComponent implements OnInit {

  @ViewChild('table_cards_ref', { static: false, read: ElementRef})  table_cards_ref!: ElementRef

  table_image = "assets/images/game/table.png"
  tableCards = []
  
  constructor(
    private store: Store<AppState>,
    private displayModeServices: DisplayModeService
  ) { }

  ngOnInit(): void {
  }

  oldTablePosition!: any
  tablePosition!: {top: number, left: number, width: number, height: number}
  ngAfterViewInit() {
    let tableRect = this.table_cards_ref?.nativeElement.getBoundingClientRect()
    this.tablePosition = {
      top: tableRect?.top,
      left: tableRect?.left,
      width: tableRect?.width,
      height: tableRect?.height
    }
    this.oldTablePosition = this.tablePosition
    this.setTablePosition()
    
  }
  ngAfterViewChecked() {

    let tableRect = this.table_cards_ref?.nativeElement.getBoundingClientRect()
    this.tablePosition = {
      top: tableRect?.top,
      left: tableRect?.left,
      width: tableRect?.width,
      height: tableRect?.height
    }
    
    if (this.oldTablePosition.top !== this.tablePosition.top || this.oldTablePosition.left !== this.tablePosition.left
      || this.oldTablePosition.width !== this.tablePosition.width || this.oldTablePosition.height !== this.tablePosition.height) {
      this.oldTablePosition = this.tablePosition
      this.setTablePosition()
    }
  }
  setTablePosition() {
    this.store.dispatch(setTablePosition(this.tablePosition))
  }

  calculateRandomStyle(card: Card) {
    return {}
  }
  tableWrapperSize() {
    let defaultWidth = 249
    let defaultHeight = 127

    let width = (this.displayModeServices.expanded * defaultWidth)
    let height = (this.displayModeServices.expanded * defaultHeight)

    return {
      width: width + 'px',
      height: height + 'px'
    }
  }
}
