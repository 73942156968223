<form (submit)="startTheGame()">
    <div class="center button-wrapper">
        <button type="submit" class="" [ngClass]="calculateClass()">
            Start the Game
        </button>
    </div>
</form>


<app-general-modal *ngIf="errorModadlVisible === 'notBuyIn'">
    <header>No Buy-in selected</header>
    <content>Before you can START THE GAME specific buy-in has to be selected</content>
    <footer>
        <button (click)="closeErrorModal()">OK</button>
    </footer>
</app-general-modal>

<app-general-modal *ngIf="errorModadlVisible === 'incorrectOpponentName'">
    <header>USERNAME IS INCORRECT</header>
    <content>Please, check USERNAME you typed in</content>
    <footer>
        <button (click)="closeErrorModal()">OK</button>
    </footer>
</app-general-modal>

<app-general-modal class="wide-landscape footer-switch" *ngIf="errorModadlVisible === 'notOpponentAvailable'">
    <header>The game is not possible</header>
    <content>
        <p>Your invited friend</p>
        <p class="opponent">{{opponent}}</p>
        <p>is not on-line in MATATU ARENA</p>
    </content>
    <footer class="">
        <button (click)="closeErrorModal()">OK</button>
        <div class="notes">
            <p class="note">Please, try to invite another friend or choose </p>
            <p class="note"> Head2Head for the game with random opponent</p>
        </div>
        
    </footer>
</app-general-modal>


<app-general-modal class="smallpadding wide-landscape footer-right" *ngIf="isConfirmModalVisible">
    <header>Confirmation</header>
    <content>
            <app-stats-block></app-stats-block>
    </content>
    <footer>
        <button (click)="isConfirmModalVisible=false" class="half gray">CANCEL</button>
        <button class="half">CONFIRM</button>
    </footer>
</app-general-modal>

