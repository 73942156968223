import { animate, group, keyframes, query, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GameStatus } from 'src/app/models/game.model';
import { DisplayModeService } from 'src/app/services/display-mode.service';
import { switchGameStatus } from 'src/app/state/actions/game.actions';
import { AppState } from 'src/app/state/app.state';
import { selectAppWrapperSize } from 'src/app/state/selectors/display-mode.selector';
import { selectGameStatus, selectWinner } from 'src/app/state/selectors/game.selector';

@Component({
  selector: 'app-seven-of-cutter',
  templateUrl: './seven-of-cutter.component.html',
  styleUrls: ['./seven-of-cutter.component.scss'],
  animations: [
    trigger(
      'colorAnimation', [
        transition('* => colors', [
          group([
            query(
              '.cutter .top .color', [
                animate('2s linear', keyframes([
                  style({opacity: '0', offset: 0}),
                  style({opacity: '1', offset: 1})
                ]))
              ], {optional: true}
            ),
            query(
              '.cutter .bottom .color', [
                animate('2s linear', keyframes([
                  style({opacity: '0', offset: 0}),
                  style({opacity: '1', offset: 1})
                ]))
              ], {optional: true}
            ),
            query(
              '.cutter .middle .color', [
                animate('2s linear', keyframes([
                  style({opacity: '0', offset: 0}),
                  style({opacity: '1', offset: 1})
                ]))
              ], {optional: true}
            ),
            
            
          ])
        
        ])
      ]
    )
  ]
})
export class SevenOfCutterComponent implements OnInit {

  winner: number | null = null
  winner$ = this.store.pipe(
    select(state => selectWinner(state.game))
  )
  gameStatus: GameStatus | null = null
  gameStatus$ = this.store.pipe(
    select(state => selectGameStatus(state.game))
  )
  appWrapperSize!: any
  appWrapperSize$ = this.store.pipe(
    select(state => selectAppWrapperSize(state.displayMode))
  )

  constructor(private store: Store<AppState>, 
    private displayModeService: DisplayModeService) { }

  ngOnInit(): void {
    this.subscribeAppWrapperSize()

    this.subscribeWinner()
    this.subscribeGameStatus()
  }

  subscribeAppWrapperSize() {
    this.appWrapperSize$.subscribe(value => {
      this.appWrapperSize = value
    })
  }


  subscribeWinner() {
    this.winner$.subscribe(value => {
      this.winner = value
    })
  }

  subscribeGameStatus() {
    this.gameStatus$.subscribe(value => {
      this.gameStatus = value;

      if (this.gameStatus === GameStatus.seven_of_cutter_cards_values) {
        setTimeout(() => {
          this.store.dispatch(switchGameStatus({status: GameStatus.seven_of_cutter_gray_arrows}))
        }, 1000);
      }
      if (this.gameStatus === GameStatus.seven_of_cutter_gray_arrows) {
        setTimeout(() => {
          this.store.dispatch(switchGameStatus({status: GameStatus.seven_of_cutter_colors}))
        }, 1000);
      }

      if (this.gameStatus === GameStatus.seven_of_cutter_colors) {
        this.animationState = 'colors'
      }


    })
  }

  getTopColor() {
    
    if (this.gameStatus !== GameStatus.seven_of_cutter_colors 
      && this.gameStatus !== GameStatus.seven_of_cutter_info_box) {
      return ''  
    }
  

    if (this.winner === null) {
      return ''
    }

    if (this.winner === 0) {
      return 'red active';
    }
    
    return  'green active'
    
  }
  getMiddleColor() {
    
    if (this.gameStatus !== GameStatus.seven_of_cutter_colors 
      && this.gameStatus !== GameStatus.seven_of_cutter_info_box) {
      return ''  
    }


    if (this.winner === null) {
      return ''
    }
    if (this.winner === 0) {
      return 'green active'
    }
    return 'red active'
  }
  getBottomColor() {

    if (this.gameStatus !== GameStatus.seven_of_cutter_colors 
      && this.gameStatus !== GameStatus.seven_of_cutter_info_box) {
      return ''  
    }
    
    if (this.winner === null) {
      return ''
    }

    if (this.winner === 0) {
      return 'green active';
    }
    
    return  'red active'
  }

  animationState!: any
  handleColorAnimation() {
    return {
      value: this.animationState
    }
  }

  canRenderValues() {
    if (this.gameStatus === GameStatus.seven_of_cutter_cards_values 
      || this.gameStatus === GameStatus.seven_of_cutter_colors 
      || this.gameStatus === GameStatus.seven_of_cutter_gray_arrows 
      || this.gameStatus === GameStatus.seven_of_cutter_info_box
      || this.gameStatus === GameStatus.rematch) {
        return true
      } 
    return false
  }

  canRenderArrows() {
    if (this.gameStatus === GameStatus.seven_of_cutter_colors 
      || this.gameStatus === GameStatus.seven_of_cutter_gray_arrows 
      || this.gameStatus === GameStatus.seven_of_cutter_info_box
      || this.gameStatus === GameStatus.rematch) {
        return true
      } 
    return false  }

  canRenderBox() {
    if (this.gameStatus === GameStatus.seven_of_cutter_cards_values 
      || this.gameStatus === GameStatus.seven_of_cutter_colors 
      || this.gameStatus === GameStatus.seven_of_cutter_gray_arrows 
      || this.gameStatus === GameStatus.seven_of_cutter_info_box
      || this.gameStatus === GameStatus.rematch) {
        return true
      } 
    return false
  }

  sevenOfCutterSize() {

    let defaultWidth = 110
    // let defaultHeight = 0
    // let width = Math.max(this.appWrapperSize.width / 4.75, 110)
    // let height = Math.max(width*1.5, 167)

    let width = defaultWidth * this.displayModeService.expanded
    // let height = defaultHeight * this.displayModeService.expanded
    return {
      width: width + 'px',
      // height: height + 'px'
    }
  }

  arrowSize() {
    let  width = 85 * this.displayModeService.expanded
    let height = 35 * this.displayModeService.expanded
    return {
      width: width + 'px',
      height: height + 'px'
    }
  }
  boxSize() {
    let  width = 110 * this.displayModeService.expanded
    let height = 48 * this.displayModeService.expanded
    return {
      width: width + 'px',
      height: height + 'px'
    }
  }
}
