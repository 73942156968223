<div 
    class="game-type" 
    (click)="selectGameType()"
    [ngClass]="calculateClass()">

    <div class="icon-wrapper">
        <img src={{gameVariant.icon}} alt="">    
    </div>
    <div class="game-name"> {{ gameVariant.name }} </div>

    <app-invite-opponent-input (startTheGameEventEmmiter)="inputEvent($event)"  *ngIf="gameVariant.type === 'FRIEND'" ></app-invite-opponent-input>
</div>  

