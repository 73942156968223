import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import * as UserActions from 'src/app/state/actions/user.actions';

@Injectable()
export class UserEffects {

    constructor(
        private userService: UserService,
        private actions$: Actions
    ){}

    // getUser$ = createEffect(() => this.actions$.pipe(
    //      ofType(UserActions.getUserRequest),
    //     //  switchMap((action) => {
    //     //      return this.userService.getUser(action.userToken).pipe(
    //     //          map(user => UserActions.getUserDone({ action.userToken })),
    //     //      );
    //     //  }),
    // ));
}