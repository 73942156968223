import { DashboardModule } from './layouts/dashboard/dashboard.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

//import { lobbyReducer } from './state/reducers/lobby.reducer';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { lobbyReducer } from './state/reducers/lobby.reducer';
import { userReducer } from './state/reducers/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './state/effects/user.effects';
import { displayModeReducer } from './state/reducers/display-mode.reducer';
import { GameModule } from './layouts/game/game.module';
import { GeneralModalComponent } from './layouts/modals/general-modal/general-modal.component';
import { ModalsModule } from './layouts/modals/modals.module';
import { gameReducer } from './state/reducers/game.reducer';
import { GameEffects } from './state/effects/game.effects';
import { FormsModule } from '@angular/forms';
import { gameReplayReducer } from './state/reducers/game-replay.reducer';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,    
    AppRoutingModule, 
    DashboardModule,
    GameModule,
    StoreModule.forRoot({lobby: lobbyReducer, user: userReducer, displayMode: displayModeReducer, game: gameReducer, gameReplay: gameReplayReducer}),
    EffectsModule.forRoot([ UserEffects, GameEffects ]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    //   logOnly: environment.production, // Restrict extension to log-only mode
    // }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
