import { state } from "@angular/animations";
import { Action, createReducer, on } from "@ngrx/store";
import { DisplayMode } from "src/app/models/display-mode.model";
import * as DisplayModeActions from "src/app/state/actions/display-mode.actions"

export const initDisplayMode: DisplayMode = {
    width: window.innerWidth,
    height: window.innerHeight,
    isPortrait: (window.innerHeight > window.innerWidth),
    deckPosition: {
        top: 0,
        left: 0
    },
    tablePosition: {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    },
    aceSwitchPosition: {
        top: 0,
        left: 0
    },
    appWrapperSize: {
        width: 0,
        height: 0
    }
}

export const createDisplayModeReducer = createReducer(
    initDisplayMode,
    on ( DisplayModeActions.setAppWrapperSize, 
        (state, {width, height}) => {
            return {
                ...state,
                appWrapperSize: {
                    width: width,
                    height: height
                }
            }
        }
    ),
    on( DisplayModeActions.setAceSwitchPosition, 
        (state, {left, top }) => {
            return {
                ...state,
                aceSwitchPosition:{
                    left,
                    top
                }
            }
        }
    ),
    on( DisplayModeActions.setTablePosition,
        (state, { left, top, width, height}) => {
            // console.log("set table position");
            // console.log(left);
            // console.log(top);
            
            
            
            return {
                ...state,
                tablePosition: {
                    left: left,
                    top: top,
                    width: width,
                    height: height
                }
            }
        }
    ),
    on( DisplayModeActions.setDeckPosition,
        (state, { left, top }) => {
            return {
                ...state, 
                deckPosition: {
                    left: left,
                    top: top
                }
            }
        }
    ),  
    on( DisplayModeActions.resizeDisplay,
        (state, { width, height, isPortrait }) => {
            // console.log("reducer - resize display");
                        
            return {
                ...state,
                width,
                height,
                isPortrait
            }

        } 
    )
)

export function displayModeReducer(state: DisplayMode | undefined, action: Action) : DisplayMode {
    return createDisplayModeReducer(state, action);
}

