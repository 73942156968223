<div class="subheader-items" [ngSwitch]="selected_content">
    <app-online-players *ngSwitchCase="SubheaderContent.PLAYERS"></app-online-players>
    <ng-container *ngIf="myTodayBalance">
        <app-todays-balance *ngSwitchCase="SubheaderContent.BALANCE"></app-todays-balance>
        <app-win-lost-rate *ngSwitchCase="SubheaderContent.WINLOST"></app-win-lost-rate>    
    </ng-container>
    
</div>


<div class="subheader-controlls" *ngIf="myTodayBalance">
    <div 
        (click)="switchContent(-1)" 
        class="controll-button controll-left" 
        [ngClass]="{inactive: (selected_content === 0)}">
        <img src={{button_left}} alt="">
    </div>
    <div 
        (click)="switchContent(1)" 
        class="controll-button controll-right"
        [ngClass]="{inactive: (selected_content === 2)}">
        <img src={{button_right}} alt="">
    </div>
</div>


<div class="subheader-items-landscape">
    <app-online-players [ngClass]="{landscape: true}"></app-online-players>
    <ng-container *ngIf="myTodayBalance">
        <app-todays-balance [ngClass]="{landscape: true}"></app-todays-balance>
        <app-win-lost-rate [ngClass]="{landscape: true}"></app-win-lost-rate>
    </ng-container>
</div>