import { createAction, props } from '@ngrx/store'
import { BuyInType, GameType } from 'src/app/models/lobby.model';

const SELECT_GAME_TYPE = '[GameType] game type select';
const SELECT_BUY_IN = '[BuyInType] buy in select';
const OPPONENT_NAME_CHANGED = '[string] opponent name changed'
const INVITATION = '[invitation] invite from friend'
const INVITATION_ACCEPT = '[invitation] accept invitation from friend'
const INVITATION_REJECT = '[invitation] reject invitation from friend'

export const selectGameType = createAction(
    SELECT_GAME_TYPE,
    props<{gameType: GameType}>()
);
export const selectBuyInType = createAction(
  SELECT_BUY_IN,
  props<{buyInType: BuyInType}>()
);

export const changeOpponentName = createAction(
  OPPONENT_NAME_CHANGED,
  props<{opponentName: string}>()
)

export const invitationFromFriend = createAction(
  INVITATION,
)

export const acceptInvitation = createAction(
  INVITATION_ACCEPT,
)

export const rejectInvitation = createAction(
  INVITATION_REJECT,
)
