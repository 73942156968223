<div class="seven-of-cutter-wrapper" 
    [ngStyle]="sevenOfCutterSize()"
    [@colorAnimation]="handleColorAnimation()">
    <app-number-of-cards class="opponent" [player]="1" *ngIf="canRenderValues()"></app-number-of-cards>
    <div class="cutter arrow" [ngStyle]="arrowSize()">
        <ng-container  *ngIf="canRenderArrows()">
            <div class="top">
                <div class="color" [ngClass]="getTopColor()">
                </div>
            </div>
        </ng-container>
    </div>
    
    <div class="cutter box" [ngStyle]="boxSize()">
        <div class="middle" *ngIf="canRenderBox()">
            <div class="color" [ngClass]="getMiddleColor()">
            </div>
            <div class="text">
                <p>Seven of</p>
                <p>cutter</p> 
            </div>
        </div>
    </div>
    <div class="cutter arrow" [ngStyle]="arrowSize()">
        <div class="bottom" *ngIf="canRenderArrows()">
            <div class="color" [ngClass]="getBottomColor()">
            </div>
        </div>
    </div>
</div>